import { FC } from 'react';
import { ProjectHeaderType } from '../../../../../types/project';
import { useUCTechnologies } from './hooks/use-uc-technologies';
import { UseCaseTags } from '../tags/use-case-tags';

interface Props {
  className?: string;
  useCase: ProjectHeaderType;
}

const UseCaseTechnologies: FC<Props> = ({ useCase }) => {
  const { technologies } = useUCTechnologies(useCase);
  return <UseCaseTags {...{ tags: technologies, variant: 'blue' }} />;
};

export default UseCaseTechnologies;
