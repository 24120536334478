import { arrow, autoUpdate, computePosition, flip, Side } from '@floating-ui/dom';
import Util from '../../globals/ts/util';

export interface ITooltipOptions {
  /**
   * Whether the tooltip has an arrow
   */
  hasArrow?: boolean;
  /**
   * Whether the color is inverse
   * @example: On spotlight context
   */
  isColorInverse?: boolean;
  /**
   * On which side of the trigger, the tooltip should be attached
   */
  placement?: Side;
  /**
   * Whether the provided string content should be interpreted as HTML.
   * Prefer passing content as HTMLElement when possible instead
   */
  isContentHtml?: boolean;
}

const DEFAULT_OPTIONS: Required<ITooltipOptions> = {
  hasArrow: true,
  isColorInverse: false,
  placement: 'top',
  isContentHtml: false
};

const CLASS_NAME = {
  tooltip: {
    element: 'nj-tooltip',
    modifier: {
      withoutArrow: 'nj-tooltip--without-arrow',
      inverse: 'nj-tooltip--inverse',
      placed: (placement: ITooltipOptions['placement']) => `nj-tooltip--${placement}`
    }
  },
  arrow: {
    element: 'nj-tooltip__arrow',
    modifier: {
      center: 'nj-tooltip__arrow--center'
    }
  },
  inner: {
    element: 'nj-tooltip__inner'
  }
};

const INIT_QUERY_SELECTOR = '[data-toggle="tooltip"]';
export class Tooltip {
  private _isDisplayed = false;
  readonly anchorElement: HTMLElement;
  private readonly anchorParentElement: HTMLElement;
  private tooltipElement: HTMLDivElement;
  private arrowElement: HTMLDivElement;
  private innerElement: HTMLDivElement;

  private cleanupFunction?: () => void;
  private options: ITooltipOptions;
  constructor(anchorElement: HTMLElement, content?: string | HTMLElement, options?: ITooltipOptions) {
    this.anchorElement = anchorElement;
    this.anchorParentElement = anchorElement.parentElement;
    this.createTooltipElement();
    Object.defineProperty(this.anchorElement, '_njTooltip', {
      value: this,
      writable: true
    });

    const tooltipContent = content ?? anchorElement.dataset.tooltipContent;

    this.options = Object.assign(
      {},
      DEFAULT_OPTIONS,
      options ?? {},
      anchorElement.dataset ? this.getOptionsFromDataset(anchorElement.dataset) : {}
    );
    this.updateTooltipModifiers(this.options);
    this.setContent(tooltipContent, this.options.isContentHtml);
    if (typeof anchorElement.dataset?.tooltipAlways === 'string') {
      this.show();
    }
  }

  /**
   * Generate a tooltip element without any display logic
   */
  private createTooltipElement() {
    const tooltipElement = document.createElement('div');
    tooltipElement.className = CLASS_NAME.tooltip.element;
    tooltipElement.role = 'tooltip';
    tooltipElement.id = Util.getUID('tooltip-');

    const arrowElement = document.createElement('div');
    arrowElement.className = `${CLASS_NAME.arrow.element} ${CLASS_NAME.arrow.modifier.center}`;
    arrowElement.ariaHidden = 'true';

    const innerElement = document.createElement('div');

    innerElement.className = CLASS_NAME.inner.element;
    tooltipElement.appendChild(arrowElement);
    tooltipElement.appendChild(innerElement);

    this.tooltipElement = tooltipElement;
    this.arrowElement = arrowElement;
    this.innerElement = innerElement;
  }

  private getOptionsFromDataset(dataset: DOMStringMap): ITooltipOptions {
    const options: ITooltipOptions = {};
    if (typeof dataset.tooltipArrow === 'string') {
      options.hasArrow = dataset.tooltipArrow === 'true';
    }
    if (typeof dataset.tooltipInverse === 'string') {
      options.isColorInverse = dataset.tooltipInverse === 'true';
    }
    if (typeof dataset.tooltipPlacement === 'string') {
      options.placement = dataset.tooltipPlacement as Side;
    }
    if (typeof dataset.tooltipHtml === 'string') {
      options.isContentHtml = dataset.tooltipHtml === 'true';
    }
    return options;
  }

  /**
   * Update tooltip classes to reflect desired options
   * @param options The options to customize the tooltip
   */
  private updateTooltipModifiers(options: Omit<ITooltipOptions, 'isContentHtml'>) {
    if (typeof options.hasArrow === 'boolean') {
      if (options.hasArrow) {
        this.tooltipElement.classList.remove(CLASS_NAME.tooltip.modifier.withoutArrow);
      } else {
        this.tooltipElement.classList.add(CLASS_NAME.tooltip.modifier.withoutArrow);
      }
    }

    if (typeof options.isColorInverse === 'boolean') {
      if (options.isColorInverse) {
        this.tooltipElement.classList.add(CLASS_NAME.tooltip.modifier.inverse);
      } else {
        this.tooltipElement.classList.remove(CLASS_NAME.tooltip.modifier.inverse);
      }
    }

    if (typeof options.placement === 'string') {
      // Reset positions
      this.tooltipElement.classList.remove(CLASS_NAME.tooltip.modifier.placed('top'));
      this.tooltipElement.classList.remove(CLASS_NAME.tooltip.modifier.placed('right'));
      this.tooltipElement.classList.remove(CLASS_NAME.tooltip.modifier.placed('bottom'));
      this.tooltipElement.classList.remove(CLASS_NAME.tooltip.modifier.placed('left'));

      this.tooltipElement.classList.add(CLASS_NAME.tooltip.modifier.placed(options.placement));
    }
  }

  /**
   * Display the tooltip. Make sure to `hide()` it when no longer needed.
   */
  show() {
    if (this._isDisplayed) {
      return;
    }

    this.anchorParentElement.appendChild(this.tooltipElement);
    this.anchorElement.setAttribute('aria-describedby', this.tooltipElement.id);

    this._isDisplayed = true;

    this.cleanupFunction = autoUpdate(this.anchorElement, this.tooltipElement, () => {
      computePosition(this.anchorElement, this.tooltipElement, {
        placement: this.options.placement,
        middleware: [flip(), this.options.hasArrow && arrow({ element: this.arrowElement })]
      }).then(({ x, y, placement }) => {
        Object.assign(this.tooltipElement.style, {
          left: `${x}px`,
          top: `${y}px`
        });
        this.updateTooltipModifiers({ placement: placement as Side });
      });
    });
  }

  /**
   * Hide the tooltip
   */
  hide() {
    if (!this._isDisplayed) {
      return;
    }
    this.cleanupFunction?.();
    this.cleanupFunction = null;
    this.anchorElement.parentElement?.removeChild(this.tooltipElement);
    this.anchorElement.removeAttribute('aria-describedby');
    this._isDisplayed = false;
  }

  /**
   * Update tooltip content
   * @param content The content of the tooltip, it can be a string or an {@link HTMLElement}
   * @param isContentHTML Whether the provided string content should be interpreted as HTML,
   * prefer passing content as HTMLElement when possible instead.
   * Default to `false`.
   */
  setContent(content: string | HTMLElement, isContentHTML = DEFAULT_OPTIONS.isContentHtml) {
    if (typeof content === 'string') {
      if (isContentHTML) {
        this.innerElement.innerHTML = content;
      } else {
        this.innerElement.innerText = content;
      }
    } else {
      this.innerElement.appendChild(content);
    }
  }

  /**
   * Update tooltip options
   * @param options
   */
  updateOptions(options: Omit<ITooltipOptions, 'isContentHtml'>) {
    this.options = {
      ...this.options,
      ...options
    };

    this.updateTooltipModifiers(this.options);
  }
}

/**
 * Initialize a tooltip anchored to an {@link HTMLElement}
 *
 * @param anchorElement The anchor element on which the tooltip will be attached
 * @param content The content of the tooltip, it can be a string or an {@link HTMLElement}.
 * If not set, and no `data-tooltip-content` attribute is set to the anchor element, no tooltip will be rendered.
 * @param options The {@link ITooltipOptions} options to customize the generated tooltip.
 *
 * @return The tooltip reference for further manipulation
 */
export const njTooltip = (
  anchorElement: HTMLElement,
  content?: string | HTMLElement,
  options?: ITooltipOptions
): Tooltip => new Tooltip(anchorElement, content, options);

/**
 * Initialize all tooltip anchor matching query selector on given element.
 *
 * @param element Parent element on which we will apply query selection. Default to `document.body`
 * @param querySelector Query selector of your tooltip anchors. Default to `[data-toggle="tooltip"]`
 *
 * @return The initialized tooltip element references and a function to unmount them all
 */
export const initAllTooltips = (
  element?: HTMLElement,
  querySelector = INIT_QUERY_SELECTOR
): { tooltips: Tooltip[]; unmount: () => void } => {
  const parentElement = element ?? document.body;

  const tooltips: Tooltip[] = [];
  const unmountFunctions: Array<() => void> = [];

  parentElement.querySelectorAll(querySelector).forEach((anchor) => {
    const anchorElement = anchor as HTMLElement;

    const tooltip = njTooltip(anchorElement);

    if (!(anchorElement.dataset.tooltipAlways === 'true')) {
      unmountFunctions.push(displayTooltipOnHoverAndFocus(tooltip));
    }

    tooltips.push(tooltip);
  });

  const unmount = () => {
    unmountFunctions.forEach((unmount) => unmount());
  };

  return { tooltips, unmount };
};

/**
 * Attach listeners to focus and hover events to toggle tooltip
 * @param tooltip The tooltip which will be displayed
 * @param triggerElement The element on which would be attached event listeners. Default to tooltip anchor
 * @return Dismount function to remove event listeners, should be called before disposing the tooltip
 */
export const displayTooltipOnHoverAndFocus = (tooltip: Tooltip, triggerElement?: HTMLElement): (() => void) => {
  const controller = new AbortController();
  const { signal } = controller;

  const anchorElement = triggerElement ?? tooltip.anchorElement;

  anchorElement.addEventListener(
    'focusin',
    () => {
      tooltip.show();
    },
    { signal }
  );
  anchorElement.addEventListener(
    'mouseenter',
    () => {
      tooltip.show();
    },
    { signal }
  );
  anchorElement.addEventListener(
    'focusout',
    () => {
      tooltip.hide();
    },
    { signal }
  );
  anchorElement.addEventListener(
    'mouseleave',
    () => {
      tooltip.hide();
    },
    { signal }
  );

  return () => {
    tooltip.hide();
    controller.abort();
  };
};

export default {
  njTooltip,
  initAllTooltips,
  Tooltip,
  displayTooltipOnHoverAndFocus
};
