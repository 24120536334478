import { FC } from 'react';
import { TagType } from '../../../../../types/tag';
import { TBrandExtendedColorVariants } from '@engie-group/fluid-design-system-react/lib/global';
import './use-case-tags.scss';
import { TagLink } from '../../../../../pages/Project/components/TagLink';


interface Props {
  variant?: TBrandExtendedColorVariants;
  tags: TagType[];
}

const UseCaseTags: FC<Props> = ({ tags, variant = 'teal' }) => {
  let i = 0;
  return (
    <div className="use-case-tags-container">
      {tags.slice(0, 3).map((tag) => {
        let label = tag.label;
        if (i > 1) {
          label = '+' + (tags.length - 2);
        }
        i++;
        return (
          <TagLink
            key={tag.id}
            id={tag.id}
            text={label}
            category={tag.category}
            className='use-case-tag'
            closeAriaLabel='Close tag'
            size='sm'
            variant={variant}
          />
        );
      })}
    </div>
  );
};

export { UseCaseTags };