import { Core, EventName } from '../../globals/ts/enum';
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';

export default class InputNumber extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-form-item--input-number`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.input-number`;
  protected static readonly EVENT_KEY = `.${InputNumber.DATA_KEY}`;
  private static readonly LIVEZONE_TIMEOUT = 5000;

  protected static readonly SELECTOR = {
    default: `.${InputNumber.NAME}`,
    field: `.${Core.KEY_PREFIX}-form-item__field`,
    incrementButton: `.${Core.KEY_PREFIX}-form-item__increment-button`,
    decrementButton: `.${Core.KEY_PREFIX}-form-item__decrement-button`,
    liveZone: `[aria-live]`
  };

  private static readonly EVENT = {
    click: `${EventName.click}${InputNumber.EVENT_KEY}`,
    decrement: `${EventName.decrement}${InputNumber.EVENT_KEY}`,
    increment: `${EventName.increment}${InputNumber.EVENT_KEY}`,
    onchange: `${EventName.onchange}${InputNumber.EVENT_KEY}`
  };

  private readonly field: HTMLInputElement;
  private readonly incrementButton: HTMLButtonElement;
  private readonly decrementButton: HTMLButtonElement;
  private readonly liveZone: HTMLDivElement;

  private liveZoneTimeoutId: ReturnType<typeof setTimeout>;

  constructor(element: HTMLDivElement) {
    super(InputNumber, element);
    Data.setData(element, InputNumber.DATA_KEY, this);

    // Define elements
    this.field = element.querySelector(InputNumber.SELECTOR.field);
    this.incrementButton = element.querySelector(InputNumber.SELECTOR.incrementButton);
    this.decrementButton = element.querySelector(InputNumber.SELECTOR.decrementButton);
    this.liveZone = element.querySelector(InputNumber.SELECTOR.liveZone);

    // Initial setup
    this.updateFieldSize();
    this.disableButtonsIfNeeded(false);

    // Fire events
    EventHandler.on(this.decrementButton, InputNumber.EVENT.click, this.decrementValue);
    EventHandler.on(this.incrementButton, InputNumber.EVENT.click, this.incrementValue);
    EventHandler.on(this.field, 'input', () => {
      if (this.min !== null && this.valueAsNumber < this.min) {
        this.field.value = String(this.min);
      } else if (this.max !== null && this.valueAsNumber > this.max) {
        this.field.value = String(this.max);
      }

      this.updateFieldSize();
      this.disableButtonsIfNeeded();
      this.updateLiveZone();

      EventHandler.trigger(this.element, InputNumber.EVENT.onchange, {
        value: this.valueAsNumber
      });
    });
  }

  dispose(): void {
    EventHandler.off(this.decrementButton, InputNumber.EVENT.click);
    EventHandler.off(this.incrementButton, InputNumber.EVENT.click);
    EventHandler.off(this.field, 'input');
    Data.removeData(this.element, InputNumber.DATA_KEY);
    this.element = null;
  }

  get valueAsNumber(): number {
    return Number(this.field.value);
  }

  get step(): number {
    return this.field.step ? Number(this.field.step) : 1;
  }

  get min(): number | null {
    return this.field.min ? Number(this.field.min) : null;
  }

  get max(): number | null {
    return this.field.max ? Number(this.field.max) : null;
  }

  /**
   * Update the width of the input field based on its value length
   */
  updateFieldSize = (): void => {
    this.field.style.setProperty('--nj-fc-field-characters-length', `${this.field.value.length || 1}`);
  };

  /** Add or remove the disabled attribute to the increment and decrement buttons if needed. */
  disableButtonsIfNeeded(moveFocus = true) {
    const disableIncrement = this.max !== null && this.valueAsNumber >= this.max;
    const disableDecrement = this.min !== null && this.valueAsNumber <= this.min;

    this.incrementButton.disabled = disableIncrement;
    this.decrementButton.disabled = disableDecrement;

    if (disableIncrement && moveFocus) {
      this.field.focus();
    }

    if (disableDecrement && moveFocus) {
      this.field.focus();
    }
  }

  updateLiveZone() {
    if (this.liveZoneTimeoutId) {
      clearTimeout(this.liveZoneTimeoutId);
    }

    const label = this.element.querySelector('label').textContent;
    // Text format of the live zone content
    const liveZoneFormat = this.element.dataset.liveZoneFormat ?? `${label} : {x}`;
    this.liveZone.innerHTML = `<p>${liveZoneFormat.replace('{x}', this.field.value)}</p>`;

    this.liveZoneTimeoutId = setTimeout(() => {
      this.liveZone.innerHTML = null;
    }, InputNumber.LIVEZONE_TIMEOUT);
  }

  /**
   * Decrement field value with the "-" button
   */
  decrementValue = (): void => {
    const newValue = Number(this.getClampedValue(this.valueAsNumber - this.step).toFixed(2));

    this.field.value = String(newValue);
    this.updateFieldSize();
    this.disableButtonsIfNeeded();
    this.updateLiveZone();

    EventHandler.trigger(this.element, InputNumber.EVENT.decrement, {
      value: newValue
    });
  };

  /**
   * Increment field value with the "+" button
   */
  incrementValue = (): void => {
    const newValue = Number(this.getClampedValue(this.valueAsNumber + this.step).toFixed(2));

    this.field.value = String(newValue);
    this.updateFieldSize();
    this.disableButtonsIfNeeded();
    this.updateLiveZone();

    EventHandler.trigger(this.element, InputNumber.EVENT.increment, {
      value: newValue
    });
  };

  /**
   * Return clamped value according to min and max if present.
   */
  getClampedValue(value: number): number {
    if (this.min !== null && value < this.min) {
      value = this.min;
    }

    if (this.max !== null && value > this.max) {
      value = this.max;
    }

    return value;
  }

  static init(options = {}): InputNumber[] {
    return super.init(this, options, InputNumber.SELECTOR.default) as InputNumber[];
  }
}
