import { FunctionComponent } from 'react';
import { ReactComponent as ArrowRight } from '../../images/svg/icons/arrow-right.svg';
import './look4dataSearch.css';
import { AgoraProjectMapper } from './AgoraMapper';

interface Look4dataSearchCardPropTypes {
  rank?: number;
  title?: string;
  content?: string;
  data_source?: string;
  url?: string;
}

const Look4dataSearchCard: FunctionComponent<Look4dataSearchCardPropTypes> = ({
  rank,
  title,
  content,
  url,
  data_source = '',
}) => {
  return (
    <div className="card p-6 shadow rounded">
      <div className="flex items-baseline">
        {rank && <div className={`card-rank bg-rank-${rank} mr-4`}>{rank}</div>}
        <div className="flex-1 min-w-0">
          <div
            className="font-bold text-2xl text-primary-10 truncate card-title"
            title={title}
          >
            {title}
          </div>
          <p
            className="mt-4 text-gray-800 overflow-y-auto card-content"
            style={{
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: url ? 4 : 6,
              height: url ? 75 : 110,
              display: '-webkit-box',
              wordWrap: 'break-word',
            }}
          >
            {content}
          </p>
          {url && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={url}
              className="text-primary-8 font-bold flex items-center justify-end my-2 uppercase"
            >
              <span>discover</span>
              <ArrowRight className="fill-current h-4 ml-2" />
            </a>
          )}
        </div>
      </div>
      <hr className="h-px shadow border-gray-100" />

      <div className="font-bold flex items-center justify-end mt-2">
        <span className="text-gray-800">a resource from </span>
        <span className="text-primary-9 ml-1">{AgoraProjectMapper(data_source)}</span>
      </div>
    </div>
  );
};

export default Look4dataSearchCard;
