import { FC } from 'react';
import YammerWidget from '../../../../yammer-widget/yammer-widget';

interface SocialMediaProps {
  yammerTitle: string;
  yammerLink: string;
  className: string;
}

const SocialMedia: FC<SocialMediaProps> = ({ yammerTitle, yammerLink, className }) => {
  return (
    <div className={className}>
      <YammerWidget yammer_title={yammerTitle} yammer_widget_src={yammerLink} />
    </div>
  );
};

export { SocialMedia };
