export interface userNotificationsType {
  newCommentOnUC: notificationFrequencies;
  newCommentOnFollowedUC: notificationFrequencies;
  newUC: notificationFrequencies;
}

export enum notificationFrequencies {
  'LIVE' = 'LIVE',
  'NONE' = 'NONE',
  'UNDEFINED' = 'UNDEFINED',
}

export interface NotificationsConfigType {
  label: string;
  name: string;
}
