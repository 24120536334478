import { FC } from 'react';
import { NJCard, NJCardBody } from '@engie-group/fluid-design-system-react';
import './home-event-card.scss';
import { EventType } from '../../../../../../types/events';
import { DateTime } from 'luxon';
import { EventActionButton } from '../../../../atoms/event-action-button/event-action-button';

interface HomeEventCardProps {
  event: EventType;
}

const HomeEventCard: FC<HomeEventCardProps> = ({ event }) => {
  const dateFormat = 'dd/MM/yyyy h:mma'
  const startDate = DateTime.fromISO(event.startDate).toFormat(dateFormat);
  const endDate = event.endDate ? DateTime.fromISO(event.endDate).toFormat(dateFormat) : '';

  return (
    <a className='hec-event-container' href={`/events/${event.id}`}>
      <NJCard
        className="hec-event hec-image"
        image={
          <img
            src={
              event?.images && event?.images[0]
                ? '/' + event?.images[0].id_image
                : 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736881_640.jpg'
            }
            alt={event?.images && event?.images[0] ? '' : 'cdn.pixabay.com'}
          />
        }
      >
        <NJCardBody>
          <div className="hec-space">
            <span>{event.eventLocation}</span>
          </div>
          <h2 className="hec-title ago-principal-title-bold">{event.title}</h2>
          <span className="ago-text-bold-blue-16">
            {startDate}
            {event.endDate && ' -  ' + endDate}
          </span>
          <div className="hec-button-pos">
            <EventActionButton
              joinType={event.joinType}
              joinUrl={event.joinUrl}
            ></EventActionButton>
          </div>
        </NJCardBody>
      </NJCard>
    </a>
  );
};

export { HomeEventCard };
