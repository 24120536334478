import { FC } from 'react';
import './home-indicators-summary.scss';

interface HomeIndicatorsSummaryProps {
  firstIndicator: string;
  secondIndicator: string;
  thirdIndicator: string;
  title: string;
}

const HomeIndicatorsSummary: FC<HomeIndicatorsSummaryProps> = ({
  firstIndicator,
  secondIndicator,
  thirdIndicator,
  title,
}) => {
  return (
    <div className="his-layout">
      <h3 className="his-title ago-principal-title-bold">{title}</h3>
      <p className="his-content ago-text-regular-16">{firstIndicator}</p>
      <p className="his-content ago-text-regular-16">{secondIndicator}</p>
      <p className="his-content ago-text-regular-16">{thirdIndicator}</p>
    </div>
  );
};

export { HomeIndicatorsSummary };
