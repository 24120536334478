export enum ProjectStatusEnum {
  CLOSED = 0,
  SCOPING = 1,
  PILOT = 2,
  PRODUCTION = 3,
  INDUSTRIALIZATION = 4,
}

export interface ProjectStatusDatum {
  value: ProjectStatusEnum;
  title: string;
  description: string;
}

export const ProjectStatuses: Array<ProjectStatusDatum> = [
  {
    value: ProjectStatusEnum.CLOSED,
    description:
      'This status for the UC that have been cancelled, abandoned, stopped, closed or on hold.',
    title: 'On hold/Closed',
  },
  {
    value: ProjectStatusEnum.SCOPING,
    description: 'The use case is in a POC stage',
    title: 'Scoping',
  },
  {
    value: ProjectStatusEnum.PILOT,
    description:
      'The use case is being tested for a specific use and still encounters some errors',
    title: 'Pilot',
  },
  {
    value: ProjectStatusEnum.PRODUCTION,
    description:
      'The use case is running with limited errors, but doesn’t have a graphic interface and/or cannot be used by operational people',
    title: 'Production',
  },
  {
    value: ProjectStatusEnum.INDUSTRIALIZATION,
    description:
      'The use case is up and running and can be used by operational people without too much trouble',
    title: 'Industrialization',
  },
];
