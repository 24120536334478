import * as slugify from 'slugify';
import * as ss from 'string-similarity';
import { FC } from 'react';
import { NJAvatar } from '@engie-group/fluid-design-system-react';
import { TagType } from '../../../../../types/tag';
import { countries } from '../../../../../consts/countries';
import './use-case-flags.scss';

interface Props {
  bes: TagType[];
}

const getCountryFlag = (searchTerm: string): string | undefined => {
  const country = countries.find((item) => {
    const res =
      Math.round(
        (ss.compareTwoStrings(
          slugify.default(item.name.toLowerCase()),
          slugify.default(searchTerm.toLowerCase()),
        ) +
          Number.EPSILON) *
          100,
      ) / 100;
    return res > 0.8;
  });
  return country ? `/flags/${country.ISO.toLowerCase()}.svg` : undefined;
};

const UseCaseFlags: FC<Props> = ({ bes }) => {
  const getFlags = () => {
    const avatars: JSX.Element[] = [];

    bes.forEach((be, index) => {
      const flag = getCountryFlag(be.label);

      if (flag) {
        const avatar = (
          <div
            key={'avatar-' + be.id + '-' + index}
            style={{
              position: 'relative',
              left: (bes.length - 1 - index) * 16,
            }}
          >
            <NJAvatar label={be.label} size="md">
              <img alt={be.label} src={flag} />
            </NJAvatar>
          </div>
        );
        avatars.push(avatar);
      }
    });

    return avatars;
  };

  return <div className="ago-use-case-card-flag">{getFlags()}</div>;
};

export { UseCaseFlags };
