import './LinkButton.scss';
import { FunctionComponent } from 'react';
import classNames from 'classnames';
import { NJButton } from '@engie-group/fluid-design-system-react';

interface PropTypes {
  url?: string;
  target?: string;
  className?: string;
  label?: string;
  iconName?: string;
  emphasisAttention?: 'bold' | 'subtle' | 'minimal';
  variant?: 'inverse' | 'primary' | 'secondary' | 'destructive';
  isDisabled?: boolean;
  iconClassName?: string;
  size?: 'small' | 'normal' | 'large' | 'xsmall';
}

const LinkButton: FunctionComponent<PropTypes> = ({
  url = '',
  target = 'self',
  className,
  label,
  iconName,
  emphasisAttention,
  variant,
  isDisabled = false,
  iconClassName,
  size,
}) => {
  const computedClasses = classNames('', className);
  return (
    <NJButton
      href={url}
      className={computedClasses}
      hrefTarget={target}
      label={label}
      icon={iconName}
      emphasis={emphasisAttention}
      variant={variant}
      iconClassName={iconClassName}
      size={size}
    />
  );
};

export default LinkButton;
