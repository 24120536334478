import { NJCard, NJCardBody } from "@engie-group/fluid-design-system-react";
import { ProjectHeaderType } from "../../../../../types/project";
import { ImageCardTag } from "../../../atoms/card/header/image-card-tag";
import { useUseCaseCard } from "./hooks/use-use-case-card";
import "./use-case-card.scss"
import { FC } from "react";
import { UseCaseCardTitle } from "./title/use-case-card-title";
import { UseCaseTags } from "../tags/use-case-tags";
import { UseCaseFlags } from "../flags/use-case-flags";
import { UseCaseCardFooter } from "./footer/use-case-card-footer";
import { ProjectStatusEnum } from "../../../../../consts/project-status";
import UseCaseTechnologies from "../technologies/use-case-technologies";

interface Props {
    className?: string,
    index: number,
    useCase: ProjectHeaderType
}

const UseCaseCard: FC<Props> = ({ useCase }) => {
  const { shortDescription, firstName, lastName } = useUseCaseCard(useCase);
  return (
    <a href={`/projects/${useCase.id}`}>
      <NJCard
        className="ago-use-cases-card"
        {...{
          image: (
            <>
              <img
                {...{
                  alt: useCase.shortDescription,
                  className: 'ago-use-case-card-img',
                  src: useCase?.image?.url,
                }}
              />
              <ImageCardTag {...{ title: useCase.uctype }} />
              <UseCaseFlags {...{ bes: useCase.bes }} />
            </>
          ),
        }}
      >
        <NJCardBody className="description-container">
          <UseCaseCardTitle
            {...{
              bus: useCase.bus,
              title: useCase.title,
            }}
          />
          <p className="ago-text-regular-16 uc_descrpition">{shortDescription}</p>
          <UseCaseTags {...{ tags: useCase.tags }} />
          <UseCaseTechnologies {...{ useCase }} />
          <UseCaseCardFooter
            {...{
              fillingRate: useCase.fillingRate.toFixed(2),
              firstName,
              lastName,
              status: useCase.status as ProjectStatusEnum,
            }}
          />
        </NJCardBody>
      </NJCard>
    </a>
  );
};


export { UseCaseCard };
