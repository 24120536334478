import { FC } from 'react';
import { NJButton, NJIcon } from '@engie-group/fluid-design-system-react';
import './event-action-button.scss';
import { ReactComponent as Outlook } from '../../../../images/svg/logos/outlook.svg';

interface EventActionButtonProps {
  joinType: string | undefined;
  joinUrl: string | undefined;
}

const EventActionButton: FC<EventActionButtonProps> = ({ joinType, joinUrl }) => {
  return !joinType ? (
    <NJButton href={joinUrl} variant="primary">
      <NJIcon data-child-name="customIcon" name="open_in_browser" className="eab-icon" />
      <span data-child-name="customLabel">Join now</span>
    </NJButton>
  ) : joinType === 'register' ? (
    <NJButton href={joinUrl} variant="primary">
      <NJIcon data-child-name="customIcon" name="border_color" className="eab-icon" />
      <span data-child-name="customLabel">Register</span>
    </NJButton>
  ) : (
    <NJButton href={joinUrl} variant="primary">
      <span data-child-name="customLabel" className="eab-button-content">
        <Outlook className="eab-icon-outlook" />
        Add to calendar
      </span>
    </NJButton>
  );
};

export { EventActionButton };
