import { isUrl } from '../services/utils';
import { feedFilters } from '../types/feedFilters';
import { ContentTypes } from '../types/inspirations';

export const initialState = {
  feed: [],
  feedFilter: feedFilters.ALL,
  metadata: undefined,
  loadingMetadata: false,
  loadingFeed: false,
  metadataFailed: false,
};

const feed = {
  name: 'feed',
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        case 'GET_FEED_START':
          return {
            ...state,
            feedFilter: payload.feedFilter,
            loadingFeed:
              state.feed.length === 0 || state.feedFilter !== payload.feedFilter,
          };
        case 'GET_FEED_SUCCESS':
          return {
            ...state,
            loadingFeed: false,
            feed: payload.feed ? payload.feed : [],
            pagination: payload.pagination,
          };
        case 'CREATE_POST_START':
          return { ...state, loadingFeed: true };
        case 'CREATE_POST_SUCCESS':
          return {
            ...state,
            feed: [payload.post].concat(state.feed),
            loadingFeed: false,
            metadata: undefined,
            loadingMetadata: false,
          };
        case 'GET_METADATA_START':
          return { ...state, loadingMetadata: true, metadataFailed: false };
        case 'GET_METADATA_SUCCESS':
          return {
            ...state,
            loadingMetadata: false,
            metadata: payload.metadata,
            metadataFailed: false,
          };
        case 'GET_METADATA_FAIL':
          return {
            ...state,
            loadingMetadata: false,
            metadataFailed: true,
          };
        case 'DELETE_POST_SUCCESS':
          return {
            ...state,
            feed: state.feed.filter((post) => post.id !== payload.postId),
          };
        case 'RESET_FORM':
          return {
            ...state,
            metadata: undefined,
            loadingMetadata: false,
            loadingFeed: false,
            metadataFailed: false,
          };
        default:
          return state;
      }
    };
  },
  doFetchNews:
    (page = 1, tags = '', query = '') =>
    ({ dispatch, apiFetch }) => {
      dispatch({
        type: 'GET_FEED_START',
        payload: { feedFilter: feedFilters.POSTS },
      });
      apiFetch(`posts?page=${page}&tags=${tags}&query=${query}&limit=9`).then(
        (response) =>
          dispatch({
            type: 'GET_FEED_SUCCESS',
            payload: { feed: response.data, pagination: response.pagination },
          }),
      );
    },

  doFetchLatestPosts:
    (limit = 1) =>
    ({ apiFetch }) =>
      apiFetch(`posts?page=1&limit=${limit}`).then((response) => response.data),

  doFetchLatestPost:
    () =>
    ({ apiFetch }) =>
      apiFetch('posts?page=1&limit=4').then((response) => response.data[0]),

  doDeletePostSuccess:
    ({ id }) =>
    ({ dispatch }) => {
      dispatch({
        type: 'DELETE_POST_SUCCESS',
        payload: {
          postId: id,
        },
      });
    },
  doCreatePost:
    ({ text, url, tags }) =>
    ({ dispatch, apiFetch }) => {
      dispatch({ type: 'CREATE_POST_START' });
      return apiFetch('posts', {
        method: 'POST',
        body: { text, url, tags },
      }).then((newPost) =>
        dispatch({
          type: 'CREATE_POST_SUCCESS',
          payload: {
            post: newPost,
          },
        }),
      );
    },
  doDeletePost:
    ({ id }) =>
    ({ apiFetch, store }) => {
      return apiFetch(`posts/${id}`, {
        method: 'DELETE',
      }).then((response) => {
        store.doUpdateRefreshCounter({ type: ContentTypes.POST });
        return response;
      });
    },
  doResetForm:
    () =>
    ({ dispatch }) => {
      dispatch({ type: 'RESET_FORM' });
    },
  doFetchMetadata:
    (url) =>
    ({ dispatch, apiFetch }) => {
      if (!isUrl(url)) return dispatch({ type: 'RESET_FORM' });
      const encodedUrl = encodeURIComponent(url);
      dispatch({ type: 'GET_METADATA_START' });
      apiFetch(`posts/metadata?url=${encodedUrl}`)
        .then((metadata) =>
          dispatch({
            type: 'GET_METADATA_SUCCESS',
            payload: { metadata },
          }),
        )
        .catch(() => dispatch({ type: 'GET_METADATA_FAIL' }));
    },
  doResetFeedPage:
    () =>
    ({ store }) =>
      store.doUpdateQuery(''),
  doFetchArticleById:
    (id) =>
    ({ apiFetch }) => {
      return apiFetch(`posts/${id}`);
    },
  doFetchArticle:
    () =>
    ({ apiFetch, store }) => {
      const routeParams = store.selectRouteParams();
      return apiFetch(`posts/${routeParams.id}`);
    },
  selectFeed: (state) => state.feed.feed,
  selectFeedPagination: (state) => state.feed.pagination,
  selectMetadata: (state) => state.feed.metadata,
  selectIsMetadataLoading: (state) => state.feed.loadingMetadata,
  selectIsFeedLoading: (state) => state.feed.loadingFeed,
  selectFeedFilter: (state) => state.feed.feedFilter,
  selectIsMetadataFailed: (state) => state.feed.metadataFailed,
};
export default feed;
