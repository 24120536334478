import { number, object } from 'idonttrustlikethat';
import { simpleNewsResponse } from '../../news/news';

const newsComponent = object({
  id: number,
  order: number,
  news: simpleNewsResponse,
});

type NewsComponent = typeof newsComponent.T;

export { newsComponent };
export type { NewsComponent };
