import React, { FC, useEffect, useState } from 'react';
import { BurgerMenuContentActions } from './actions/burger-menu-content-actions';
import { BurgerMenuContentItems } from './items/burger-menu-content-items';
import './burger-menu-content.scss';

interface BurgerMenuContentProps {
  showContent: boolean;
  onNavigate: (location: string) => void;
  userId: string;
  username: string;
  onLogout: () => void;
  getUserInitials: (username: string) => string;
  isAdmin: boolean;
}

const BurgerMenuContent: FC<BurgerMenuContentProps> = ({
  showContent,
  onNavigate,
  userId,
  username,
  onLogout,
  getUserInitials,
  isAdmin,
}) => {
  const [NJNavbarHeight, setNJNavbarHeight] = useState(0);

  useEffect(() => {
    const NJNavbar = document.getElementById('nj-navbar');

    if (showContent && NJNavbar) {
      setNJNavbarHeight(NJNavbar.offsetHeight);
    }
  }, [showContent]);

  return (
    <div
      className={`ago-burger-menu-content ${showContent ? 'ago-burger-menu-content--open' : 'ago-burger-menu-content--close'
        }`}
      style={{
        display: showContent ? 'flex' : 'none',
        top: `${NJNavbarHeight}px`,
        maxHeight: `calc(100dvh - ${NJNavbarHeight}px)`,
      }}
    >
      <BurgerMenuContentItems isAdmin={isAdmin} onNavigate={onNavigate} />
      <BurgerMenuContentActions
        onNavigate={onNavigate}
        userId={userId}
        username={username}
        onLogout={onLogout}
        getUserInitials={getUserInitials}
      />
    </div>
  );
};

export { BurgerMenuContent };
