import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  FunctionComponent,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import classNames from 'classnames';
import { Loader } from '../Loader/Loader';

export enum ButtonKind {
  default,
  info,
  warning,
  success,
  error,
  transparent,
  full,
  neutral,
  // fluid conception
  primarySubtle,
  primaryMinimal,
  secondary,
  secondarySubtle,
  secondaryMinimal,
  destructive,
  destructiveSubtle,
  destructiveMinimal,
  inverse,
  inverseSubtle,
  inverseMinimal,
}

interface PropTypes {
  onClick?: (event?: MouseEvent<HTMLElement>) => void;
  onMouseOver?: (event?: MouseEvent<HTMLElement>) => void;
  onMouseOut?: (event?: MouseEvent<HTMLElement>) => void;
  kind?: ButtonKind;
  disabled?: boolean;
  type?: DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >['type'];
  isLoading?: boolean;
  className?: string;
}

export function computedClassesButton(className, kind, disabled) {
  return classNames(
    'nj-btn',
    {
      'nj-btn--subtle': kind === ButtonKind.primarySubtle,
      'nj-btn--minimal': kind === ButtonKind.primaryMinimal,
      // secondary group
      'nj-btn--secondary': kind === ButtonKind.secondary,
      'nj-btn--secondary nj-btn--subtle': kind === ButtonKind.secondarySubtle,
      'nj-btn--secondary nj-btn--minimal': kind === ButtonKind.secondaryMinimal,
      'nj-btn--destructive': kind === ButtonKind.destructive,
      'nj-btn--destructive nj-btn--subtle': kind === ButtonKind.destructiveSubtle,
      'nj-btn--destructive nj-btn--minimal': kind === ButtonKind.destructiveMinimal,
      'nj-btn--inverse': kind === ButtonKind.inverse,
      'nj-btn--inverse nj-btn--subtle': kind === ButtonKind.inverseSubtle,
      'nj-btn--inverse nj-btn--minimal': kind === ButtonKind.inverseMinimal,
      //
      // "button-default": !disabled && kind === ButtonKind.default,
      'button-neutral': !disabled && kind === ButtonKind.neutral,
      'button-info': !disabled && kind === ButtonKind.info,
      'button-warning': !disabled && kind === ButtonKind.warning,
      'button-success': !disabled && kind === ButtonKind.success,
      'button-error': !disabled && kind === ButtonKind.error,
      'w-full': !disabled && kind === ButtonKind.full,
      'button-disabled': disabled,
    },
    className,
  );
}

const Button: FunctionComponent<PropsWithChildren<PropTypes>> = ({
  onClick,
  onMouseOver,
  onMouseOut,
  kind,
  disabled,
  type,
  children,
  isLoading = false,
  className,
}) => {
  const computedOnClick = isLoading ? undefined : onClick;
  const computedOnMouseOver = isLoading ? undefined : onMouseOver;
  const computedOnMouseOut = isLoading ? undefined : onMouseOut;
  return (
    <button
      type={type}
      className={computedClassesButton(className, kind, disabled)}
      onClick={computedOnClick}
      disabled={disabled}
      onMouseOver={computedOnMouseOver}
      onMouseOut={computedOnMouseOut}
    >
      {isLoading ? <Loader isLoading /> : children}
    </button>
  );
};

export { Button };
