import { useEffect, useState } from 'react';
import { NewsResponse } from '../../../../../../types/strapi/news/news';
import { NewsBlock } from '../../../../../../types/strapi/component/blocks/news';
import { get } from '../../../../../../services/strapi/strapiFetcher';
import StrapiRoutes from '../../../../../../services/strapi/strapiRoutes';

const useHomeNewsBlock = (newsBlock: NewsBlock, page: number) => {
  const params = {
    pagination: {
      page,
      pageSize: 3,
    },
    populate: ['author', 'image'],
    sort: 'publishedAt:DESC',
  };
  const [newsResponse, setNewsResponse] = useState<NewsResponse>({
    data: [],
  });

  const fetchLatestNews = async () => {
    await get(StrapiRoutes.news, setNewsResponse, params);
  };

  const getNews = async () => {
    newsBlock.news.length > 0
      ? setNewsResponse(newsBlockToNewsReponseMapper())
      : await fetchLatestNews();
  };

  const newsBlockToNewsReponseMapper = (): NewsResponse => {
    newsBlock.news.sort((a, b) => a.order - b.order);

    return { data: newsBlock.news.map((nw) => nw.news.data) };
  };

  useEffect(() => {
    getNews();
  }, [newsBlock, page]);

  return { newsResponse };
};

export { useHomeNewsBlock };
