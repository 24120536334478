import { literal, number, object, string } from 'idonttrustlikethat';
import { useCaseLinks } from '../use-cases/use-case-link';

const useCasesComponent = literal('blocks.use-cases');
const useCasesBlock = object({
  id: number,
  __component: useCasesComponent,
  url_list: useCaseLinks,
  title: string,
});

type UseCasesBlock = typeof useCasesBlock.T;
type UseCasesComponent = typeof useCasesComponent.T;

export { useCasesBlock, useCasesComponent };
export type { UseCasesBlock, UseCasesComponent };
