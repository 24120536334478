const domain = 'https://engie.okta-emea.com';

const config = {
  url: domain,
  issuer: `${domain}/oauth2/default`,
  redirectUri: window.location.origin,
  clientId: '0oa264pzs3SYSPO7K0i7',
};

export default config;
