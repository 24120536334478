/**
 * --------------------------------------------------------------------------
 * NJ: search.ts
 * --------------------------------------------------------------------------
 */
import AbstractComponent from '../../globals/ts/abstract-component';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';

export default class Search extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-search`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.search`;
  protected static readonly EVENT_KEY = `.${Search.DATA_KEY}`;
  protected static readonly SELECTOR = {
    default: `.${Search.NAME}`,
    field: `.${Search.NAME}__field`,
    clearButton: `.${Search.NAME}__clear-button`
  };

  private readonly root: HTMLDivElement;
  private readonly field: HTMLInputElement;
  private readonly clearButton: HTMLButtonElement;

  constructor(element: HTMLDivElement) {
    super(Search, element);
    Data.setData(element, Search.DATA_KEY, this);

    this.root = element;
    this.field = element.querySelector(Search.SELECTOR.field);
    this.clearButton = element.querySelector(Search.SELECTOR.clearButton);

    this.toggleClearButtonIfNeeded();
    this.toggleInertAttribute();

    this.setListeners();
  }

  static init(options = {}): Search[] {
    return super.init(this, options, Search.SELECTOR.default) as Search[];
  }

  dispose(): void {
    EventHandler.off(this.clearButton, 'click');
    EventHandler.off(this.field, 'input');

    Data.removeData(this.element, Search.DATA_KEY);
    this.element = null;
  }

  static getInstance(element: HTMLElement): Search {
    return Data.getData(element, Search.DATA_KEY) as Search;
  }

  private setListeners(): void {
    EventHandler.on(this.clearButton, 'click', () => {
      this.field.value = null;
      this.toggleClearButtonIfNeeded();
      this.field.focus();
    });

    EventHandler.on(this.field, 'input', () => {
      this.toggleClearButtonIfNeeded();
    });
  }

  private toggleClearButtonIfNeeded() {
    const className = `${Search.NAME}__clear-button--hidden`;

    if (!this.field.value || this.field.value.length === 0) {
      this.clearButton.classList.add(className);
    } else {
      this.clearButton.classList.remove(className);
    }
  }

  private toggleInertAttribute() {
    this.root.inert = this.root.classList.contains(`${Search.NAME}--disabled`) || this.field.disabled === true;
  }
}
