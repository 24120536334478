import { composeBundles } from 'redux-bundler';
import routes from './routes';
import extraArgs from './extra-args';
import user from './user';
import feed from './feed';
import projects from './projects';
import projectsTabs from './projectsTabs';
import initiatives from './initiatives';
import tags from './tags';
import search from './search';
import members from './members';
import inspirations from './inspirations';
import events from './events';
import refreshCounter from './refreshCounter.ts';
import metrics from './metrics';
import newsletter from './newsletter';
import missions from './missions';
import assets from './assets';
import look4dataSearch from './look4dataSearch';

export default composeBundles(
  routes,
  extraArgs,
  user,
  members,
  inspirations,
  initiatives,
  feed,
  projects,
  projectsTabs,
  tags,
  search,
  inspirations,
  refreshCounter,
  events,
  metrics,
  newsletter,
  missions,
  assets,
  look4dataSearch,
);
