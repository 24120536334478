import { getAccessToken } from '../okta/okta';

const httpGet = async <T>(url: string, setState: Function | null = null): Promise<T> => {
  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `bearer ${getAccessToken()}`,
        'Content-type': 'application/json',
      },
    });

    const json = response.ok ? await response.json() : {};

    if (setState !== null) {
      setState(json);
    }

    return json;
  } catch (error) {
    throw error;
  }
};

export { httpGet };
