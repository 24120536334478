import { FC } from 'react';
import { NewsBlock } from '../../../../../types/strapi/component/blocks/news';
import { SnippetNews } from '../../../templates/news/snippet-news/snippet-news';

interface HomeNewsBlockProps {
  newsBlock: NewsBlock;
}

const HomeNewsBlock: FC<HomeNewsBlockProps> = ({ newsBlock }) => {
  return (
    <SnippetNews
      {...{
        title: newsBlock.title,
        newsBlock,
      }}
    />
  );
};

export { HomeNewsBlock };
