import React, { FC } from 'react';
import { NJAvatar, NJIcon } from '@engie-group/fluid-design-system-react';
import './burger-menu-content-actions.scss';

interface BurgerMenuContentActionsProps {
  onNavigate: (location: string) => void;
  userId: string;
  username: string;
  onLogout: () => void;
  getUserInitials: (username: string) => string;
}

const BurgerMenuContentActions: FC<BurgerMenuContentActionsProps> = ({
  onNavigate,
  userId,
  username,
  onLogout,
  getUserInitials,
}) => {
  return (
    <div className="ago-burger-menu-content-actions">
      <div className="ago-burger-menu-content-actions-content">
        <a
          href={`/profile/${userId}`}
          className="ago-burger-menu-content-actions-content-profile"
          onClick={() => onNavigate(`/profile/${userId}`)}
        >
          <NJAvatar
            href=""
            initials={getUserInitials(username)}
            label={username}
            size="md"
          />
          <p>Profile</p>
        </a>
        <div
          onClick={onLogout}
          className="ago-burger-menu-content-actions-content-logout"
        >
          <NJIcon name="logout" />
          <p>Logout</p>
        </div>
      </div>
    </div>
  );
};

export { BurgerMenuContentActions };
