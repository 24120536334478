import { ProjectHeaderType } from '../../../../../../types/project';
import { TagType } from '../../../../../../types/tag';

interface Technology {
  name: string;
  active: boolean;
  other: boolean;
}

interface SectionItem {
  active: boolean;
  technologies: Technology[];
}
interface Jsonsection {
  [k: string]: SectionItem;
}

const useUCTechnologies = (useCase: ProjectHeaderType) => {
  const data_management = useCase.data_management || '[]';
  const data_journey = useCase.data_journey || '[]';
  const common_api = useCase.common_api || '[]';

  const safeJSONParse = (str: string) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      console.error('Erreur lors du parsing JSON:', e);
      return null;
    }
  };

  const extractActiveTechnologies = (
    data_management: Jsonsection,
    data_journey: Jsonsection,
    common_api: { [k: string]: { active: boolean; other: boolean } },
  ): string[] => {
    const json = { ...data_management, ...data_journey };
    let first_part = Object.values(json).map((section) => {
      let onlyActive = section.technologies.filter(
        (technology) => technology.active && !technology.other && technology.name !== '',
      );

      let list = onlyActive.map((tech) => tech.name);
      return list;
    });

    let res = [
      ...first_part,
      ...(Object.values(common_api).filter((item) => item.active).length > 0
        ? ['commonAPI']
        : []),
    ];
    //@ts-ignore
    return [].concat.apply([], res);
  };

  const technologies: TagType[] = extractActiveTechnologies(
    safeJSONParse(data_management),
    safeJSONParse(data_journey),
    safeJSONParse(common_api),
  ).map((val, id) => ({ id, category: '', label: val }));
  const technologiesMaxLength: number = 2;

  return {
    extractActiveTechnologies,
    safeJSONParse,
    technologies,
    technologiesMaxLength,
  };
};

export { useUCTechnologies };
