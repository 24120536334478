import { FC, PropsWithChildren, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { Button } from '../Button/Button';
import { connect } from 'redux-bundler-react';
import { Trans } from '@lingui/macro';

export interface AgreementsPopinPropsTypes {
  doFetchActiveProject: () => Promise<{}>;
  userId: string;
}

const AgreementsPopin: FC<PropsWithChildren<AgreementsPopinPropsTypes>> = ({
  userId,
}) => {
  const CURRENT_AGREEMENTS_VERSION = '1';

  const [isClosed, setIsClosed] = useState(true);

  useEffect(() => {
    if (userId) {
      const acceptedAgreementsVersion = Cookies.get('acceptedAgreementsVersion') || false;
      const acceptedUserID = Cookies.get('userID') || false;

      if (
        acceptedAgreementsVersion !== CURRENT_AGREEMENTS_VERSION ||
        acceptedUserID !== userId
      ) {
        setIsClosed(false);
      }
    }
  }, [userId]);

  function closePopin() {
    Cookies.set('acceptedAgreementsVersion', CURRENT_AGREEMENTS_VERSION, {
      expires: 365,
    });
    Cookies.set('userID', userId, { expires: 365 });
    setIsClosed(true);
  }

  return isClosed ? null : (
    <div className="AgreementsPopin fixed bottom-0 w-full z-10 bg-primary-8 text-neutral-1 p-6 pb-3">
      <p>
        <Trans
          id="As part of the use of this platform implemented by ENGIE SA and its
          subsidiaries, your personal data are processed."
          component={null}
        >
          As part of the use of this platform implemented by ENGIE SA and its
          subsidiaries, your personal data are processed.
        </Trans>{' '}
        <Trans
          id="Your data is collected based on the legitimate interest of the
          processing responsible in order to properly use Agora and to classify
          the contents of the site (Use case) by entity."
          component={null}
        >
          Your data is collected based on the legitimate interest of the processing
          responsible in order to properly use Agora and to classify the contents of the
          site (Use case) by entity.
        </Trans>{' '}
        <Trans
          id="For more information especially regarding the data and your associated
          rights"
          component={null}
        >
          For more information especially regarding the data and your associated rights
        </Trans>
        {': '}
        <a href="/data_privacy">
          "
          <Trans id="Data privacy information mention" component={null}>
            Data privacy information mention
          </Trans>
          "
        </a>
      </p>
      <div className="flex justify-end">
        <Button onClick={closePopin}>OK</Button>
      </div>
    </div>
  );
};

export default connect('selectUserId', AgreementsPopin);
