import { FC } from 'react';
import { TagType } from '../../../../../../types/tag';
import './use-case-card-title.scss';

interface UseCaseCardTitleProps {
  bus: TagType[];
  title: string;
}

const UseCaseCardTitle: FC<UseCaseCardTitleProps> = ({ bus, title }) => (<div className='ago-title' >
    {
        bus.map(bu => (
            <p className="ago-big-p-body ago-card-bu">{bu.label}</p>
        ))
    }
    < h2 className='ago-principal-title-bold ago-card-title' > {title}</h2 >
</div>)

export { UseCaseCardTitle };
