/**
 * --------------------------------------------------------------------------
 * NJ: segmented-control.ts
 * --------------------------------------------------------------------------
 */
import { Core, EventName } from '../../globals/ts/enum';
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';

export default class SegmentedControl extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-segmented-control`;
  public static readonly DATA_KEY = `${Core.KEY_PREFIX}.segmented-control`;
  protected static readonly EVENT_KEY = `.${SegmentedControl.DATA_KEY}`;

  public static CLASS_NAME = {
    component: `.${SegmentedControl.NAME}`,
    button: `.${SegmentedControl.NAME}-btn`,
    buttonSelected: `${SegmentedControl.NAME}-btn--selected`
  };

  static SELECTOR = {
    default: `.${SegmentedControl.NAME}`
  };

  private static readonly EVENT = {
    onchange: `${EventName.onchange}${SegmentedControl.EVENT_KEY}`
  };

  private readonly buttons: NodeListOf<HTMLElement>;
  public value: string;

  constructor(element: HTMLElement, options = {}) {
    super(SegmentedControl, element, options);
    this.element = element;
    Data.setData(element, SegmentedControl.DATA_KEY, this);
    this.buttons = element.querySelectorAll(SegmentedControl.CLASS_NAME.button);
    this.value = this.element.getAttribute('data-value');
    // Wait for correct render
    setTimeout(() => {
      this.buttons.forEach((el, index) => {
        const buttonValue = el.getAttribute('data-value');
        if (this.value && this.value === buttonValue) {
          this.select(index);
        }
        el.addEventListener('click', () => this.select(index));
      });
    }, 100);
  }

  /**
   * Select button at index
   * @param index
   */
  public select = (index: number): void => {
    this.buttons.forEach((el: HTMLButtonElement, ind) => {
      if (!el) {
        return;
      }
      if (index === ind) {
        el.classList.add(SegmentedControl.CLASS_NAME.buttonSelected);
        this.value = el.getAttribute('data-value');
        EventHandler.trigger(this.element, SegmentedControl.EVENT.onchange, { value: this.value });
        this.element.setAttribute('data-value', this.value);
        el.setAttribute('aria-pressed', 'true');
        // We wait for render of active class
        setTimeout(() => {
          this.computeSelectedTransition(el);
        });
      } else {
        el.classList.remove(SegmentedControl.CLASS_NAME.buttonSelected);
        el.setAttribute('aria-pressed', 'false');
      }
    });
  };

  private computeSelectedTransition = (button: HTMLButtonElement) => {
    const selectedBoundingRect = button.getBoundingClientRect();
    if (this.element) {
      const segmentedControlBoundingRect = this.element.getBoundingClientRect();
      const segmentedControlStyle = this.element.style;
      segmentedControlStyle.setProperty('--nj-sc-btn-selected-width', `${selectedBoundingRect.width}px`);
      segmentedControlStyle.setProperty(
        '--nj-sc-btn-selected-right',
        `${segmentedControlBoundingRect.right - selectedBoundingRect.right}px`
      );
    }
  };

  dispose(): void {
    this.buttons.forEach((el: HTMLElement, index) => {
      el.removeEventListener('click', () => this.select(index));
    });
    Data.removeData(this.element, SegmentedControl.DATA_KEY);
  }

  static getInstance(element: HTMLElement): SegmentedControl {
    return Data.getData(element, SegmentedControl.DATA_KEY) as SegmentedControl;
  }

  static init(options = {}): SegmentedControl[] {
    return super.init(this, options, SegmentedControl.SELECTOR.default) as SegmentedControl[];
  }
}
