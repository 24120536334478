import { FunctionComponent } from 'react';
import classNames from 'classnames';

interface PropTypes {
  isLoading?: boolean;
  dark?: boolean;
}

export const Loader: FunctionComponent<PropTypes> = ({ isLoading, dark }) => {
  const dotClass = classNames({
    'bg-neutral-0': !dark,
    'bg-neutral-6': dark,
  });
  return isLoading ? (
    <div className="lds-ellipsis">
      <div className={dotClass} />
      <div className={dotClass} />
      <div className={dotClass} />
      <div className={dotClass} />
    </div>
  ) : null;
};
