import bgImage from '../../images/png/card_bg_placeholder.png';
import { languages } from '../../types/languages';
import { FeedItemPost } from '../../types/post';
import { ProjectHeaderType } from '../../types/project';
import { userFlorent, userRoman, userToto } from '../MembersSearch/Members.fixture';
import { BETags, BUTags } from '../TagsSearch/Tags.fixture';

export const EmptyUser = {
  id: '',
  description: '',
  firstName: '',
  lastName: '',
  email: '',
  lang: '',
  isAdmin: false,
  bus: [],
  tags: [],
  be: [],
  isContributor: false,
  isAmbassador: false,
  isCdo: false,
  isDeleted: false,
  cdoOf: 666,
};

export const EmptyProjectCardFixture: ProjectHeaderType = {
  id: '',
  user: EmptyUser,
  title: '',
  shortDescription: '',
  status: 0,
  image: { id: '', url: '' },
  creationDate: new Date(),
  members: [],
  tags: [],
  type: FeedItemPost.PROJECT,
  uctype: '',
  data_journey: '',
  data_management: '',
  common_api: '',
  isBookMarked: false,
  bookMarks: 0,
  contactPoint: EmptyUser,
  fillingRate: 0,
  bus: BUTags,
  bes: BUTags,
  language: languages.ENGLIGH,
  fileImage: '',
};

export const ProjectCardFixture: ProjectHeaderType = {
  id: '123',
  user: userToto,
  title: 'Use Case Title',
  shortDescription:
    'Wind turbines make noise ! At least a little bit. That is why wind plants noise impact is limited by regulation in order to protect residents.',
  status: 2,
  image: { id: 'image', url: bgImage },
  creationDate: new Date(Date.UTC(2010, 9, 10)),
  members: [userFlorent, userRoman],
  tags: [
    {
      id: 1,
      label: 'tag 1',
      category: 'category a',
    },
    {
      id: 2,
      label: 'tag 2',
      category: 'category b',
    },
    {
      id: 3,
      label: 'tag 3',
      category: 'category c',
    },
  ],
  type: FeedItemPost.PROJECT,
  uctype: '',
  data_journey: '',
  data_management: '',
  common_api: '',
  isBookMarked: true,
  bookMarks: 11,
  contactPoint: userFlorent,
  fillingRate: 90,
  bus: BUTags,
  bes: BETags,
  language: languages.ENGLIGH,
  fileImage: bgImage,
};

export const ProjectCardsFixture: ProjectHeaderType[] = [
  {
    id: '123',
    user: userToto,
    title: 'Use Case Title',
    shortDescription:
      'Wind turbines make noise ! At least a little bit. That is why wind plants noise impact is limited by regulation in order to protect residents.',
    status: 2,
    image: { id: 'image', url: bgImage },
    creationDate: new Date(Date.UTC(2010, 9, 10)),
    members: [userFlorent, userRoman],
    tags: [
      {
        id: 1,
        label: 'tag 1',
        category: 'category a',
      },
      {
        id: 2,
        label: 'tag 2',
        category: 'category b',
      },
      {
        id: 3,
        label: 'tag 3',
        category: 'category c',
      },
    ],
    type: FeedItemPost.PROJECT,
    uctype: '',
    data_journey: '',
    data_management: '',
    common_api: '',
    isBookMarked: true,
    bookMarks: 11,
    contactPoint: userFlorent,
    fillingRate: 20,
    bus: BUTags,
    bes: BETags,
    language: languages.ENGLIGH,
    fileImage: bgImage,
  },
  {
    id: '456',
    user: userFlorent,
    title: 'Another Use Case Title',
    shortDescription: 'Wind turbines will consumed all the wind',
    status: 1,
    image: { id: 'image', url: bgImage },
    creationDate: new Date(Date.UTC(2010, 9, 10)),
    members: [userFlorent, userRoman],
    tags: [
      {
        id: 1,
        label: 'tag 1',
        category: 'category a',
      },
      {
        id: 2,
        label: 'tag 2',
        category: 'category b',
      },
      {
        id: 3,
        label: 'tag 3',
        category: 'category c',
      },
    ],
    type: FeedItemPost.PROJECT,
    uctype: '',
    data_journey: '',
    data_management: '',
    common_api: '',
    isBookMarked: false,
    bookMarks: 7,
    contactPoint: userFlorent,
    fillingRate: 40,
    bus: BUTags,
    bes: BETags,
    language: languages.ENGLIGH,
    fileImage: bgImage,
  },
];
