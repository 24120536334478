import '../../globals/js/animation';
import { Core, EventName } from '../../globals/ts/enum';
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';
import { njSemanticMotionEasingLinearOutSlowIn } from '@engie-group/fluid-design-tokens';

export default class InlineMessage extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-inline-message`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.inline-message`;
  protected static readonly EVENT_KEY = `.${InlineMessage.DATA_KEY}`;

  protected static readonly SELECTOR = {
    default: `.${InlineMessage.NAME}`,
    dismiss: `.${InlineMessage.NAME}__close`
  };

  private static readonly KEY_FRAMES = [
    {
      transform: 'scale3d(1, 1, 1)',
      opacity: 1
    },
    {
      transform: 'scale3d(0, 0, 0)',
      opacity: 0
    }
  ];

  private static readonly EVENT = {
    click: `${EventName.click}${InlineMessage.EVENT_KEY}`,
    close: `${EventName.close}${InlineMessage.EVENT_KEY}`,
    closed: `${EventName.closed}${InlineMessage.EVENT_KEY}`
  };

  constructor(element: HTMLElement) {
    super(InlineMessage, element);
    Data.setData(element, InlineMessage.DATA_KEY, this);
    this.setListeners();
  }

  closeAndDestroy(): void {
    EventHandler.trigger(this.element, InlineMessage.EVENT.close);

    if (this.element.animate)
      this.element.animate(InlineMessage.KEY_FRAMES, {
        duration: 250,
        delay: 100,
        easing: njSemanticMotionEasingLinearOutSlowIn
      }).onfinish = (): void => {
        this.destroyElement();
      };
  }

  async close(callback: () => void) {
    EventHandler.trigger(this.element, InlineMessage.EVENT.close);
    const duration = 250;
    const delay = 100;

    if (this.element.animate)
      this.element.animate(InlineMessage.KEY_FRAMES, {
        duration,
        delay,
        easing: njSemanticMotionEasingLinearOutSlowIn
      });

    await new Promise((resolve) => setTimeout(resolve, duration + delay));
    callback();
  }

  dispose(): void {
    const closeButton = this.element.querySelector(InlineMessage.SELECTOR.dismiss);
    EventHandler.off(closeButton, InlineMessage.EVENT.click);
    Data.removeData(this.element, InlineMessage.DATA_KEY);
    this.element = null;
  }

  private destroyElement(): void {
    if (this.element && this.element.parentNode) {
      this.element.parentNode.removeChild(this.element);
    }

    EventHandler.trigger(this.element, InlineMessage.EVENT.closed);
  }

  private setListeners(): void {
    const closeButton = this.element.querySelector(InlineMessage.SELECTOR.dismiss);

    EventHandler.on(closeButton, InlineMessage.EVENT.click, () => {
      this.closeAndDestroy();
    });
  }

  static init(options = {}): InlineMessage[] {
    return super.init(this, options, InlineMessage.SELECTOR.default) as InlineMessage[];
  }

  static getInstance(element: HTMLElement): InlineMessage {
    return Data.getData(element, InlineMessage.DATA_KEY) as InlineMessage;
  }
}
