/**
 * --------------------------------------------------------------------------
 * NJ: Slider.ts
 * --------------------------------------------------------------------------
 */
import { VirtualElement } from '@floating-ui/dom';
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import { Core } from '../../globals/ts/enum';
import EventHandler from '../../globals/ts/event-handler';
import Manipulator from '../../globals/ts/manipulator';
import Util from '../../globals/ts/util';
import { njTooltip, Tooltip } from '../tooltip';

export default class Slider extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-slider`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.slider`;
  private static readonly CLASS_NAME = `${Core.KEY_PREFIX}-slider`;

  protected static readonly SELECTOR = {
    default: `.${Slider.CLASS_NAME}`,
    input: 'input',
    label: 'label'
  };

  // thumb width in pixel
  private static readonly THUMB_WIDTH = 16;

  private static readonly DEFAULT_TYPE = {
    tooltip: 'boolean'
  };

  protected static readonly DEFAULT_OPTIONS = {
    tooltip: false
  };

  private static readonly PERCENT_CONV = 100;

  private static readonly PSEUDO_ELEMS = ['webkit-slider-runnable', 'moz-range', 'ms'];

  // eslint-disable-next-line no-magic-numbers
  private dataId = Number(String(Math.random()).slice(2)) + Date.now();
  private input: HTMLInputElement;
  private tooltip: Tooltip;
  private currentProgress = 0;

  constructor(element: HTMLElement, options = {}) {
    super(Slider, element, Slider.getOptions(element, options));

    this.input = this.element.querySelector(Slider.SELECTOR.input) as HTMLInputElement;
    this.element.setAttribute('data-id', this.dataId.toString());

    this.refreshProgressValue();
    this.setListeners();

    if (this.options.tooltip) {
      const anchor = document.createElement('div');
      anchor.className = 'nj-slider__tooltip-anchor';
      this.element.appendChild(anchor);

      const virtualElement: VirtualElement & {
        parentElement: HTMLElement;
        setAttribute: Element['setAttribute'];
        removeAttribute: Element['removeAttribute'];
      } = {
        parentElement: this.element,
        setAttribute: (name, value) => {
          this.input.setAttribute(name, value);
        },
        removeAttribute: (name) => {
          this.input.removeAttribute(name);
        },
        contextElement: anchor,
        getBoundingClientRect: () => {
          const { x, y, width, height, top, left, right, bottom } = this.input.getBoundingClientRect();
          const correction = this.currentProgress * (-1 * Slider.THUMB_WIDTH);
          return {
            x: width * this.currentProgress + correction + x,
            y,
            width: Slider.THUMB_WIDTH,
            height,
            top,
            left: width * this.currentProgress + correction + left,
            right,
            bottom
          };
        }
      };

      this.tooltip = njTooltip(virtualElement as HTMLElement, this.input.value, {
        hasArrow: false,
        placement: 'top'
      });

      this.tooltip.show();
      this.setTooltipListeners();
    }

    Data.setData(element, Slider.DATA_KEY, this);
  }

  private setListeners(): void {
    EventHandler.on(this.element, 'input change keyup', () => {
      this.refreshProgressValue();
    });
  }

  private setTooltipListeners(): void {
    EventHandler.on(this.element, 'input change keyup', () => {
      this.refreshTooltipValue();
    });

    const delay = 100;
    let throttled = false;

    EventHandler.on(document, 'resize', () => {
      if (!throttled) {
        this.refreshTooltipValue();
        throttled = true;
        setTimeout(() => {
          throttled = false;
        }, delay);
      }
    });
  }

  private refreshProgressValue(): void {
    const input: HTMLElement = document.querySelector(`[data-id='${this.dataId}']`);
    if (input) {
      const max = parseFloat(this.input.max) || Slider.PERCENT_CONV;
      const min = parseFloat(this.input.min) || 0;
      const value = parseFloat(this.input.value);
      this.currentProgress = (value - min) / (max - min);
      const perc = Slider.PERCENT_CONV * this.currentProgress;
      input.style.setProperty('--nj-slider-track-position', `${perc}% 100%`);
      input.style.setProperty('--nj-slider-anchor-left', `calc(${perc}% + ${this.currentProgress} * -20px + 10px)`);
    }
  }

  private refreshTooltipValue(): void {
    this.tooltip.setContent(this.input.value);
  }

  private static getOptions(element: HTMLElement, options = {}): any {
    options = {
      ...Slider.DEFAULT_OPTIONS,
      ...Manipulator.getDataAttributes(element),
      ...(typeof options === 'object' && options ? options : {})
    };

    Util.typeCheckConfig(Slider.NAME, options, Slider.DEFAULT_TYPE);

    return options;
  }

  dispose(): void {
    this.tooltip?.hide();
    EventHandler.off(this.element, 'input change keyup');
    EventHandler.off(document, 'resize');
    Data.removeData(this.element, Slider.DATA_KEY);
    this.element = null;
  }

  static getInstance(element: HTMLElement): Slider {
    return Data.getData(element, Slider.DATA_KEY) as Slider;
  }

  static init(options = {}): Slider[] {
    return super.init(this, options, Slider.SELECTOR.default) as Slider[];
  }
}
