import { eventAndTilesBlock, eventAndTilesComponent } from './event-and-tiles';
import { newsBlock, newsBlockComponent } from './news';
import { union } from 'idonttrustlikethat';
import { useCasesBlock, useCasesComponent } from './use-cases';
import { communityBlock, communityComponent } from './community';
import {
  indicatorsAndTagsBlock,
  indicatorsAndTagsComponent,
} from './indicators-and-tiles';

const homeBlocksComponents = union(
  eventAndTilesComponent,
  newsBlockComponent,
  useCasesComponent,
  communityComponent,
  indicatorsAndTagsComponent,
);
const homeBlocks = union(
  eventAndTilesBlock,
  newsBlock,
  useCasesBlock,
  communityBlock,
  indicatorsAndTagsBlock,
);

type HomeBlocks = typeof homeBlocks.T;
type HomeComponents = typeof homeBlocksComponents.T;

export { homeBlocks, homeBlocksComponents };
export type { HomeBlocks, HomeComponents };
