import './auto-init';
import Accordion from './components/accordion';
import Alert from './components/alert';
import AutocompleteInput from './components/autocomplete-input';
import Collapse from './components/collapse';
import Dropdown from './components/dropdown';
import Fab from './components/fab';
import Form from './components/form';
import Header from './components/header';
import InlineMessage from './components/inline-message';
import InputNumber from './components/input-number';
import { IMenuOptions, openMenu } from './components/menu';
import Modal, { BeforeHideEventSource } from './components/modal';
import Navbar from './components/navbar';
import Password from './components/password';
import Search from './components/search';
import SegmentedControl from './components/segmented-control';
import Select from './components/select';
import SelectInput from './components/select-input';
import Sidebar from './components/sidebar';
import Slider from './components/slider';
import Tab from './components/tab';
import Tag from './components/tag';
import {
  displayTooltipOnHoverAndFocus,
  initAllTooltips,
  ITooltipOptions,
  njTooltip,
  Tooltip
} from './components/tooltip';

/**
 * Main class of Fluid Design System library
 */
export default class NJ {
  // List of components that will be initialized automatically
  private static readonly AUTOINIT_COMPONENTS = [
    Accordion,
    Alert,
    Collapse,
    Dropdown,
    Fab,
    Form,
    Header,
    InlineMessage,
    InputNumber,
    Search,
    Modal,
    Navbar,
    Select,
    Sidebar,
    Slider,
    Tab,
    Tag,
    Password,
    SelectInput,
    AutocompleteInput,
    SegmentedControl
  ];
  // NEW_COMPONENT add component name here

  // Makes components API available
  static readonly Accordion = Accordion;
  static readonly Alert = Alert;
  static readonly Collapse = Collapse;
  static readonly Dropdown = Dropdown;
  static readonly Fab = Fab;
  static readonly Form = Form;
  static readonly Header = Header;
  static readonly InlineMessage = InlineMessage;
  static readonly InputNumber = InputNumber;
  static readonly Search = Search;
  static readonly Modal = Modal;
  static readonly Navbar = Navbar;
  static readonly Select = Select;
  static readonly Sidebar = Sidebar;
  static readonly Slider = Slider;
  static readonly Tab = Tab;
  static readonly Tag = Tag;
  static readonly njTooltip = njTooltip;
  static readonly initAllTooltips = initAllTooltips;
  static readonly displayTooltipOnHoverAndFocus = displayTooltipOnHoverAndFocus;
  static readonly Tooltip = Tooltip;
  static readonly Password = Password;
  static readonly SelectInput = SelectInput;
  static readonly AutocompleteInput = AutocompleteInput;
  static readonly openMenu = openMenu;

  // NEW_COMPONENT add component name here

  /**
   * Initialize the components listed in the AUTOINIT_COMPONENTS variable
   */
  static AutoInit(): void {
    try {
      for (let i = 0, len = NJ.AUTOINIT_COMPONENTS.length; i < len; i++) {
        NJ.AUTOINIT_COMPONENTS[i].init();
      }
    } catch (error) {
      console.error('Error while loading components: ', error);
    }
  }
}

export {
  Accordion,
  Alert,
  Collapse,
  Dropdown,
  Fab,
  Form,
  Header,
  InlineMessage,
  InputNumber,
  Search,
  Modal,
  Navbar,
  Password,
  Select,
  Sidebar,
  Slider,
  Tab,
  Tag,
  AutocompleteInput,
  SelectInput,
  SegmentedControl,
  BeforeHideEventSource,
  njTooltip,
  initAllTooltips,
  displayTooltipOnHoverAndFocus,
  Tooltip,
  ITooltipOptions,
  openMenu,
  IMenuOptions
};

declare global {
  interface Window {
    NJ: any;
  }
}

if (typeof window !== 'undefined') {
  window.NJ = NJ;
}
