import { FC } from 'react';
import './home-use-cases-summary.scss';
import { NJBadge } from '@engie-group/fluid-design-system-react';

interface HomeUseCasesSummaryProps {
  firstUseCase: string;
  secondUseCase: string;
  thirdUseCase: string;
  title: string;
}

const HomeUseCasesSummary: FC<HomeUseCasesSummaryProps> = ({
  firstUseCase,
  secondUseCase,
  thirdUseCase,
  title,
}) => {
  return (
    <div className="hucs-layout">
      <h3 className="hucs-title ago-principal-title-bold">{title}</h3>
      <div className="hucs-content ago-text-regular-16">
        <NJBadge size="lg" variant="information">
          1
        </NJBadge>
        <p>{firstUseCase}</p>
      </div>
      <div className="hucs-content ago-text-regular-16">
        <NJBadge size="lg" variant="information">
          2
        </NJBadge>
        <p>{secondUseCase}</p>
      </div>
      <div className="hucs-content ago-text-regular-16">
        <NJBadge size="lg" variant="information">
          3
        </NJBadge>
        <p>{thirdUseCase}</p>
      </div>
    </div>
  );
};

export { HomeUseCasesSummary };
