import { FC } from 'react';
import ReactPlayer from 'react-player';

interface PlayerVideoProps {
  url: string;
  width: string;
  height: string;
  iframe?: boolean;
  title?: string;
  className?: string;
  onPlay?: () => void;
  onPause?: () => void;
}

const PlayerVideo: FC<PlayerVideoProps> = ({
  url,
  width,
  height,
  iframe,
  title,
  className,
  onPlay,
  onPause,
}) => {
  return iframe ? (
    <iframe
      src={url}
      width={width}
      height={height}
      title={title}
      style={{ boxSizing: 'border-box' }}
      onClick={onPlay}
    />
  ) : (
    <div className={className}>
      <ReactPlayer
        url={url}
        width={width}
        height={height}
        onPlay={onPlay}
        onPause={onPause}
        controls
      />
    </div>
  );
};

export default PlayerVideo;
