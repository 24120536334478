import { FunctionComponent } from 'react';
import { ReactComponent as Close } from '../../../images/svg/icons/icon-close.svg';
import { ReactComponent as Plus } from '../../../images/svg/icons/plus.svg';
import { TagType } from '../../../types/tag';

export interface SelectedTagPropTypes {
  tag: TagType;
  className?: string;
  iconName?: 'close' | 'plus';
  onTagClick: (tag: TagType) => void;
}

export const SelectedTag: FunctionComponent<SelectedTagPropTypes> = ({
  tag,
  onTagClick,
  className = 'bg-primary-8 hover:bg-primary-7',
  iconName = 'close',
}) => {
  function onClick() {
    onTagClick(tag);
  }

  function renderIcon() {
    switch (iconName) {
      case 'plus':
        return <Plus className="w-4 mx-2 my-1 text-neutral-5 fill-current" />;
      case 'close':
      default:
        return <Close className="w-6 ml-2 text-neutral-1 fill-current" />;
    }
  }

  return (
    <button
      className={`focus:outline-none p-1 pl-2 text-neutral-1 flex items-center rounded ${className}`}
      onClick={onClick}
    >
      {tag.label}
      {renderIcon()}
    </button>
  );
};
