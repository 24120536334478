import { array, literal, number, object, string } from 'idonttrustlikethat';
import { newsComponent } from '../news/news-component';

const newsBlockComponent = literal('blocks.news');
const newsBlock = object({
  id: number,
  __component: newsBlockComponent,
  title: string,
  news: array(newsComponent),
  welcome_title: string,
  welcome_description: string,
});

type NewsBlock = typeof newsBlock.T;

export { newsBlock, newsBlockComponent };
export type { NewsBlock };
