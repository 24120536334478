export interface Country {
  name: string;
  ISO: string;
  emoji: string;
  unicode: string;
}

export const countries: Country[] = [
  {
    name: 'Andorra',
    ISO: 'AD',
    emoji: '🇦🇩',
    unicode: 'U+1F1E6 U+1F1E9',
  },
  {
    name: 'United Arab Emirates',
    ISO: 'AE',
    emoji: '🇦🇪',
    unicode: 'U+1F1E6 U+1F1EA',
  },
  {
    name: 'Afghanistan',
    ISO: 'AF',
    emoji: '🇦🇫',
    unicode: 'U+1F1E6 U+1F1EB',
  },
  {
    name: 'Antigua and Barbuda',
    ISO: 'AG',
    emoji: '🇦🇬',
    unicode: 'U+1F1E6 U+1F1EC',
  },
  {
    name: 'Anguilla',
    ISO: 'AI',
    emoji: '🇦🇮',
    unicode: 'U+1F1E6 U+1F1EE',
  },
  {
    name: 'Albania',
    ISO: 'AL',
    emoji: '🇦🇱',
    unicode: 'U+1F1E6 U+1F1F1',
  },
  {
    name: 'Armenia',
    ISO: 'AM',
    emoji: '🇦🇲',
    unicode: 'U+1F1E6 U+1F1F2',
  },
  {
    name: 'Angola',
    ISO: 'AO',
    emoji: '🇦🇴',
    unicode: 'U+1F1E6 U+1F1F4',
  },
  {
    name: 'Antarctica',
    ISO: 'AQ',
    emoji: '🇦🇶',
    unicode: 'U+1F1E6 U+1F1F6',
  },
  {
    name: 'Argentina',
    ISO: 'AR',
    emoji: '🇦🇷',
    unicode: 'U+1F1E6 U+1F1F7',
  },
  {
    name: 'American Samoa',
    ISO: 'AS',
    emoji: '🇦🇸',
    unicode: 'U+1F1E6 U+1F1F8',
  },
  {
    name: 'Austria',
    ISO: 'AT',
    emoji: '🇦🇹',
    unicode: 'U+1F1E6 U+1F1F9',
  },
  {
    name: 'Australia',
    ISO: 'AU',
    emoji: '🇦🇺',
    unicode: 'U+1F1E6 U+1F1FA',
  },
  {
    name: 'Aruba',
    ISO: 'AW',
    emoji: '🇦🇼',
    unicode: 'U+1F1E6 U+1F1FC',
  },
  {
    name: 'Åland Islands',
    ISO: 'AX',
    emoji: '🇦🇽',
    unicode: 'U+1F1E6 U+1F1FD',
  },
  {
    name: 'Azerbaijan',
    ISO: 'AZ',
    emoji: '🇦🇿',
    unicode: 'U+1F1E6 U+1F1FF',
  },
  {
    name: 'Bosnia and Herzegovina',
    ISO: 'BA',
    emoji: '🇧🇦',
    unicode: 'U+1F1E7 U+1F1E6',
  },
  {
    name: 'Barbados',
    ISO: 'BB',
    emoji: '🇧🇧',
    unicode: 'U+1F1E7 U+1F1E7',
  },
  {
    name: 'Bangladesh',
    ISO: 'BD',
    emoji: '🇧🇩',
    unicode: 'U+1F1E7 U+1F1E9',
  },
  {
    name: 'Belgium',
    ISO: 'BE',
    emoji: '🇧🇪',
    unicode: 'U+1F1E7 U+1F1EA',
  },
  {
    name: 'Burkina Faso',
    ISO: 'BF',
    emoji: '🇧🇫',
    unicode: 'U+1F1E7 U+1F1EB',
  },
  {
    name: 'Bulgaria',
    ISO: 'BG',
    emoji: '🇧🇬',
    unicode: 'U+1F1E7 U+1F1EC',
  },
  {
    name: 'Bahrain',
    ISO: 'BH',
    emoji: '🇧🇭',
    unicode: 'U+1F1E7 U+1F1ED',
  },
  {
    name: 'Burundi',
    ISO: 'BI',
    emoji: '🇧🇮',
    unicode: 'U+1F1E7 U+1F1EE',
  },
  {
    name: 'Benin',
    ISO: 'BJ',
    emoji: '🇧🇯',
    unicode: 'U+1F1E7 U+1F1EF',
  },
  {
    name: 'Saint Barthélemy',
    ISO: 'BL',
    emoji: '🇧🇱',
    unicode: 'U+1F1E7 U+1F1F1',
  },
  {
    name: 'Bermuda',
    ISO: 'BM',
    emoji: '🇧🇲',
    unicode: 'U+1F1E7 U+1F1F2',
  },
  {
    name: 'Brunei Darussalam',
    ISO: 'BN',
    emoji: '🇧🇳',
    unicode: 'U+1F1E7 U+1F1F3',
  },
  {
    name: 'Bolivia',
    ISO: 'BO',
    emoji: '🇧🇴',
    unicode: 'U+1F1E7 U+1F1F4',
  },
  {
    name: 'Bonaire, Sint Eustatius and Saba',
    ISO: 'BQ',
    emoji: '🇧🇶',
    unicode: 'U+1F1E7 U+1F1F6',
  },
  {
    name: 'Brazil',
    ISO: 'BR',
    emoji: '🇧🇷',
    unicode: 'U+1F1E7 U+1F1F7',
  },
  {
    name: 'Bahamas',
    ISO: 'BS',
    emoji: '🇧🇸',
    unicode: 'U+1F1E7 U+1F1F8',
  },
  {
    name: 'Bhutan',
    ISO: 'BT',
    emoji: '🇧🇹',
    unicode: 'U+1F1E7 U+1F1F9',
  },
  {
    name: 'Bouvet Island',
    ISO: 'BV',
    emoji: '🇧🇻',
    unicode: 'U+1F1E7 U+1F1FB',
  },
  {
    name: 'Botswana',
    ISO: 'BW',
    emoji: '🇧🇼',
    unicode: 'U+1F1E7 U+1F1FC',
  },
  {
    name: 'Belarus',
    ISO: 'BY',
    emoji: '🇧🇾',
    unicode: 'U+1F1E7 U+1F1FE',
  },
  {
    name: 'Belize',
    ISO: 'BZ',
    emoji: '🇧🇿',
    unicode: 'U+1F1E7 U+1F1FF',
  },
  {
    name: 'Canada',
    ISO: 'CA',
    emoji: '🇨🇦',
    unicode: 'U+1F1E8 U+1F1E6',
  },
  {
    name: 'Cocos (Keeling) Islands',
    ISO: 'CC',
    emoji: '🇨🇨',
    unicode: 'U+1F1E8 U+1F1E8',
  },
  {
    name: 'Congo, Democratic Republic of the',
    ISO: 'CD',
    emoji: '🇨🇩',
    unicode: 'U+1F1E8 U+1F1E9',
  },
  {
    name: 'Central African Republic',
    ISO: 'CF',
    emoji: '🇨🇫',
    unicode: 'U+1F1E8 U+1F1EB',
  },
  {
    name: 'Congo, Republic of the',
    ISO: 'CG',
    emoji: '🇨🇬',
    unicode: 'U+1F1E8 U+1F1EC',
  },
  {
    name: 'Switzerland',
    ISO: 'CH',
    emoji: '🇨🇭',
    unicode: 'U+1F1E8 U+1F1ED',
  },
  {
    name: "Côte D'Ivoire",
    ISO: 'CI',
    emoji: '🇨🇮',
    unicode: 'U+1F1E8 U+1F1EE',
  },
  {
    name: 'Cook Islands',
    ISO: 'CK',
    emoji: '🇨🇰',
    unicode: 'U+1F1E8 U+1F1F0',
  },
  {
    name: 'Chile',
    ISO: 'CL',
    emoji: '🇨🇱',
    unicode: 'U+1F1E8 U+1F1F1',
  },
  {
    name: 'Cameroon',
    ISO: 'CM',
    emoji: '🇨🇲',
    unicode: 'U+1F1E8 U+1F1F2',
  },
  {
    name: 'China',
    ISO: 'CN',
    emoji: '🇨🇳',
    unicode: 'U+1F1E8 U+1F1F3',
  },
  {
    name: 'Colombia',
    ISO: 'CO',
    emoji: '🇨🇴',
    unicode: 'U+1F1E8 U+1F1F4',
  },
  {
    name: 'Costa Rica',
    ISO: 'CR',
    emoji: '🇨🇷',
    unicode: 'U+1F1E8 U+1F1F7',
  },
  {
    name: 'Cuba',
    ISO: 'CU',
    emoji: '🇨🇺',
    unicode: 'U+1F1E8 U+1F1FA',
  },
  {
    name: 'Cape Verde',
    ISO: 'CV',
    emoji: '🇨🇻',
    unicode: 'U+1F1E8 U+1F1FB',
  },
  {
    name: 'Curaçao',
    ISO: 'CW',
    emoji: '🇨🇼',
    unicode: 'U+1F1E8 U+1F1FC',
  },
  {
    name: 'Christmas Island',
    ISO: 'CX',
    emoji: '🇨🇽',
    unicode: 'U+1F1E8 U+1F1FD',
  },
  {
    name: 'Cyprus',
    ISO: 'CY',
    emoji: '🇨🇾',
    unicode: 'U+1F1E8 U+1F1FE',
  },
  {
    name: 'Czech Republic',
    ISO: 'CZ',
    emoji: '🇨🇿',
    unicode: 'U+1F1E8 U+1F1FF',
  },
  {
    name: 'Germany',
    ISO: 'DE',
    emoji: '🇩🇪',
    unicode: 'U+1F1E9 U+1F1EA',
  },
  {
    name: 'Djibouti',
    ISO: 'DJ',
    emoji: '🇩🇯',
    unicode: 'U+1F1E9 U+1F1EF',
  },
  {
    name: 'Denmark',
    ISO: 'DK',
    emoji: '🇩🇰',
    unicode: 'U+1F1E9 U+1F1F0',
  },
  {
    name: 'Dominica',
    ISO: 'DM',
    emoji: '🇩🇲',
    unicode: 'U+1F1E9 U+1F1F2',
  },
  {
    name: 'Dominican Republic',
    ISO: 'DO',
    emoji: '🇩🇴',
    unicode: 'U+1F1E9 U+1F1F4',
  },
  {
    name: 'Algeria',
    ISO: 'DZ',
    emoji: '🇩🇿',
    unicode: 'U+1F1E9 U+1F1FF',
  },
  {
    name: 'Ecuador',
    ISO: 'EC',
    emoji: '🇪🇨',
    unicode: 'U+1F1EA U+1F1E8',
  },
  {
    name: 'Estonia',
    ISO: 'EE',
    emoji: '🇪🇪',
    unicode: 'U+1F1EA U+1F1EA',
  },
  {
    name: 'Egypt',
    ISO: 'EG',
    emoji: '🇪🇬',
    unicode: 'U+1F1EA U+1F1EC',
  },
  {
    name: 'Western Sahara',
    ISO: 'EH',
    emoji: '🇪🇭',
    unicode: 'U+1F1EA U+1F1ED',
  },
  {
    name: 'Eritrea',
    ISO: 'ER',
    emoji: '🇪🇷',
    unicode: 'U+1F1EA U+1F1F7',
  },
  {
    name: 'Spain',
    ISO: 'ES',
    emoji: '🇪🇸',
    unicode: 'U+1F1EA U+1F1F8',
  },
  {
    name: 'Ethiopia',
    ISO: 'ET',
    emoji: '🇪🇹',
    unicode: 'U+1F1EA U+1F1F9',
  },
  {
    name: 'Finland',
    ISO: 'FI',
    emoji: '🇫🇮',
    unicode: 'U+1F1EB U+1F1EE',
  },
  {
    name: 'Fiji',
    ISO: 'FJ',
    emoji: '🇫🇯',
    unicode: 'U+1F1EB U+1F1EF',
  },
  {
    name: 'Falkland Islands (Malvinas)',
    ISO: 'FK',
    emoji: '🇫🇰',
    unicode: 'U+1F1EB U+1F1F0',
  },
  {
    name: 'Micronesia',
    ISO: 'FM',
    emoji: '🇫🇲',
    unicode: 'U+1F1EB U+1F1F2',
  },
  {
    name: 'Faroe Islands',
    ISO: 'FO',
    emoji: '🇫🇴',
    unicode: 'U+1F1EB U+1F1F4',
  },
  {
    name: 'France',
    ISO: 'FR',
    emoji: '🇫🇷',
    unicode: 'U+1F1EB U+1F1F7',
  },
  {
    name: 'Gabon',
    ISO: 'GA',
    emoji: '🇬🇦',
    unicode: 'U+1F1EC U+1F1E6',
  },
  {
    name: 'United Kingdom',
    ISO: 'GB',
    emoji: '🇬🇧',
    unicode: 'U+1F1EC U+1F1E7',
  },
  {
    name: 'Grenada',
    ISO: 'GD',
    emoji: '🇬🇩',
    unicode: 'U+1F1EC U+1F1E9',
  },
  {
    name: 'Georgia',
    ISO: 'GE',
    emoji: '🇬🇪',
    unicode: 'U+1F1EC U+1F1EA',
  },
  {
    name: 'French Guiana',
    ISO: 'GF',
    emoji: '🇬🇫',
    unicode: 'U+1F1EC U+1F1EB',
  },
  {
    name: 'Guernsey',
    ISO: 'GG',
    emoji: '🇬🇬',
    unicode: 'U+1F1EC U+1F1EC',
  },
  {
    name: 'Ghana',
    ISO: 'GH',
    emoji: '🇬🇭',
    unicode: 'U+1F1EC U+1F1ED',
  },
  {
    name: 'Gibraltar',
    ISO: 'GI',
    emoji: '🇬🇮',
    unicode: 'U+1F1EC U+1F1EE',
  },
  {
    name: 'Greenland',
    ISO: 'GL',
    emoji: '🇬🇱',
    unicode: 'U+1F1EC U+1F1F1',
  },
  {
    name: 'Gambia',
    ISO: 'GM',
    emoji: '🇬🇲',
    unicode: 'U+1F1EC U+1F1F2',
  },
  {
    name: 'Guinea',
    ISO: 'GN',
    emoji: '🇬🇳',
    unicode: 'U+1F1EC U+1F1F3',
  },
  {
    name: 'Guadeloupe',
    ISO: 'GP',
    emoji: '🇬🇵',
    unicode: 'U+1F1EC U+1F1F5',
  },
  {
    name: 'Equatorial Guinea',
    ISO: 'GQ',
    emoji: '🇬🇶',
    unicode: 'U+1F1EC U+1F1F6',
  },
  {
    name: 'Greece',
    ISO: 'GR',
    emoji: '🇬🇷',
    unicode: 'U+1F1EC U+1F1F7',
  },
  {
    name: 'South Georgia',
    ISO: 'GS',
    emoji: '🇬🇸',
    unicode: 'U+1F1EC U+1F1F8',
  },
  {
    name: 'Guatemala',
    ISO: 'GT',
    emoji: '🇬🇹',
    unicode: 'U+1F1EC U+1F1F9',
  },
  {
    name: 'Guam',
    ISO: 'GU',
    emoji: '🇬🇺',
    unicode: 'U+1F1EC U+1F1FA',
  },
  {
    name: 'Guinea-Bissau',
    ISO: 'GW',
    emoji: '🇬🇼',
    unicode: 'U+1F1EC U+1F1FC',
  },
  {
    name: 'Guyana',
    ISO: 'GY',
    emoji: '🇬🇾',
    unicode: 'U+1F1EC U+1F1FE',
  },
  {
    name: 'Hong Kong',
    ISO: 'HK',
    emoji: '🇭🇰',
    unicode: 'U+1F1ED U+1F1F0',
  },
  {
    name: 'Heard Island and Mcdonald Islands',
    ISO: 'HM',
    emoji: '🇭🇲',
    unicode: 'U+1F1ED U+1F1F2',
  },
  {
    name: 'Honduras',
    ISO: 'HN',
    emoji: '🇭🇳',
    unicode: 'U+1F1ED U+1F1F3',
  },
  {
    name: 'Croatia',
    ISO: 'HR',
    emoji: '🇭🇷',
    unicode: 'U+1F1ED U+1F1F7',
  },
  {
    name: 'Haiti',
    ISO: 'HT',
    emoji: '🇭🇹',
    unicode: 'U+1F1ED U+1F1F9',
  },
  {
    name: 'Hungary',
    ISO: 'HU',
    emoji: '🇭🇺',
    unicode: 'U+1F1ED U+1F1FA',
  },
  {
    name: 'Indonesia',
    ISO: 'ID',
    emoji: '🇮🇩',
    unicode: 'U+1F1EE U+1F1E9',
  },
  {
    name: 'Ireland',
    ISO: 'IE',
    emoji: '🇮🇪',
    unicode: 'U+1F1EE U+1F1EA',
  },
  {
    name: 'Israel',
    ISO: 'IL',
    emoji: '🇮🇱',
    unicode: 'U+1F1EE U+1F1F1',
  },
  {
    name: 'Isle of Man',
    ISO: 'IM',
    emoji: '🇮🇲',
    unicode: 'U+1F1EE U+1F1F2',
  },
  {
    name: 'India',
    ISO: 'IN',
    emoji: '🇮🇳',
    unicode: 'U+1F1EE U+1F1F3',
  },
  {
    name: 'British Indian Ocean Territory',
    ISO: 'IO',
    emoji: '🇮🇴',
    unicode: 'U+1F1EE U+1F1F4',
  },
  {
    name: 'Iraq',
    ISO: 'IQ',
    emoji: '🇮🇶',
    unicode: 'U+1F1EE U+1F1F6',
  },
  {
    name: 'Iran',
    ISO: 'IR',
    emoji: '🇮🇷',
    unicode: 'U+1F1EE U+1F1F7',
  },
  {
    name: 'Iceland',
    ISO: 'IS',
    emoji: '🇮🇸',
    unicode: 'U+1F1EE U+1F1F8',
  },
  {
    name: 'Italy',
    ISO: 'IT',
    emoji: '🇮🇹',
    unicode: 'U+1F1EE U+1F1F9',
  },
  {
    name: 'Jersey',
    ISO: 'JE',
    emoji: '🇯🇪',
    unicode: 'U+1F1EF U+1F1EA',
  },
  {
    name: 'Jamaica',
    ISO: 'JM',
    emoji: '🇯🇲',
    unicode: 'U+1F1EF U+1F1F2',
  },
  {
    name: 'Jordan',
    ISO: 'JO',
    emoji: '🇯🇴',
    unicode: 'U+1F1EF U+1F1F4',
  },
  {
    name: 'Japan',
    ISO: 'JP',
    emoji: '🇯🇵',
    unicode: 'U+1F1EF U+1F1F5',
  },
  {
    name: 'Kenya',
    ISO: 'KE',
    emoji: '🇰🇪',
    unicode: 'U+1F1F0 U+1F1EA',
  },
  {
    name: 'Kyrgyzstan',
    ISO: 'KG',
    emoji: '🇰🇬',
    unicode: 'U+1F1F0 U+1F1EC',
  },
  {
    name: 'Cambodia',
    ISO: 'KH',
    emoji: '🇰🇭',
    unicode: 'U+1F1F0 U+1F1ED',
  },
  {
    name: 'Kiribati',
    ISO: 'KI',
    emoji: '🇰🇮',
    unicode: 'U+1F1F0 U+1F1EE',
  },
  {
    name: 'Comoros',
    ISO: 'KM',
    emoji: '🇰🇲',
    unicode: 'U+1F1F0 U+1F1F2',
  },
  {
    name: 'Saint Kitts and Nevis',
    ISO: 'KN',
    emoji: '🇰🇳',
    unicode: 'U+1F1F0 U+1F1F3',
  },
  {
    name: 'North Korea',
    ISO: 'KP',
    emoji: '🇰🇵',
    unicode: 'U+1F1F0 U+1F1F5',
  },
  {
    name: 'South Korea',
    ISO: 'KR',
    emoji: '🇰🇷',
    unicode: 'U+1F1F0 U+1F1F7',
  },
  {
    name: 'Kuwait',
    ISO: 'KW',
    emoji: '🇰🇼',
    unicode: 'U+1F1F0 U+1F1FC',
  },
  {
    name: 'Cayman Islands',
    ISO: 'KY',
    emoji: '🇰🇾',
    unicode: 'U+1F1F0 U+1F1FE',
  },
  {
    name: 'Kazakhstan',
    ISO: 'KZ',
    emoji: '🇰🇿',
    unicode: 'U+1F1F0 U+1F1FF',
  },
  {
    name: "Lao People's Democratic Republic",
    ISO: 'LA',
    emoji: '🇱🇦',
    unicode: 'U+1F1F1 U+1F1E6',
  },
  {
    name: 'Lebanon',
    ISO: 'LB',
    emoji: '🇱🇧',
    unicode: 'U+1F1F1 U+1F1E7',
  },
  {
    name: 'Saint Lucia',
    ISO: 'LC',
    emoji: '🇱🇨',
    unicode: 'U+1F1F1 U+1F1E8',
  },
  {
    name: 'Liechtenstein',
    ISO: 'LI',
    emoji: '🇱🇮',
    unicode: 'U+1F1F1 U+1F1EE',
  },
  {
    name: 'Sri Lanka',
    ISO: 'LK',
    emoji: '🇱🇰',
    unicode: 'U+1F1F1 U+1F1F0',
  },
  {
    name: 'Liberia',
    ISO: 'LR',
    emoji: '🇱🇷',
    unicode: 'U+1F1F1 U+1F1F7',
  },
  {
    name: 'Lesotho',
    ISO: 'LS',
    emoji: '🇱🇸',
    unicode: 'U+1F1F1 U+1F1F8',
  },
  {
    name: 'Lithuania',
    ISO: 'LT',
    emoji: '🇱🇹',
    unicode: 'U+1F1F1 U+1F1F9',
  },
  {
    name: 'Luxembourg',
    ISO: 'LU',
    emoji: '🇱🇺',
    unicode: 'U+1F1F1 U+1F1FA',
  },
  {
    name: 'Latvia',
    ISO: 'LV',
    emoji: '🇱🇻',
    unicode: 'U+1F1F1 U+1F1FB',
  },
  {
    name: 'Libya',
    ISO: 'LY',
    emoji: '🇱🇾',
    unicode: 'U+1F1F1 U+1F1FE',
  },
  {
    name: 'Morocco',
    ISO: 'MA',
    emoji: '🇲🇦',
    unicode: 'U+1F1F2 U+1F1E6',
  },
  {
    name: 'Monaco',
    ISO: 'MC',
    emoji: '🇲🇨',
    unicode: 'U+1F1F2 U+1F1E8',
  },
  {
    name: 'Moldova',
    ISO: 'MD',
    emoji: '🇲🇩',
    unicode: 'U+1F1F2 U+1F1E9',
  },
  {
    name: 'Montenegro',
    ISO: 'ME',
    emoji: '🇲🇪',
    unicode: 'U+1F1F2 U+1F1EA',
  },
  {
    name: 'Saint Martin (French Part)',
    ISO: 'MF',
    emoji: '🇲🇫',
    unicode: 'U+1F1F2 U+1F1EB',
  },
  {
    name: 'Madagascar',
    ISO: 'MG',
    emoji: '🇲🇬',
    unicode: 'U+1F1F2 U+1F1EC',
  },
  {
    name: 'Marshall Islands',
    ISO: 'MH',
    emoji: '🇲🇭',
    unicode: 'U+1F1F2 U+1F1ED',
  },
  {
    name: 'Macedonia',
    ISO: 'MK',
    emoji: '🇲🇰',
    unicode: 'U+1F1F2 U+1F1F0',
  },
  {
    name: 'Mali',
    ISO: 'ML',
    emoji: '🇲🇱',
    unicode: 'U+1F1F2 U+1F1F1',
  },
  {
    name: 'Myanmar',
    ISO: 'MM',
    emoji: '🇲🇲',
    unicode: 'U+1F1F2 U+1F1F2',
  },
  {
    name: 'Mongolia',
    ISO: 'MN',
    emoji: '🇲🇳',
    unicode: 'U+1F1F2 U+1F1F3',
  },
  {
    name: 'Macao',
    ISO: 'MO',
    emoji: '🇲🇴',
    unicode: 'U+1F1F2 U+1F1F4',
  },
  {
    name: 'Northern Mariana Islands',
    ISO: 'MP',
    emoji: '🇲🇵',
    unicode: 'U+1F1F2 U+1F1F5',
  },
  {
    name: 'Martinique',
    ISO: 'MQ',
    emoji: '🇲🇶',
    unicode: 'U+1F1F2 U+1F1F6',
  },
  {
    name: 'Mauritania',
    ISO: 'MR',
    emoji: '🇲🇷',
    unicode: 'U+1F1F2 U+1F1F7',
  },
  {
    name: 'Montserrat',
    ISO: 'MS',
    emoji: '🇲🇸',
    unicode: 'U+1F1F2 U+1F1F8',
  },
  {
    name: 'Malta',
    ISO: 'MT',
    emoji: '🇲🇹',
    unicode: 'U+1F1F2 U+1F1F9',
  },
  {
    name: 'Mauritius',
    ISO: 'MU',
    emoji: '🇲🇺',
    unicode: 'U+1F1F2 U+1F1FA',
  },
  {
    name: 'Maldives',
    ISO: 'MV',
    emoji: '🇲🇻',
    unicode: 'U+1F1F2 U+1F1FB',
  },
  {
    name: 'Malawi',
    ISO: 'MW',
    emoji: '🇲🇼',
    unicode: 'U+1F1F2 U+1F1FC',
  },
  {
    name: 'Mexico',
    ISO: 'MX',
    emoji: '🇲🇽',
    unicode: 'U+1F1F2 U+1F1FD',
  },
  {
    name: 'Malaysia',
    ISO: 'MY',
    emoji: '🇲🇾',
    unicode: 'U+1F1F2 U+1F1FE',
  },
  {
    name: 'Mozambique',
    ISO: 'MZ',
    emoji: '🇲🇿',
    unicode: 'U+1F1F2 U+1F1FF',
  },
  {
    name: 'Namibia',
    ISO: 'NA',
    emoji: '🇳🇦',
    unicode: 'U+1F1F3 U+1F1E6',
  },
  {
    name: 'New Caledonia',
    ISO: 'NC',
    emoji: '🇳🇨',
    unicode: 'U+1F1F3 U+1F1E8',
  },
  {
    name: 'Niger',
    ISO: 'NE',
    emoji: '🇳🇪',
    unicode: 'U+1F1F3 U+1F1EA',
  },
  {
    name: 'Norfolk Island',
    ISO: 'NF',
    emoji: '🇳🇫',
    unicode: 'U+1F1F3 U+1F1EB',
  },
  {
    name: 'Nigeria',
    ISO: 'NG',
    emoji: '🇳🇬',
    unicode: 'U+1F1F3 U+1F1EC',
  },
  {
    name: 'Nicaragua',
    ISO: 'NI',
    emoji: '🇳🇮',
    unicode: 'U+1F1F3 U+1F1EE',
  },
  {
    name: 'Netherlands',
    ISO: 'NL',
    emoji: '🇳🇱',
    unicode: 'U+1F1F3 U+1F1F1',
  },
  {
    name: 'Norway',
    ISO: 'NO',
    emoji: '🇳🇴',
    unicode: 'U+1F1F3 U+1F1F4',
  },
  {
    name: 'Nepal',
    ISO: 'NP',
    emoji: '🇳🇵',
    unicode: 'U+1F1F3 U+1F1F5',
  },
  {
    name: 'Nauru',
    ISO: 'NR',
    emoji: '🇳🇷',
    unicode: 'U+1F1F3 U+1F1F7',
  },
  {
    name: 'Niue',
    ISO: 'NU',
    emoji: '🇳🇺',
    unicode: 'U+1F1F3 U+1F1FA',
  },
  {
    name: 'New Zealand',
    ISO: 'NZ',
    emoji: '🇳🇿',
    unicode: 'U+1F1F3 U+1F1FF',
  },
  {
    name: 'Oman',
    ISO: 'OM',
    emoji: '🇴🇲',
    unicode: 'U+1F1F4 U+1F1F2',
  },
  {
    name: 'Panama',
    ISO: 'PA',
    emoji: '🇵🇦',
    unicode: 'U+1F1F5 U+1F1E6',
  },
  {
    name: 'Peru',
    ISO: 'PE',
    emoji: '🇵🇪',
    unicode: 'U+1F1F5 U+1F1EA',
  },
  {
    name: 'French Polynesia',
    ISO: 'PF',
    emoji: '🇵🇫',
    unicode: 'U+1F1F5 U+1F1EB',
  },
  {
    name: 'Papua New Guinea',
    ISO: 'PG',
    emoji: '🇵🇬',
    unicode: 'U+1F1F5 U+1F1EC',
  },
  {
    name: 'Philippines',
    ISO: 'PH',
    emoji: '🇵🇭',
    unicode: 'U+1F1F5 U+1F1ED',
  },
  {
    name: 'Pakistan',
    ISO: 'PK',
    emoji: '🇵🇰',
    unicode: 'U+1F1F5 U+1F1F0',
  },
  {
    name: 'Poland',
    ISO: 'PL',
    emoji: '🇵🇱',
    unicode: 'U+1F1F5 U+1F1F1',
  },
  {
    name: 'Saint Pierre and Miquelon',
    ISO: 'PM',
    emoji: '🇵🇲',
    unicode: 'U+1F1F5 U+1F1F2',
  },
  {
    name: 'Pitcairn',
    ISO: 'PN',
    emoji: '🇵🇳',
    unicode: 'U+1F1F5 U+1F1F3',
  },
  {
    name: 'Puerto Rico',
    ISO: 'PR',
    emoji: '🇵🇷',
    unicode: 'U+1F1F5 U+1F1F7',
  },
  {
    name: 'Palestinian Territory',
    ISO: 'PS',
    emoji: '🇵🇸',
    unicode: 'U+1F1F5 U+1F1F8',
  },
  {
    name: 'Portugal',
    ISO: 'PT',
    emoji: '🇵🇹',
    unicode: 'U+1F1F5 U+1F1F9',
  },
  {
    name: 'Palau',
    ISO: 'PW',
    emoji: '🇵🇼',
    unicode: 'U+1F1F5 U+1F1FC',
  },
  {
    name: 'Paraguay',
    ISO: 'PY',
    emoji: '🇵🇾',
    unicode: 'U+1F1F5 U+1F1FE',
  },
  {
    name: 'Qatar',
    ISO: 'QA',
    emoji: '🇶🇦',
    unicode: 'U+1F1F6 U+1F1E6',
  },
  {
    name: 'Réunion',
    ISO: 'RE',
    emoji: '🇷🇪',
    unicode: 'U+1F1F7 U+1F1EA',
  },
  {
    name: 'Romania',
    ISO: 'RO',
    emoji: '🇷🇴',
    unicode: 'U+1F1F7 U+1F1F4',
  },
  {
    name: 'Serbia',
    ISO: 'RS',
    emoji: '🇷🇸',
    unicode: 'U+1F1F7 U+1F1F8',
  },
  {
    name: 'Russia',
    ISO: 'RU',
    emoji: '🇷🇺',
    unicode: 'U+1F1F7 U+1F1FA',
  },
  {
    name: 'Rwanda',
    ISO: 'RW',
    emoji: '🇷🇼',
    unicode: 'U+1F1F7 U+1F1FC',
  },
  {
    name: 'Saudi Arabia',
    ISO: 'SA',
    emoji: '🇸🇦',
    unicode: 'U+1F1F8 U+1F1E6',
  },
  {
    name: 'Solomon Islands',
    ISO: 'SB',
    emoji: '🇸🇧',
    unicode: 'U+1F1F8 U+1F1E7',
  },
  {
    name: 'Seychelles',
    ISO: 'SC',
    emoji: '🇸🇨',
    unicode: 'U+1F1F8 U+1F1E8',
  },
  {
    name: 'Sudan',
    ISO: 'SD',
    emoji: '🇸🇩',
    unicode: 'U+1F1F8 U+1F1E9',
  },
  {
    name: 'Sweden',
    ISO: 'SE',
    emoji: '🇸🇪',
    unicode: 'U+1F1F8 U+1F1EA',
  },
  {
    name: 'Singapore',
    ISO: 'SG',
    emoji: '🇸🇬',
    unicode: 'U+1F1F8 U+1F1EC',
  },
  {
    name: 'Saint Helena, Ascension and Tristan Da Cunha',
    ISO: 'SH',
    emoji: '🇸🇭',
    unicode: 'U+1F1F8 U+1F1ED',
  },
  {
    name: 'Slovenia',
    ISO: 'SI',
    emoji: '🇸🇮',
    unicode: 'U+1F1F8 U+1F1EE',
  },
  {
    name: 'Svalbard and Jan Mayen',
    ISO: 'SJ',
    emoji: '🇸🇯',
    unicode: 'U+1F1F8 U+1F1EF',
  },
  {
    name: 'Slovakia',
    ISO: 'SK',
    emoji: '🇸🇰',
    unicode: 'U+1F1F8 U+1F1F0',
  },
  {
    name: 'Sierra Leone',
    ISO: 'SL',
    emoji: '🇸🇱',
    unicode: 'U+1F1F8 U+1F1F1',
  },
  {
    name: 'San Marino',
    ISO: 'SM',
    emoji: '🇸🇲',
    unicode: 'U+1F1F8 U+1F1F2',
  },
  {
    name: 'Senegal',
    ISO: 'SN',
    emoji: '🇸🇳',
    unicode: 'U+1F1F8 U+1F1F3',
  },
  {
    name: 'Somalia',
    ISO: 'SO',
    emoji: '🇸🇴',
    unicode: 'U+1F1F8 U+1F1F4',
  },
  {
    name: 'Suriname',
    ISO: 'SR',
    emoji: '🇸🇷',
    unicode: 'U+1F1F8 U+1F1F7',
  },
  {
    name: 'South Sudan',
    ISO: 'SS',
    emoji: '🇸🇸',
    unicode: 'U+1F1F8 U+1F1F8',
  },
  {
    name: 'Sao Tome and Principe',
    ISO: 'ST',
    emoji: '🇸🇹',
    unicode: 'U+1F1F8 U+1F1F9',
  },
  {
    name: 'El Salvador',
    ISO: 'SV',
    emoji: '🇸🇻',
    unicode: 'U+1F1F8 U+1F1FB',
  },
  {
    name: 'Sint Maarten (Dutch Part)',
    ISO: 'SX',
    emoji: '🇸🇽',
    unicode: 'U+1F1F8 U+1F1FD',
  },
  {
    name: 'Syrian Arab Republic',
    ISO: 'SY',
    emoji: '🇸🇾',
    unicode: 'U+1F1F8 U+1F1FE',
  },
  {
    name: 'Swaziland',
    ISO: 'SZ',
    emoji: '🇸🇿',
    unicode: 'U+1F1F8 U+1F1FF',
  },
  {
    name: 'Turks and Caicos Islands',
    ISO: 'TC',
    emoji: '🇹🇨',
    unicode: 'U+1F1F9 U+1F1E8',
  },
  {
    name: 'Chad',
    ISO: 'TD',
    emoji: '🇹🇩',
    unicode: 'U+1F1F9 U+1F1E9',
  },
  {
    name: 'French Southern Territories',
    ISO: 'TF',
    emoji: '🇹🇫',
    unicode: 'U+1F1F9 U+1F1EB',
  },
  {
    name: 'Togo',
    ISO: 'TG',
    emoji: '🇹🇬',
    unicode: 'U+1F1F9 U+1F1EC',
  },
  {
    name: 'Thailand',
    ISO: 'TH',
    emoji: '🇹🇭',
    unicode: 'U+1F1F9 U+1F1ED',
  },
  {
    name: 'Tajikistan',
    ISO: 'TJ',
    emoji: '🇹🇯',
    unicode: 'U+1F1F9 U+1F1EF',
  },
  {
    name: 'Tokelau',
    ISO: 'TK',
    emoji: '🇹🇰',
    unicode: 'U+1F1F9 U+1F1F0',
  },
  {
    name: 'Timor-Leste',
    ISO: 'TL',
    emoji: '🇹🇱',
    unicode: 'U+1F1F9 U+1F1F1',
  },
  {
    name: 'Turkmenistan',
    ISO: 'TM',
    emoji: '🇹🇲',
    unicode: 'U+1F1F9 U+1F1F2',
  },
  {
    name: 'Tunisia',
    ISO: 'TN',
    emoji: '🇹🇳',
    unicode: 'U+1F1F9 U+1F1F3',
  },
  {
    name: 'Tonga',
    ISO: 'TO',
    emoji: '🇹🇴',
    unicode: 'U+1F1F9 U+1F1F4',
  },
  {
    name: 'Turkey',
    ISO: 'TR',
    emoji: '🇹🇷',
    unicode: 'U+1F1F9 U+1F1F7',
  },
  {
    name: 'Trinidad and Tobago',
    ISO: 'TT',
    emoji: '🇹🇹',
    unicode: 'U+1F1F9 U+1F1F9',
  },
  {
    name: 'Tuvalu',
    ISO: 'TV',
    emoji: '🇹🇻',
    unicode: 'U+1F1F9 U+1F1FB',
  },
  {
    name: 'Taiwan',
    ISO: 'TW',
    emoji: '🇹🇼',
    unicode: 'U+1F1F9 U+1F1FC',
  },
  {
    name: 'Tanzania',
    ISO: 'TZ',
    emoji: '🇹🇿',
    unicode: 'U+1F1F9 U+1F1FF',
  },
  {
    name: 'Ukraine',
    ISO: 'UA',
    emoji: '🇺🇦',
    unicode: 'U+1F1FA U+1F1E6',
  },
  {
    name: 'Uganda',
    ISO: 'UG',
    emoji: '🇺🇬',
    unicode: 'U+1F1FA U+1F1EC',
  },
  {
    name: 'United States Minor Outlying Islands',
    ISO: 'UM',
    emoji: '🇺🇲',
    unicode: 'U+1F1FA U+1F1F2',
  },
  {
    name: 'United States',
    ISO: 'US',
    emoji: '🇺🇸',
    unicode: 'U+1F1FA U+1F1F8',
  },
  {
    name: 'Uruguay',
    ISO: 'UY',
    emoji: '🇺🇾',
    unicode: 'U+1F1FA U+1F1FE',
  },
  {
    name: 'Uzbekistan',
    ISO: 'UZ',
    emoji: '🇺🇿',
    unicode: 'U+1F1FA U+1F1FF',
  },
  {
    name: 'Vatican City',
    ISO: 'VA',
    emoji: '🇻🇦',
    unicode: 'U+1F1FB U+1F1E6',
  },
  {
    name: 'Saint Vincent and The Grenadines',
    ISO: 'VC',
    emoji: '🇻🇨',
    unicode: 'U+1F1FB U+1F1E8',
  },
  {
    name: 'Venezuela',
    ISO: 'VE',
    emoji: '🇻🇪',
    unicode: 'U+1F1FB U+1F1EA',
  },
  {
    name: 'Virgin Islands, British',
    ISO: 'VG',
    emoji: '🇻🇬',
    unicode: 'U+1F1FB U+1F1EC',
  },
  {
    name: 'Virgin Islands, U.S.',
    ISO: 'VI',
    emoji: '🇻🇮',
    unicode: 'U+1F1FB U+1F1EE',
  },
  {
    name: 'Viet Nam',
    ISO: 'VN',
    emoji: '🇻🇳',
    unicode: 'U+1F1FB U+1F1F3',
  },
  {
    name: 'Vanuatu',
    ISO: 'VU',
    emoji: '🇻🇺',
    unicode: 'U+1F1FB U+1F1FA',
  },
  {
    name: 'Wallis and Futuna',
    ISO: 'WF',
    emoji: '🇼🇫',
    unicode: 'U+1F1FC U+1F1EB',
  },
  {
    name: 'Samoa',
    ISO: 'WS',
    emoji: '🇼🇸',
    unicode: 'U+1F1FC U+1F1F8',
  },
  {
    name: 'Yemen',
    ISO: 'YE',
    emoji: '🇾🇪',
    unicode: 'U+1F1FE U+1F1EA',
  },
  {
    name: 'Mayotte',
    ISO: 'YT',
    emoji: '🇾🇹',
    unicode: 'U+1F1FE U+1F1F9',
  },
  {
    name: 'South Africa',
    ISO: 'ZA',
    emoji: '🇿🇦',
    unicode: 'U+1F1FF U+1F1E6',
  },
  {
    name: 'Zambia',
    ISO: 'ZM',
    emoji: '🇿🇲',
    unicode: 'U+1F1FF U+1F1F2',
  },
  {
    name: 'Zimbabwe',
    ISO: 'ZW',
    emoji: '🇿🇼',
    unicode: 'U+1F1FF U+1F1FC',
  },
];
