import { array, object, number, string } from 'idonttrustlikethat';

const videoCaption = object({
  data: object({
    id: number,
    attributes: object({
      name: string,
      alternativeText: string.nullable(),
      caption: string.nullable(),
      width: number,
      height: number,
      formats: object({
        thumbnail: object({
          ext: string,
          url: string,
          hash: string,
          mime: string,
          name: string,
          path: string.nullable(),
          size: number,
          width: number,
          height: number,
        }),
      }),
      hash: string,
      ext: string,
      mime: string,
      size: number,
      url: string,
      previewUrl: string.nullable(),
      provider: string,
      provider_metadata: string.nullable(),
      createdAt: string,
      updatedAt: string,
    }),
  }).nullable(),
});

const videoLink = object({
  id: number,
  order: number,
  url: string,
  title: string,
  video_caption: videoCaption,
});

const videoLinks = array(videoLink);

const videoLinkTypedList = array(
  object({
    id: number,
    order: number,
    url: string,
    title: string,
    type: number,
    video_caption: videoCaption,
  }),
);

const videoLinkTyped = object({
  id: number,
  order: number,
  url: string,
  title: string,
  type: number,
  video_caption: videoCaption,
});

type VideoLink = typeof videoLink.T;
type VideoLinks = typeof videoLinks.T;
type VideoLinkTyped = typeof videoLinkTyped.T;
type VideoLinkTypedList = typeof videoLinkTypedList.T;

export { videoLink, videoLinks, videoLinkTyped, videoLinkTypedList };
export type { VideoLink, VideoLinks, VideoLinkTyped, VideoLinkTypedList };
