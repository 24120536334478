import { useEffect, useState } from 'react';
import { ProjectHeaderType, ProjectResponse } from '../../../../../../types/project';
import { EmptyProjectCardFixture } from '../../../../../ProjectCard/ProjectCard.fixtures';
import { UseCasesBlock } from '../../../../../../types/strapi/component/blocks/use-cases';
import { apiFetcher } from '../../../../../../services/api/apiFetcher';

const useUseCaseBlock = (block: UseCasesBlock, limit = 3) => {
  const [isLatestProjectLoading, setIsLatestProjectLoading] = useState<boolean>(true);
  const [latestProject, setLatestProject] = useState<ProjectHeaderType[]>([
    EmptyProjectCardFixture,
  ]);

  const fetchLatestProjects = async () => {
    setIsLatestProjectLoading(true);
    setLatestProject(
      (await apiFetcher<ProjectResponse>(`projects/header?page=1&limit=${limit}`)).data,
    );
    setIsLatestProjectLoading(false);
  };

  const fetchProjects = async () => {
    setIsLatestProjectLoading(true);
    const projects = await Promise.all(
      block.url_list.map((ucComponent) =>
        apiFetcher<ProjectHeaderType>(`projects/${ucComponent.use_case_id}/header`),
      ),
    );
    setLatestProject(projects);
    setIsLatestProjectLoading(false);
  };

  useEffect(() => {
    block.url_list.length === 0 ? fetchLatestProjects() : fetchProjects();
  }, [block.url_list]);

  return { isLatestProjectLoading, latestProject };
};

export { useUseCaseBlock };
