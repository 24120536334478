import './snippet-news.scss';
import { FC, useState } from 'react';
import { NJIcon } from '@engie-group/fluid-design-system-react';
import { SnippetNewsBlock } from './snippet-news-block';
import { NewsBlock } from '../../../../../types/strapi/component/blocks/news';
import rehypeRaw from 'rehype-raw';
import Markdown from 'react-markdown';

interface SnippetNewsProps {
  newsBlock: NewsBlock;
}

const SnippetNews: FC<SnippetNewsProps> = ({ newsBlock }) => {
  const [bckgPicture, setBckgPicture] = useState('');
  const [backgroundTrigger, setBackgroundTrigger] = useState<boolean>(false);

  return (
    <div
      className={`ago-news-container home-block-full-size ${
        backgroundTrigger ? 'test' : ''
      }`}
      style={
        {
          '--ago-news-container-background': `url(${bckgPicture})`,
        } as React.CSSProperties
      }
    >
      <div className="content">
        <section className="welcome-text">
          <h1 className="ago-tera-title light-blue-900">{newsBlock.welcome_title}</h1>
          <Markdown
            {...{
              className: 'markdown',
              rehypePlugins: [rehypeRaw],
            }}
          >
            {newsBlock.welcome_description}
          </Markdown>
        </section>
        <section className="news-list">
          <h3 className="ago-principal-title-bold">{newsBlock.title}</h3>
          <SnippetNewsBlock
            newsBlock={newsBlock}
            onMount={(picture) => {
              setBckgPicture(picture);
            }}
            onHover={(picture) => {
              setBckgPicture(picture);
              setBackgroundTrigger(true);
            }}
            onMouseLeave={() => setBackgroundTrigger(false)}
          />
        </section>
      </div>
      <div className="ago-arrow-container">
        <div
          className="arrow-button"
          onClick={() => {
            const navbar = document.getElementById('nj-navbar');
            const element = document.getElementById('home-scroll-anchor');

            if (element && navbar) {
              const offsetY = navbar.clientHeight;
              const positionY = element.getBoundingClientRect().top;

              window.scrollTo({ top: positionY - offsetY, behavior: 'smooth' });
            }
          }}
        >
          <div className="arrow-button-border">
            <NJIcon className="arrow-button" name="keyboard_arrow_down" size="xl" />
          </div>
        </div>
      </div>
    </div>
  );
};

export { SnippetNews };
