import { FC } from 'react';
import './home-indicators-and-tags-block.scss';
import { HomeIndicatorsSummary } from './indicators/home-indicators-summary';
import { HomeTagsSummary } from './tags/home-tags-summary';
import { HomeUseCasesSummary } from './use-cases/home-use-cases-summary';
import { Loader } from '../../../Loader/Loader';
import { useHomeIndicatorsAndTagsBlock } from './hooks/use-indicators-and-tags-block';

const HomeIndicatorsAndTagsBlock: FC = () => {
  const { isLoading, indicatorsAndTags, leaderboard } = useHomeIndicatorsAndTagsBlock();
  const getIndustrializedUseCases = (): number => {
    if (indicatorsAndTags) {
      return (
        indicatorsAndTags.numberOfProjectsInProduction +
        indicatorsAndTags.numberOfProjectsInIndustrialization
      );
    }
    return 0;
  };
  return (
    <div className="hit-backgroud">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="hit-layout">
          <div className="hit-row">
            <HomeIndicatorsSummary
              firstIndicator={
                indicatorsAndTags?.numberOfBUsWithSharedProjects + ' Entities Sharing UC'
              }
              secondIndicator={indicatorsAndTags?.numberOfContributors + ' Contributors'}
              thirdIndicator={indicatorsAndTags?.numberOfUsers + ' Users'}
              title={'Community'}
            />
            <HomeIndicatorsSummary
              firstIndicator={indicatorsAndTags?.numberOfProjects + ' Use Cases'}
              secondIndicator={getIndustrializedUseCases() + ' Industrialized Use Cases'}
              thirdIndicator={indicatorsAndTags?.numberOfPosts + ' Articles'}
              title={'Contents'}
            />
          </div>
          <div className="hit-row">
            <HomeTagsSummary
              title={'Popular Tags'}
              tags={indicatorsAndTags?.numberOfContributionByTag ?? []}
            />
            <HomeUseCasesSummary
              firstUseCase={leaderboard[0].label + ' (' + leaderboard[0].total + ')'}
              secondUseCase={leaderboard[1].label + ' (' + leaderboard[1].total + ')'}
              thirdUseCase={leaderboard[2].label + ' (' + leaderboard[2].total + ')'}
              title={'Use Cases Leaderboard'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { HomeIndicatorsAndTagsBlock };
