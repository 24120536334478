import { useMemo } from 'react';
import { CommunityBlock } from '../../../../../../types/strapi/component/blocks/community';

const useCommunity = (communityData: CommunityBlock) => {
  const videosList = useMemo(
    () => communityData.video_links,
    [communityData.video_links],
  );

  const yammerLink = useMemo(
    () => communityData.yammer_link,
    [communityData.yammer_link],
  );

  const yammerTitle = useMemo(
    () => communityData.yammer_title,
    [communityData.yammer_title],
  );

  const communityTitle = useMemo(() => communityData.title, [communityData.title]);
  return {
    videosList,
    yammerLink,
    yammerTitle,
    communityTitle,
  };
};

export { useCommunity };
