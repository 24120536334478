import { FC } from 'react';
import './image-card-tag.scss';

interface ImageCardTagProps {
  title: string;
}

const ImageCardTag: FC<ImageCardTagProps> = ({ title }) => {
  return (
    <div className="ago-image-card-tag">
      {' '}
      <p className="ago-text-bold-blue-16">{title}</p>{' '}
    </div>
  );
};

export { ImageCardTag };
