/* eslint-disable @typescript-eslint/no-unused-vars */
import './home.scss';

import { FunctionComponent } from 'react';
import { RouteInfoType } from '../../types/routing';
import { DataFetcher } from '../../components/atomic/templates/data-fetcher';
import { referalApiWS } from '../../config/unsplash';
import StrapiRoutes from '../../services/strapi/strapiRoutes';
import { agoraHome } from '../../types/strapi/home/agora-home';
import { HomeDynamicZone } from '../../components/atomic/templates/home/home-dynamic-zone';

interface PropTypes {
  routeInfo: RouteInfoType;
}

export const Home: FunctionComponent<PropTypes> = ({ routeInfo }) => {
  return (
    <div className="">
      <DataFetcher
        {...{
          route: `${referalApiWS}/api/${StrapiRoutes.agoraHome}`,
          params: {
            populate: [
              'information_banner',
              'information_banner.information_banner',
              'content',
              'content.event',
              'content.tiles',
              'content.tiles.image',
              'content.news',
              'content.news.news',
              'content.news.news.image',
              'content.news.news.author',
              'content.video_links',
              'content.url_list',
              'content.video_links.video_caption',
            ],
          },
          validator: agoraHome,
        }}
      >
        {(data) => (
          <HomeDynamicZone
            {...{
              blocks: data.data.attributes.content,
              informationBanner: data.data.attributes.information_banner,
            }}
          />
        )}
      </DataFetcher>
    </div>
  );
};

export default Home;
