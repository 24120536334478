export const initialState = {};
const look4dataSearch = {
  name: 'look4dataSearch',
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        default:
          return state;
      }
    };
  },
  doFetchSearch:
    ({ query, page = 1, abortControllerSignal }) =>
    ({ apiFetch }) => {
      return apiFetch(`look4data?query=${encodeURI(query)}&page=${page}`, {
        signal: abortControllerSignal,
      });
    },

  doSendFeedback:
    ({ id, search_query, level, feedback_text }) =>
    ({ apiFetch }) => {
      return apiFetch('look4data', {
        method: 'POST',
        body: {
          user_id: id,
          search_query,
          level,
          feedback_text,
        },
      });
    },
};
export default look4dataSearch;
