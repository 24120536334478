import { type FC, useEffect, useState } from 'react';
import { type VFetchResult, vfetch } from '../../../utils/fetch';
// import { DSCErrors } from 'components/atoms/errors/dsc-errors'
// import { DSCSpinner } from 'components/atoms/spinner/dsc-spinner'
import type { Validator } from 'idonttrustlikethat';
import qs from 'qs';
import { getToken } from '../../../services/authService.js';

interface Props<T> {
  route: string;
  params?: object;
  rawparams?: string;
  validator: Validator<T>;
  children: FC<T>;
}

interface HookProps<T> {
  route: string;
  params?: object;
  rawparams?: string;
  validator: Validator<T>;
}

export const DataFetcher = <T extends object>({
  children,
  route,
  params,
  rawparams,
  validator,
}: Props<T>) => {
  const data: VFetchResult<T> | undefined = useFetcher({ route, validator, params, rawparams });

  if (!data) return <p>loading...</p>;

  if (data && !data.ok) return <p>Error... ({JSON.stringify(data.error, null, 2)})</p>;

  return children(data.value);
};

export const useFetcher = <T extends object>({ route, validator, params, rawparams }: HookProps<T>): VFetchResult<T> | undefined => {
  const [token, setToken] = useState('');
  getToken('accessToken').then(({ accessToken }) => setToken(accessToken));
  return useData(route, validator, token, params, rawparams)
}

const useData = <T extends object>(
  route: string,
  validator: Validator<T>,
  token: string,
  params?: object,
  rawparams?: string,
): VFetchResult<T> | undefined => {
  const [data, setData] = useState<VFetchResult<T>>();

  if (params) {
    const parametersConverted = qs.stringify(params, {
      encodeValuesOnly: true,
    });
    route += '?' + (rawparams ? parametersConverted + rawparams : parametersConverted);
  }

  const getData = () => {
    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    return vfetch(route, { ok: validator }, { headers });
  };

  useEffect(() => {
    if (token !== '') {
      getData().then(setData);
    }
  }, [route, token]);

  return data;
};
