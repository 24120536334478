import { FC } from 'react';
import { HomeBlocks } from '../../../../types/strapi/component/blocks/home-block';
import { newsBlockComponent } from '../../../../types/strapi/component/blocks/news';
import './home-dynamic-zone.scss';
import { eventAndTilesComponent } from '../../../../types/strapi/component/blocks/event-and-tiles';
import { useCasesComponent } from '../../../../types/strapi/component/blocks/use-cases';
import { HomeNewsBlock } from '../../organisms/news/blocks/home-news-block';
import { UseCaseBlock } from '../../molecules/use-cases/blocks/use-case-block';
import { HomeEventAndTilesBlock } from '../../organisms/events-and-tiles/blocks/home-events-and-tiles-block';
import { ApplicationNews } from '../../../../types/strapi/component/home/application_news';
import { InformationBanner } from '../../organisms/information-banner/information-banner';
import { communityComponent } from '../../../../types/strapi/component/blocks/community';
import CommunityAndVideosBlock from '../../organisms/community/blocks/community-block';
import { HomeIndicatorsAndTagsBlock } from '../../organisms/indicators-and-tags/home-indicators-and-tags-block';
import { indicatorsAndTagsComponent } from '../../../../types/strapi/component/blocks/indicators-and-tiles';

interface HomeDynamicZoneProps {
  blocks: HomeBlocks[];
  informationBanner: ApplicationNews;
}
const HomeDynamicZone: FC<HomeDynamicZoneProps> = ({ blocks, informationBanner }) => {
  return (
    <>
      <InformationBanner informationBanner={informationBanner}></InformationBanner>
      {blocks.map((block: HomeBlocks, index: number) => {
        const dynamicZoneBuilder = () => {
          switch (block.__component) {
            case newsBlockComponent.literal:
              return (
                <HomeNewsBlock
                  key={block.__component}
                  {...{
                    newsBlock: block,
                    informationBanner,
                  }}
                />
              );

            case eventAndTilesComponent.literal:
              return (
                <HomeEventAndTilesBlock
                  key={block.__component}
                  {...{
                    eventAndTilesBlock: block,
                  }}
                />
              );

            case useCasesComponent.literal:
              return <UseCaseBlock key={block.__component} {...{ block }} />;

            case communityComponent.literal:
              return (
                <CommunityAndVideosBlock
                  key={block.__component}
                  {...{
                    communityData: block,
                  }}
                ></CommunityAndVideosBlock>
              );

            case indicatorsAndTagsComponent.literal:
              return <HomeIndicatorsAndTagsBlock key={block.__component} />;

            default:
              return <p>{block}</p>;
          }
        };
        return (
          <>
            {index === 1 && <div id="home-scroll-anchor" />}
            {dynamicZoneBuilder()}
          </>
        );
      })}
    </>
  );
};

export { HomeDynamicZone };
