import { array, isoDate, number, object, string } from 'idonttrustlikethat';

const informationBannerAttributes = object({
  background_color: string,
  content: string,
  end_date: isoDate,
  text_color: string,
});

const informationBanner = object({
  id: number,
  attributes: informationBannerAttributes,
});

const informationBanners = array(informationBanner);
const informationBannerResponse = object({
  data: informationBanner,
  meta: object({}).optional(),
});

const informationBannersResponse = object({
  data: informationBanners,
  meta: object({}),
});

type InformationBanner = typeof informationBanner.T;
type InformationBanners = typeof informationBanners.T;
type InformationBannerResponse = typeof informationBannerResponse.T;
type InformationBannersResponse = typeof informationBannersResponse.T;
type InformationBannerAttributes = typeof informationBannerAttributes.T;

export {
  informationBanner,
  informationBanners,
  informationBannerResponse,
  informationBannersResponse,
  informationBannerAttributes,
};

export type {
  InformationBanner,
  InformationBanners,
  InformationBannerResponse,
  InformationBannersResponse,
  InformationBannerAttributes,
};
