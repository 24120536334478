import { array, number, object } from 'idonttrustlikethat';

const useCaseLink = object({
  use_case_id: number,
  order: number,
});

const useCaseLinks = array(useCaseLink);

type UseCaseLink = typeof useCaseLink.T;
type UseCaseLinks = typeof useCaseLinks.T;

export { useCaseLink, useCaseLinks };
export type { UseCaseLink, UseCaseLinks };
