import React, { FC } from 'react';
import { BurgerMenuContent } from './content/burger-menu-content';
import { NJIconButton } from '@engie-group/fluid-design-system-react';
import useShowBurgerMenuContent from './hooks/use-show-burger-menu-content';
import './burger-menu.scss';

interface BurgerMenuProps {
  isAdmin: boolean;
  userId: string;
  username: string;
  onLogout: () => void;
  getUserInitials: (username: string) => string;
}

const BurgerMenu: FC<BurgerMenuProps> = ({
  isAdmin,
  userId,
  username,
  onLogout,
  getUserInitials,
}) => {
  const [showBurgerMenuContent, setShowBurgerMenuContent] =
    useShowBurgerMenuContent(false);

  return (
    <>
      <div className="ago-burger-menu-icons">
        <NJIconButton
          className="ago-burger-menu-icon"
          icon={showBurgerMenuContent ? 'close' : 'menu'}
          label="Burger Menu"
          size="lg"
          onClick={() => {
            setShowBurgerMenuContent(!showBurgerMenuContent);
          }}
        />
        <a href="/search_nav">
          <NJIconButton
            className="ago-burger-menu-icon"
            icon="search"
            label="Search"
            size="lg"
            onClick={() => {
              setShowBurgerMenuContent(false);
            }}
          />
        </a>
      </div>
      <BurgerMenuContent
        isAdmin={isAdmin}
        showContent={showBurgerMenuContent}
        onNavigate={() => setShowBurgerMenuContent(false)}
        userId={userId}
        username={username}
        onLogout={onLogout}
        getUserInitials={getUserInitials}
      />
    </>
  );
};

export { BurgerMenu };
