import { FC } from 'react';
import LinkButton from '../../../../LinkButton/LinkButton';
import { UseCaseCard } from '../use-case-card/use-case-card';
import { UseCasesBlock } from '../../../../../types/strapi/component/blocks/use-cases';
import { useUseCaseBlock } from './hooks/use-use-case-block';
import './use-case-block.scss';
interface UseCaseBlockProps {
  block: UseCasesBlock;
}

const UseCaseBlock: FC<UseCaseBlockProps> = ({ block }) => {
  const { latestProject } = useUseCaseBlock(block);
  return (
    <div className="ago-use-cases-homepage home-block-full-size">
      <h3 className="ago-subtitle-h3-bold ago-usecases-title">Use Cases</h3>
      <div className="ago-last-usecases">
        <div className="ago-last-usecases-flex">
          {latestProject.map((useCase, index) => (
            <UseCaseCard key={index} index={index} useCase={useCase} />
          ))}
        </div>
        <div className="ago-bottom-button">
          <LinkButton url="/projects" label="All the Use Cases" />
        </div>
      </div>
    </div>
  );
};

export { UseCaseBlock };
