import { FC } from 'react';
import { EventAndTilesBlock } from '../../../../../types/strapi/component/blocks/event-and-tiles';
import { HomeTilesBlock } from './tiles/home-tiles-block';
import { HomeEventsBlock } from './events/home-events-block';
import './home-events-and-tiles-block.scss';
import { useHomeEventAndTilesBlock } from './hooks/use-home-events-and-tiles-block';

interface HomeEventAndTilesBlockProps {
  eventAndTilesBlock: EventAndTilesBlock;
}

const HomeEventAndTilesBlock: FC<HomeEventAndTilesBlockProps> = ({
  eventAndTilesBlock,
}) => {
  const { isEventsLoading, events } = useHomeEventAndTilesBlock(eventAndTilesBlock);
  return (
    <div className="het-margin het-direction">
      <HomeTilesBlock tilesList={eventAndTilesBlock.tiles} />
      <HomeEventsBlock
        {...{
          isEventsLoading,
          eventList: events,
          title: eventAndTilesBlock.event_title,
        }}
      />
    </div>
  );
};

export { HomeEventAndTilesBlock };
