import {
  autoUpdate,
  computePosition,
  flip,
  FlipOptions,
  hide,
  HideOptions,
  offset,
  OffsetOptions,
  Placement,
  ReferenceElement,
  shift,
  ShiftOptions
} from '@floating-ui/dom';

export interface IMenuOptions {
  placement?: Placement;
  offsetOptions?: OffsetOptions;
  flipOptions?: FlipOptions;
  shiftOptions?: ShiftOptions;
  hideOptions?: HideOptions;
}

interface IReturn {
  menu: HTMLDivElement;
  unmount: () => void;
}
const createMenuElement = (content: Element) => {
  const menu = document.createElement('div');
  menu.className = 'nj-menu';
  menu.appendChild(content);
  return menu;
};

/**
 * Opens a menu at the given coordinates or anchor element.
 * @param coordinate The coordinate on which the menu should open
 * @param content Element which will be rendered in the menu
 * @param appendTo Element or element id on which the menu will be appended in DOM. This element must be positioned (not `position: static`)
 * @param options Options to configure how the menu should behave (see. [Floating UI Documentation](https://floating-ui.com/docs/middleware) to understand middleware options)
 *
 * @returns the generated menu `HTMLDivElement` and a function to unmount it, call the unmount function to hide the menu.
 */
export function openMenu(
  coordinate: { x: number; y: number },
  content: Element,
  appendTo?: Element | string,
  options?: IMenuOptions
): IReturn;

/**
 * Opens a menu at the given coordinates or anchor element.
 * @param anchor The reference element on which the menu should open
 * @param content Element which will be rendered in the menu
 * @param appendTo Element or element id on which the menu will be appended in DOM. This element must be positioned (not `position: static`)
 * @param options Options to configure how the menu should behave (see. [Floating UI Documentation](https://floating-ui.com/docs/middleware) to understand middleware options)
 *
 * @returns the generated menu `HTMLDivElement` and a function to unmount it, call the unmount function to hide the menu.
 */
export function openMenu(
  anchor: Element,
  content: Element,
  appendTo?: Element | string | 'parent',
  options?: IMenuOptions
): IReturn;

/**
 * Opens a menu at the given coordinates or anchor element.
 * @param at The coordinate or the reference element on which the menu should open
 * @param content Element which will be rendered in the menu
 * @param appendTo Element or element id on which the menu will be appended in DOM. This element must be positioned (not `position: static`)
 * @param options Options to configure how the menu should behave (see. [Floating UI Documentation](https://floating-ui.com/docs/middleware) to understand middleware options)
 *
 * @returns the generated menu `HTMLDivElement` and a function to unmount it, call the unmount function to hide the menu.
 */
export function openMenu(
  at: { x: number; y: number } | Element,
  content: Element,
  appendTo?: Element | string | 'parent',
  options?: IMenuOptions
): IReturn {
  let triggerElement: ReferenceElement & { parentElement: Element };
  if ('x' in at) {
    triggerElement = {
      getBoundingClientRect() {
        return {
          x: at.x,
          y: at.y,
          top: at.y,
          left: at.x,
          bottom: at.y,
          right: at.x,
          width: 0,
          height: 0
        };
      },
      parentElement: document.body
    };
  } else {
    triggerElement = at;
  }
  const menu = createMenuElement(content);
  let parentElement = triggerElement.parentElement;
  if (appendTo !== 'parent') {
    if (typeof appendTo === 'string') {
      parentElement = document.getElementById(appendTo) ?? parentElement;
    } else {
      parentElement = appendTo;
    }
  }
  parentElement.appendChild(menu);
  const unmount = autoUpdate(triggerElement, menu, () => {
    computePosition(triggerElement, menu, {
      ...options,
      middleware: [
        options?.offsetOptions && offset(options.offsetOptions),
        options?.flipOptions && flip(options.flipOptions),
        options?.shiftOptions && shift(options.shiftOptions),
        options?.hideOptions && hide(options.hideOptions)
      ]
    }).then(({ x, y, middlewareData }) => {
      Object.assign(menu.style, {
        left: `${x}px`,
        top: `${y}px`
      });

      if (options?.hideOptions?.strategy && middlewareData.hide) {
        Object.assign(menu.style, {
          visibility: middlewareData.hide[options.hideOptions.strategy] ? 'hidden' : 'visible'
        });
      }
    });
  });

  return {
    menu,
    unmount: () => {
      parentElement.removeChild(menu);
      unmount();
    }
  };
}

export default {
  openMenu
};
