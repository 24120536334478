import { FC, PropsWithChildren, ReactNode } from 'react';

export interface CustomModalPropTypes {
  header: string | ReactNode;
  closeModal: () => void;
  isOpen: boolean;
}

export const CustomModal: FC<PropsWithChildren<CustomModalPropTypes>> = ({
  header,
  closeModal,
  isOpen,
  children,
}) =>
  isOpen ? (
    <div className="flex flex-col justify-center fixed inset-0 z-40" data-test-modal>
      <div className="fixed inset-0 opacity-50 bg-neutral-1" onClick={closeModal} />
      <div className="inline-block mx-auto bg-neutral-0 py-4 shadow-lg z-50 overflow-auto">
        <div className="mb-4 px-10">
          <h2 className="font-bold text-xl">{header}</h2>
        </div>
        {children}
      </div>
    </div>
  ) : null;
