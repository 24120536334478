import { NJTag, TagSize } from '@engie-group/fluid-design-system-react';
import { TBrandExtendedColorVariants } from '@engie-group/fluid-design-system-react/lib/global';
import { FC } from 'react';

export const TagLink: FC<{
  id: number;
  text: string;
  category: string;
  className: string;
  closeAriaLabel: string;
  size: TagSize | undefined;
  variant: TBrandExtendedColorVariants | undefined;
}> = ({ id, text, category, className, closeAriaLabel, size, variant }) => {
  return (
    <a
      className={className}
      key={id + text}
      href={`${
        category.toLowerCase().includes('be')
          ? '/bes/' + id
          : category.toLowerCase().includes('bu')
            ? '/bus/' + id
            : '/tags/' + id
      }`}
    >
      <NJTag
        onClick={function noRefCheck() {}}
        closeAriaLabel={closeAriaLabel}
        label={text}
        size={size}
        variant={variant}
      />
    </a>
  );
};
