import { FunctionComponent } from 'react';
import { InputDropdown } from '../InputDropdown/InputDropdown';
import { TagType } from '../../types/tag';
import { TagItem } from './TagItem';

export interface TagsSearchPropTypes {
  tags: TagType[];
  onTagClick: (tag: TagType) => void;
  placeholder: string;
  label?: string;
  inputId?: string;
}

export const TagsSearch: FunctionComponent<TagsSearchPropTypes> = ({
  tags,
  onTagClick,
  placeholder,
  label,
  inputId = 'tag-search',
}) => {
  function filterTags(inputValue: string) {
    return tags.filter((tag) =>
      tag.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  }

  function renderItems(inputValue: string) {
    return filterTags(inputValue).map((tag) => (
      <TagItem key={tag.id} tag={tag} onTagClick={onTagClick} />
    ));
  }

  return (
    <InputDropdown
      id={inputId}
      renderItems={renderItems}
      placeholder={placeholder}
      label={label}
      disabled={tags.length === 0}
    />
  );
};
