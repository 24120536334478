import { Trans } from '@lingui/macro';
import { FunctionComponent } from 'react';

import { PaginationType } from '../../types/pagination';

interface PropTypes extends PaginationType {
  updateQueryParams: (queryParams: { [key: string]: string | undefined }) => void;
  currentQueryParams: { [key: string]: string | undefined };
}

export const Pagination: FunctionComponent<PropTypes> = ({
  currentPage,
  totalPages,
  totalResults,
  updateQueryParams,
  currentQueryParams,
}) => {
  function updatePageParam(page: string) {
    updateQueryParams({ ...currentQueryParams, page });
  }

  return totalResults > 0 ? (
    <div className="flex flex-col items-center py-4">
      <ul className="flex border border-neutral-2 border-r-0 rounded">
        {currentPage > 2 && (
          <PageButton updatePageParam={updatePageParam} pageNumber={1} />
        )}
        {currentPage > 3 && (
          <div className="text-neutral-8 border-r border-neutral-2 px-3 py-2">...</div>
        )}
        {currentPage > 1 && (
          <PageButton updatePageParam={updatePageParam} pageNumber={currentPage - 1} />
        )}
        <div className="text-neutral-0 bg-primary-8 border-r border-neutral-2 px-3 py-2">
          {currentPage}
        </div>
        {currentPage !== totalPages ? (
          <PageButton updatePageParam={updatePageParam} pageNumber={currentPage + 1} />
        ) : null}
        {currentPage < totalPages - 2 && (
          <div className="text-neutral-8 border-r border-neutral-2 px-3 py-2">...</div>
        )}
        {currentPage < totalPages - 1 && (
          <PageButton updatePageParam={updatePageParam} pageNumber={totalPages} />
        )}
      </ul>
      <span className="text-sm text-neutral-6 mt-2">
        {totalResults + ' '}
        <Trans id="result(s)" component={null}>
          result(s)
        </Trans>
      </span>
    </div>
  ) : null;
};

interface PageLinkPropTypes {
  pageNumber: number;
  updatePageParam: (page: string) => void;
}

export const PageButton: FunctionComponent<PageLinkPropTypes> = ({
  pageNumber,
  updatePageParam,
}) => {
  function onClick() {
    updatePageParam(pageNumber.toString());
  }

  return (
    <div>
      <button
        className="focus:outline-none block hover:text-neutral-0 hover:bg-primary-8 text-neutral-8 border-r border-neutral-2 px-3 py-2"
        onClick={onClick}
      >
        {pageNumber}
      </button>
    </div>
  );
};
