import { ChangeEvent, FunctionComponent, useCallback } from 'react';
import { ReactComponent as Search } from '../../images/svg/icons/icon-search.svg';

interface SearchInputPropTypes {
  setInputValue: (value: string) => void;
  value: string;
  placeHolder?: string;
}

export const SearchInput: FunctionComponent<SearchInputPropTypes> = ({
  setInputValue,
  value,
  placeHolder,
}) => {
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value),
    [setInputValue],
  );

  return (
    <div className="relative mx-4">
      <input
        id="search-input"
        className="pl-12 sm:pl-20 sm:text-3xl shadow appearance-none border border-neutral-3 rounded w-full py-2 pr-3 text-neutral-5 leading-tight focus:outline-none focus:shadow-outline"
        onChange={onChange}
        value={value}
        placeholder={placeHolder}
      />
      <div className="absolute left-0 inset-y-0 flex items-center px-2 border-neutral-4 border-r bg-neutral-4 rounded rounded-r-none">
        <div className="w-6 sm:w-10 fill-current text-neutral-1">
          <Search />
        </div>
      </div>
    </div>
  );
};
