import './community-block.scss';
import { FC } from 'react';
import { CommunityBlock } from '../../../../../types/strapi/component/blocks/community';
import { useCommunity } from './hooks/use-community';
import VideosCommunity from '../videos/videos-community';
import { SocialMedia } from '../social-media/social-media';

interface CommunityAndVideosBlockProps {
  communityData: CommunityBlock;
}

const CommunityAndVideosBlock: FC<CommunityAndVideosBlockProps> = ({ communityData }) => {
  const { videosList, yammerLink, yammerTitle, communityTitle } =
    useCommunity(communityData);
  return (
    <div className="ago-community-block-container">
      <VideosCommunity
        className="ago-video-community"
        communityTitle={communityTitle}
        videosLink={videosList}
      />
      <SocialMedia
        className="ago-social-media"
        yammerTitle={yammerTitle}
        yammerLink={yammerLink}
      />
    </div>
  );
};

export default CommunityAndVideosBlock;
