import { referalApiWS } from '../../config/unsplash';
import qs from 'qs';
import { httpGet } from '../http/http';

const get = async (
  route: string,
  setState: Function | null = null,
  search: Object | null = {},
  requirePopulateDeep: boolean = true,
) => {
  const finalSearch = qs.stringify({
    populate: requirePopulateDeep ? 'deep' : '*',
    ...search,
  });
  const url = `${referalApiWS}/api/${route}?${finalSearch}`;

  return httpGet(url, setState);
};

export { get };
