import { array, number, object, string, boolean } from 'idonttrustlikethat';
import { imageFormat } from '../image-format';

export type NewsAttributes = {
  title: string;
  publication_date: string;
  content: string;
  author: string;
  image: string;
  slug: string;
};

const author = object({
  data: object({
    id: number,
    attributes: object({
      username: string,
      firstname: string,
      lastname: string,
      email: string,
      company_role: string.nullable(),
      role: string.nullable(),
      createdAt: string,
      updatedAt: string.nullable(),
    }),
  }),
});

const news = object({
  author: author,
  title: string,
  publication_date: string,
  image: imageFormat,
  publishedAt: string,
  slug: string.nullable(),
  description: string.default(''),
});

const nullableNews = object({
  author: author.nullable(),
  title: string,
  publication_date: string,
  image: imageFormat.nullable(),
  publishedAt: string,
  slug: string.nullable(),
  description: string.default(''),
});

const nullableSimpleNews = object({
  id: number,
  attributes: nullableNews,
});

const simpleNews = object({
  id: number,
  attributes: news,
});

const newsDetails = object({
  id: number,
  attributes: object({
    title: string,
    publication_date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    publishedAt: string,
    is_featured: boolean,
    slug: string.nullable(),
    author: author,
    image: imageFormat,
    description: string,
  }),
});

const newsDetailsResponse = object({
  data: array(newsDetails),
});

const nullableNewsResponse = object({
  data: array(nullableSimpleNews),
});

const newsResponse = object({
  data: array(simpleNews),
  meta: object({
    pagination: object({
      page: number,
      pageSize: number,
      pageCount: number,
      total: number.default(0),
    }),
  }).nullable(),
});

const simpleNewsResponse = object({ data: simpleNews });

type NewsDetails = typeof newsDetails.T;
type News = typeof news.T;
type NewsDetailsResponse = typeof newsDetailsResponse.T;
type NewsResponse = typeof newsResponse.T;
type SimpleNews = typeof simpleNews.T;
type SimpleNewsResponse = typeof simpleNewsResponse.T;

export type {
  NewsDetailsResponse,
  NewsDetails,
  News,
  NewsResponse,
  SimpleNews,
  SimpleNewsResponse,
};
export {
  newsDetailsResponse,
  newsDetails,
  news,
  newsResponse,
  nullableNewsResponse,
  simpleNewsResponse,
};
