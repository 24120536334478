import { array, boolean, number, object } from 'idonttrustlikethat';
import { informationBannerResponse } from '../../information-banner/information-banner';

const applicationNew = object({
  id: number,
  is_active: boolean,
  information_banner: informationBannerResponse,
});

const applicationNews = array(applicationNew);

type ApplicationNew = typeof applicationNew.T;
type ApplicationNews = typeof applicationNews.T;

export { applicationNew, applicationNews };
export type { ApplicationNew, ApplicationNews };
