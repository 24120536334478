import React, { FC, ReactNode } from 'react';
import useWindowLocation from '../../hooks/use-window-location';
import './burger-menu-content-item.scss';

interface BurgerMenuContentItemProps {
  children: ReactNode;
  id: string;
  href: string;
  onNavigate: (location: string) => void;
}

const BurgerMenuContentItem: FC<BurgerMenuContentItemProps> = ({
  children,
  href,
  id,
  onNavigate,
}) => {
  const [windowLocation] = useWindowLocation(window.location.pathname);

  return (
    <a
      className={`ago-burger-menu-content-item ${
        href === windowLocation && 'ago-burger-menu-content-item--active'
      }`}
      href={href}
      id={id}
      onClick={() => onNavigate && onNavigate(windowLocation)}
    >
      {children}
    </a>
  );
};

export { BurgerMenuContentItem };
