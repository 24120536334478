import { FC } from 'react';
import { TilesResponse } from '../../../../../../types/strapi/tile/tile';
import { HomeTileCard } from './home-tile-card';
import './home-tiles-block.scss';

interface HomeTilesBlockProps {
  tilesList: TilesResponse;
}

const HomeTilesBlock: FC<HomeTilesBlockProps> = ({ tilesList }) => {
  return (
    <div className="ht-list">
      {tilesList.data.map((tile) => (
        <HomeTileCard key={tile.attributes.title.toLowerCase()} tile={{ data: tile }} />
      ))}
    </div>
  );
};

export { HomeTilesBlock };
