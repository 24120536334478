/**
 * --------------------------------------------------------------------------
 * Fork from Bootstrap (v4-without-jquery): util.js
 * --------------------------------------------------------------------------
 */

/**
 * ------------------------------------------------------------------------
 * Private TransitionEnd Helpers
 * ------------------------------------------------------------------------
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TRANSITION_END = 'transitionend';
const MAX_UID = 1000000;
const MILLISECONDS_MULTIPLIER = 1000;

// Shoutout AngusCroll (https://goo.gl/pxwQGp)
function toType(obj): string {
  return {}.toString
    .call(obj)
    .match(/\s([a-z]+)/i)[1]
    .toLowerCase();
}

/**
 * --------------------------------------------------------------------------
 * Public Util Api
 * --------------------------------------------------------------------------
 */

const Util = {
  TRANSITION_END: 'transitionend',

  getUID(prefix: string): string {
    do {
      // eslint-disable-next-line no-bitwise
      prefix += ~~(Math.random() * MAX_UID); // "~~" acts like a faster Math.floor() here
    } while (document.getElementById(prefix));
    return prefix;
  },

  getSelectorFromElement(element: Element): string | null {
    let selector = element.getAttribute('data-target');

    if (!selector || selector === '#') {
      const hrefAttr = element.getAttribute('href');
      selector = hrefAttr && hrefAttr !== '#' ? hrefAttr.trim() : '';
    }

    try {
      return document.querySelector(selector) ? selector : null;
    } catch (err) {
      return null;
    }
  },

  getTransitionDurationFromElement(element?: Element): number {
    if (!element) {
      return 0;
    }

    // Get transition-duration of the element
    let transitionDuration = window.getComputedStyle(element).transitionDuration;
    let transitionDelay = window.getComputedStyle(element).transitionDelay;

    const floatTransitionDuration = parseFloat(transitionDuration);
    const floatTransitionDelay = parseFloat(transitionDelay);

    // Return 0 if element or transition duration is not found
    if (!floatTransitionDuration && !floatTransitionDelay) {
      return 0;
    }

    // If multiple durations are defined, take the first
    transitionDuration = transitionDuration.split(',')[0];
    transitionDelay = transitionDelay.split(',')[0];

    return (parseFloat(transitionDuration) + parseFloat(transitionDelay)) * MILLISECONDS_MULTIPLIER;
  },

  reflow(element: HTMLElement): number {
    return element.offsetHeight;
  },

  triggerTransitionEnd(element: Element): void {
    const e = new CustomEvent(Util.TRANSITION_END, {});
    element.dispatchEvent(e);
  },

  isElement(obj): boolean {
    return (obj[0] || obj).nodeType;
  },

  emulateTransitionEnd(element: Element, duration: number): void {
    let called = false;
    const durationPadding = 5;
    const emulatedDuration = duration + durationPadding;

    function listener(): void {
      called = true;
      element.removeEventListener(Util.TRANSITION_END, listener);
    }

    element.addEventListener(Util.TRANSITION_END, listener);
    setTimeout(() => {
      if (!called) {
        Util.triggerTransitionEnd(element);
      }
    }, emulatedDuration);
  },

  typeCheckConfig(componentName, config, configTypes): void {
    for (const property in configTypes) {
      if (Object.prototype.hasOwnProperty.call(configTypes, property)) {
        const expectedTypes = configTypes[property];
        const value = config[property];
        const valueType = value && Util.isElement(value) ? 'element' : toType(value);

        if (!new RegExp(expectedTypes).test(valueType)) {
          throw new Error(
            `${componentName.toUpperCase()}: ` +
              `Option "${property}" provided type "${valueType}" ` +
              `but expected type "${expectedTypes}".`
          );
        }
      }
    }
  },

  makeArray(nodeList: HTMLCollection | NodeListOf<Element>): Element[] {
    if (typeof nodeList === 'undefined' || nodeList === null) {
      return [];
    }

    return [].slice.call(nodeList);
  },

  findShadowRoot(element: Element): ShadowRoot | null {
    if (!document.documentElement.attachShadow) {
      return null;
    }

    // Can find the shadow root otherwise it'll return the document
    if (typeof element.getRootNode === 'function') {
      const root = element.getRootNode();
      return root instanceof ShadowRoot ? root : null;
    }

    if (element instanceof ShadowRoot) {
      return element;
    }

    // when we don't find a shadow root
    if (!element.parentNode) {
      return null;
    }

    return Util.findShadowRoot(element.parentElement);
  },

  throttle(func, wait, leading, trailing, context): ReturnFunction {
    let args;
    let ctx;
    let result;
    let timeout = null;
    let previous = 0;
    const later = (): void => {
      previous = Date.now();
      timeout = null;
      result = func.apply(ctx, args);
    };
    return (): ReturnFunction => {
      const now = Date.now();
      if (!previous && !leading) previous = now;
      const remaining = wait - (now - previous);
      ctx = context || this;
      // eslint-disable-next-line prefer-rest-params
      args = arguments;

      if (remaining <= 0) {
        clearTimeout(timeout);
        timeout = null;
        previous = now;
        result = func.apply(ctx, args);
      } else if (!timeout && trailing) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    };
  },

  /**
   * Coerces value to a boolean
   * @param value - value to be coerced
   *
   * @exemple
   * - `coerceBool('something')` returns `true`
   * - `coerceBool(undefined)` returns `false`
   * - `coerceBool(null)` returns `false`
   */
  coerceBool(value): boolean {
    return Boolean(value);
  }
};

export interface ReturnFunction {
  (): unknown | void;
}

export default Util;
