import { array, number, object } from 'idonttrustlikethat';

const eventLink = object({
  id: number,
  event_id: number,
  order: number,
});

const eventLinks = array(eventLink);

type EventLink = typeof eventLink.T;
type EventLinks = typeof eventLinks.T;

export { eventLink, eventLinks };
export type { EventLink, EventLinks };
