import { User } from '../../types/user';

export const userToto: User = {
  id: '1',
  description: '',
  firstName: 'toto',
  lastName: 'titi',
  email: 'to@to.com',
  lang: 'fr',
  isAdmin: false,
  bus: [],
  tags: [],
  be: [],
  isContributor: true,
  isAmbassador: true,
  isCdo: false,
  isDeleted: false,
  cdoOf: 666,
};
export const userBenoit = {
  id: '00uj28nchqIon4heT0h7',
  description: '',
  firstName: 'Benoit',
  lastName: 'Jarlier',
  email: 'bjarlier@octo.com',
  lang: 'en',
  isAdmin: false,
  bus: [],
  tags: [],
  be: [],
  isContributor: false,
  isAmbassador: false,
  isCdo: false,
  isDeleted: false,
  cdoOf: 666,
};
export const userJules = {
  id: '00uj28mmzjnXkL1xr0h7',
  description: '',
  firstName: 'Jules',
  lastName: 'Guyot',
  email: 'jguyot@octo.com',
  lang: 'en',
  isAdmin: true,
  bus: [],
  tags: [],
  be: [],
  isContributor: false,
  isAmbassador: true,
  isCdo: false,
  isDeleted: false,
  cdoOf: 666,
};
export const userRoman = {
  id: '00uiyqiknbdjLRhjT0h7',
  description: '',
  firstName: 'Roman',
  lastName: 'Quelen',
  email: 'rquelen@octo.com',
  lang: 'fr',
  isAdmin: true,
  bus: [],
  tags: [],
  be: [],
  isContributor: true,
  isAmbassador: true,
  isCdo: false,
  isDeleted: false,
  cdoOf: 666,
};
export const userFlorent = {
  id: '00ufviy2vyqDPXLPW0h7',
  description: '',
  firstName: 'Florent',
  lastName: 'Lagrede',
  email: 'flagrede@octo.com',
  lang: 'en',
  isAdmin: true,
  bus: [],
  tags: [],
  be: [],
  isContributor: true,
  isAmbassador: false,
  isCdo: false,
  isDeleted: false,
  cdoOf: 666,
};

export const members: User[] = [userToto, userBenoit, userJules, userRoman, userFlorent];
