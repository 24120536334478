import React, { FC } from 'react';
import { BurgerMenuContentItem } from './burger-menu-content-item';
import './burger-menu-content-items.scss';
import { getMainNavbarItems, NavBarLinks } from '../../../nav-bar/hooks/use-agora-nav-bar';

interface BurgerMenuContentItemsProps {
  isAdmin: boolean,
  onNavigate: (location: string) => void;
}

const BurgerMenuContentItems: FC<BurgerMenuContentItemsProps> = ({ isAdmin, onNavigate }) => {
  const menuItems: NavBarLinks[] = [{ id: 'home', name: 'Home', href: '/' }]

  return (
    <div className="ago-burger-menu-content-items">
      {menuItems.concat(getMainNavbarItems(isAdmin)).map((item, index) => (
        <BurgerMenuContentItem
          key={item.id}
          href={item.href}
          id={`navitem${index}`}
          onNavigate={onNavigate}
        >
          {item.name}
        </BurgerMenuContentItem>
      ))}
    </div>
  );
};

export { BurgerMenuContentItems };
