import { FC } from 'react';
import {
  ProjectStatusDatum,
  ProjectStatusEnum,
  ProjectStatuses,
} from '../../../../../../consts/project-status';
import './use-case-card-footer.scss';
interface Props {
  fillingRate: string;
  firstName: string;
  lastName: string;
  status: ProjectStatusEnum;
}

const getProjectStatus = (status: ProjectStatusEnum) => {
  const projectStatus: ProjectStatusDatum =
    ProjectStatuses.find(
      (projectStatus: ProjectStatusDatum) => projectStatus.value === status,
    ) || ProjectStatuses[0];
  if (projectStatus) {
    return projectStatus.title;
  }
};

const UseCaseCardFooter: FC<Props> = ({
    fillingRate,
    firstName,
    lastName,
    status
}) => {
    const authorDetails = [
        { label: 'Created by:', value: `${firstName} ${lastName}` },
        { label: 'Filled:', value: `${fillingRate}%` },
        { label: 'Currently in:', value: getProjectStatus(status) },
    ];
    return <div className='author-container'>
        <div className="author-container-labels-values">
            {authorDetails.map((detail, index) => (
                <div key={index} className="label-value-pair">
                    <p className='ago-big-p-body ago-detail-lable'>{detail.label}</p>
                    <p className='ago-text-bold-black-16'>{detail.value}</p>
                </div>
            ))}
        </div>
    </div>
};

export { UseCaseCardFooter };
