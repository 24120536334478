import './videos-community.scss';
import { FC, useState } from 'react';
import { VideoLinks } from '../../../../../types/strapi/component/videos/video';
import PlayerVideo from '../../../atoms/player-video/player-video';
import { useWindowsSizeBlock } from '../../../../../utils/hooks/use-windows-size-block';
import { useTypesVideos, TypeVideo } from './hooks/use-types-videos';
import { referalApiWS } from '../../../../../config/unsplash';
import { NJButton } from '@engie-group/fluid-design-system-react';
import VideoPlaceholder from '../../../../../images/jpg/video-placeholder.jpg';

interface VideosCommunityProps {
  communityTitle: string;
  videosLink: VideoLinks;
  className: string;
}

const VideosCommunity: FC<VideosCommunityProps> = ({
  communityTitle,
  videosLink,
  className,
}) => {
  const { typedVideos } = useTypesVideos(videosLink);

  const { width } = useWindowsSizeBlock();
  const videoHeight = width > 1300 ? '578px' : '400px';

  const [watchedVideo, setWatchedVideo] = useState(typedVideos[0].url);
  const [typeOfVideo, setTypeOfVideo] = useState(typedVideos[0].type);
  const [title, setTitle] = useState(typedVideos[0].title);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);

  const loadVideoClicked = (
    url: string,
    type: TypeVideo,
    title: string,
    selectedIndex: number,
  ) => {
    setIsPlayingVideo(false);
    setWatchedVideo(url);
    setTypeOfVideo(type);
    setTitle(title);
    setSelectedIndex(selectedIndex);
  };

  const activeVideoClass = (index: number) => {
    return selectedIndex === index ? 'video-caption--active' : '';
  };

  typeOfVideo === TypeVideo.SHAREPOINT_ENGIE &&
    isPlayingVideo !== true &&
    setIsPlayingVideo(true);

  return (
    <div className={className}>
      <h1 className="ago-principal-title-bold ago-title-video-community">
        {communityTitle}
      </h1>
      <div className="ago-video-player-container">
        {
          <PlayerVideo
            url={watchedVideo}
            width="100%"
            height={videoHeight}
            iframe={typeOfVideo === TypeVideo.SHAREPOINT_ENGIE}
            title={title}
            onPlay={() => setIsPlayingVideo(true)}
            onPause={() => setIsPlayingVideo(false)}
          />
        }

        <div className="ago-list-videos">
          {typedVideos
            .sort((a, b) => a.order - b.order)
            .map((video, index) => {
              return (
                <div
                  key={video.title.toLowerCase()}
                  className={`video-caption-${index.toString()} ${activeVideoClass(
                    index,
                  )}`}
                  onClick={() =>
                    loadVideoClicked(video.url, video.type, video.title, index)
                  }
                >
                  <img
                    className="video-caption-image"
                    src={
                      video.video_caption.data
                        ? `${referalApiWS}${video.video_caption.data.attributes.url}`
                        : VideoPlaceholder
                    }
                    alt="video first frame"
                  />{' '}
                  {activeVideoClass(index) ? (
                    <div className="ago-animation-watch">
                      <section
                        style={
                          {
                            '--white-bar-animation-state': isPlayingVideo
                              ? 'running'
                              : 'paused',
                          } as React.CSSProperties
                        }
                        className="ago-container-animation-video"
                      >
                        <span className="ago-animated-white-bar-1"></span>
                        <span className="ago-animated-white-bar-2"></span>
                        <span className="ago-animated-white-bar-3"></span>
                      </section>
                    </div>
                  ) : (
                    <></>
                  )}
                  <h3 className="ago-principal-title-bold ago-video-caption-title">
                    {video.title}
                  </h3>
                </div>
              );
            })}
        </div>
      </div>
      <div className="ago-button-video">
        <NJButton
          href={'https://engie.sharepoint.com/sites/OneDataTeamCommunication'}
          variant="primary"
          label="All videos"
          target="_blank"
        ></NJButton>
      </div>
    </div>
  );
};

export default VideosCommunity;
