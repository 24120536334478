const truncateText = (text: string, maxLength: number): string => {
  if ((text?.length ?? 0) <= maxLength) {
    return text;
  }

  const truncated = text.substr(0, maxLength);
  const lastSpace = truncated.lastIndexOf(' ');

  if (lastSpace !== -1) {
    return truncated.substr(0, lastSpace) + '...';
  }

  return truncated + '...';
};

const toTitleCase = (text: string): string => {
  const lowerCaseInput = text.toLowerCase();
  return lowerCaseInput.replace(/(^\w|-\w)/g, (char) => char.toUpperCase());
};

export { truncateText, toTitleCase };
