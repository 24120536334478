import { useEffect, useState } from 'react';
import { apiFetcher } from '../../../../../services/api/apiFetcher';
import { IndicatorsAndTagsData } from '../../../../../types/strapi/component/blocks/api-indicator-data/indicators-and-tiles-data';
import { LeaderboardData } from '../../../../../types/strapi/component/blocks/api-indicator-data/leaderboard-data';

const useHomeIndicatorsAndTagsBlock = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [indicatorsAndTags, setIndicatorsAndTags] = useState<IndicatorsAndTagsData>();
  const [leaderboard, setLeaderboard] = useState<LeaderboardData>([]);

  const fetchAll = async () => {
    setIsLoading(true);
    setIndicatorsAndTags(await apiFetcher<IndicatorsAndTagsData>(`kpi/general`));
    setLeaderboard(await apiFetcher<LeaderboardData>(`bus`));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAll();
  }, []);

  return { isLoading, indicatorsAndTags, leaderboard };
};

export { useHomeIndicatorsAndTagsBlock };
