import { isoDate, object } from 'idonttrustlikethat';

export type StrapiCommonObject<T> = {
  id: number;
  attributes: T;
};

export type StrapiDatumResponse<T> = {
  data: StrapiCommonObject<T>;
  meta: Object;
};

export type StrapiDataResponse<T> = {
  data: StrapiCommonObject<T>[];
  meta: Object;
};

const strapiCommonDates = object({
  createdAt: isoDate,
  publishedAt: isoDate,
  updatedAt: isoDate,
});

type StrapiCommonDates = typeof strapiCommonDates.T;

export { strapiCommonDates };
export type { StrapiCommonDates };
