const extraArgs = {
  name: 'extra-args',
  getExtraArgs: (store) => {
    return {
      apiFetch: (urlPath, options = {}) => {
        const endpointResource = `${process.env.REACT_APP_API_URL}/${urlPath}`;
        const accessToken = store.selectAccessToken();
        const { method, body, ...otherOptions } = options;
        const computedOptions = {
          headers: { Authorization: `Bearer ${accessToken}` },
          ...otherOptions,
        };

        if (method) {
          computedOptions.method = method;
        }

        if (body instanceof FormData) {
          computedOptions.body = body;
        } else if (body) {
          computedOptions.body = JSON.stringify(body);
        }

        return fetch(endpointResource, computedOptions)
          .then((res) => {
            if (res.status >= 400) {
              throw res;
            }
            return res.json();
          })
          .catch((err) => {
            store.doCheckIsAuthenticated();
            throw err;
          });
      },
    };
  },
};
export default extraArgs;
