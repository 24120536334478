import {
  VideoLinks,
  VideoLinkTypedList,
} from '../../../../../../types/strapi/component/videos/video';
enum TypeVideo {
  YOUTUBE,
  AGORA_STRAPI,
  SHAREPOINT_ENGIE,
}

const useTypesVideos = (videosLink: VideoLinks) => {
  const isYoutubeURL = (url: string) => {
    return url.includes('www.youtube');
  };

  const isStrapiURL = (url: string) => {
    return url.includes('agora-strapiv2');
  };

  const isEngieSharePointURL = (url: string) => {
    return url.includes('engie.sharepoint');
  };

  const typedVideos: VideoLinkTypedList = videosLink.map((video) => {
    if (isYoutubeURL(video.url)) {
      return {
        ...video,
        type: TypeVideo.YOUTUBE,
      };
    } else if (isStrapiURL(video.url)) {
      return { ...video, type: TypeVideo.AGORA_STRAPI };
    } else if (isEngieSharePointURL(video.url)) {
      return { ...video, type: TypeVideo.SHAREPOINT_ENGIE };
    } else {
      throw new Error('Video is not in enum type');
    }
  });

  return { typedVideos };
};

export { useTypesVideos, TypeVideo };
