import { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'redux-bundler-react';
import { ReactComponent as SmileFace } from '../../images/svg/icons/smile-face.svg';
import { ReactComponent as NeutralFace } from '../../images/svg/icons/neutral-face.svg';
import { ReactComponent as SadFace } from '../../images/svg/icons/sad-face.svg';
import { ReactComponent as ThumbsUp } from '../../images/svg/icons/thumbs-up.svg';
import TextArea from '../../components/TextArea/TextArea';
import { Button } from '../../components/Button/Button';
import { FeedbackPayload, LevelSatisfied } from '../../types/look4dataSearch';
import { User } from '../../types/user';
import { Loader } from '../../components/Loader/Loader';

interface FeedbackPropTypes {
  doSendFeedback: (payload: FeedbackPayload) => Promise<any>;
  user: User;
  searchQuery: string;
}

const Feedback: FunctionComponent<FeedbackPropTypes> = ({
  doSendFeedback,
  user,
  searchQuery,
}) => {
  const [levelSatisfied, setLevelSatisfied] = useState<LevelSatisfied | null>(null);
  const [feedbackText, setFeedbackText] = useState<string>('');

  const isSubmittable = levelSatisfied && feedbackText && searchQuery;
  const [isPostLoading, setIsPostLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  function onInputChange({ target: { value } }) {
    setFeedbackText(value);
  }

  function onPost() {
    setIsPostLoading(true);
    const { id } = user;

    const payload: FeedbackPayload = {
      id,
      search_query: searchQuery,
      level: levelSatisfied,
      feedback_text: feedbackText,
    };
    return doSendFeedback(payload)
      .then(() => {
        setIsSubmitted(true);
        setLevelSatisfied(null);
        setFeedbackText('');
      })
      .catch(() => {})
      .finally(() => {
        setIsPostLoading(false);
      });
  }

  useEffect(() => {
    setIsSubmitted(false);
  }, [searchQuery]);

  useEffect(() => {
    if (levelSatisfied === 'satisfied') {
      onPost();
    }
  }, [levelSatisfied]);

  function onLevelSatisfied(levelSatisfied: LevelSatisfied) {
    if (!isPostLoading) {
      setLevelSatisfied(levelSatisfied);
    }
  }

  function iconSize(level: LevelSatisfied): string {
    return !levelSatisfied || levelSatisfied === level ? 'h-12 w-12' : 'h-10 w-10';
  }

  return (
    <div
      className="p-6 shadow rounded bg-gray-200 flex flex-col items-center px-4 py-8"
      style={{
        width: 400,
        minHeight: 220,
      }}
    >
      {!isSubmitted ? (
        <>
          <div className="font-bold text-xl text-primary-10 truncate card-title">
            Please give us a feedback!
          </div>
          <p className="mt-6 text-gray-800">
            Are you satisfied with the result of your search ?
          </p>
          <div className="flex mt-10 items-center">
            {isPostLoading && levelSatisfied === 'satisfied' ? (
              <Loader isLoading dark />
            ) : (
              <>
                <SmileFace
                  onClick={() => onLevelSatisfied('satisfied')}
                  className={`fill-current mr-4 cursor-pointer ${iconSize('satisfied')}`}
                  style={{ color: '#44C200' }}
                />
                <NeutralFace
                  onClick={() => onLevelSatisfied('neutral')}
                  className={`fill-current mr-4 cursor-pointer ${iconSize('neutral')}`}
                  style={{ color: '#DFE900' }}
                />
                <SadFace
                  onClick={() => onLevelSatisfied('unsatisfied')}
                  className={`fill-current cursor-pointer ${iconSize('unsatisfied')}`}
                  style={{ color: '#FBAD00' }}
                />
              </>
            )}
          </div>
          {levelSatisfied && levelSatisfied !== 'satisfied' && (
            <>
              <div className="mt-5 w-full">
                <TextArea
                  id={'comment'}
                  placeholder={'Please explain why'}
                  value={feedbackText}
                  onChange={onInputChange}
                  maxLength={255}
                  required={true}
                />
              </div>
              <div className="mt-5 flex justify-end">
                <Button
                  onClick={onPost}
                  disabled={!isSubmittable}
                  isLoading={isPostLoading}
                >
                  Send
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="h-full flex flex-col items-center justify-center">
          <ThumbsUp className="fill-current h-16 text-primary-10" />
          <div className="text-2xl text-primary-10 font-bold mt-4">
            Thank you for your feedback!
          </div>
        </div>
      )}
    </div>
  );
};

export default connect('doSendFeedback', 'selectUser', Feedback);
