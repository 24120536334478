/**
 * --------------------------------------------------------------------------
 * NJ: autocomplete.ts
 * --------------------------------------------------------------------------
 */
import { Core, EventName } from '../../globals/ts/enum';
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';

interface AutocompleteInputOptions {
  /** Maximum number of suggestions to display in list. */
  limit?: number;

  /**
   * Hint text when no suggestions matches the input value.
   *
   * @example "No results"
   */
  noResultMessage: string;
  /**
   * Hint text when only one suggestion matches the input value.
   *
   * @example "{x} result"
   */
  singularResultsMessage: string;

  /**
   * Hint text when multiple suggestions match the input value.
   *
   * @example "{x} results"
   */
  pluralResultsMessage: string;

  /** Whether to show the list item with the results count. */
  showResultNumber: boolean;
}

export default class AutocompleteInput extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-form-item--autocomplete`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.autocomplete`;
  static readonly SELECTOR = {
    default: `.${AutocompleteInput.NAME}`,
    input: `.${Core.KEY_PREFIX}-form-item__field`,
    list: `.${Core.KEY_PREFIX}-form-item__list`
  };

  private static readonly EVENT = {
    open: `${EventName.show}${AutocompleteInput.EVENT_KEY}`,
    onchange: `${EventName.onchange}${AutocompleteInput.EVENT_KEY}`,
    close: `${EventName.hide}${AutocompleteInput.EVENT_KEY}`
  };

  private static readonly CLASS_NAME = {
    isOpen: `${Core.KEY_PREFIX}-form-item--open`,
    hint: `${Core.KEY_PREFIX}-form-item__list-item-hint`,
    active: `active`
  };

  private static readonly HIGHLIGHT_OPENING_TAG = '<mark class="nj-highlight">';
  private static readonly HIGHLIGHT_CLOSING_TAG = '</mark>';

  private dataList: Array<{ name: string; value: string }>;

  public options: AutocompleteInputOptions = {
    noResultMessage: 'No results',
    singularResultsMessage: '{x} result',
    pluralResultsMessage: '{x} results',
    showResultNumber: false
  };

  /**
   * Element whose content will be announced by assistive technologies. Should
   * contain the number of matching suggestions.
   */
  private liveZone: HTMLElement;

  /** Input element */
  private fieldEl: HTMLInputElement;
  private listEl: HTMLElement;
  /** Base template element used to create item elements. */
  private itemTemplateEl: HTMLElement;
  /** Suggestion elements. Only the ones matching with the input value will be displayed. */
  private itemElements: HTMLElement[];
  /**  Currently displayed elements, after filtering. */
  private currentItemElements: HTMLElement[] = [];
  /** Element showing the number of suggestions matching with the input value. */
  private hintEl: HTMLElement;

  /** Should the list be filtered based on the input field value. */
  private isFiltered = false;

  constructor(element: HTMLElement) {
    super(AutocompleteInput, element);

    this.dataList = JSON.parse(this.element.dataset.list);

    if (this.element.dataset.options) {
      this.options = {
        ...this.options,
        ...JSON.parse(this.element.dataset.options)
      };
    }

    this.listEl = this.element.querySelector(AutocompleteInput.SELECTOR.list);
    this.fieldEl = this.element.querySelector(AutocompleteInput.SELECTOR.input);
    this.itemTemplateEl = this.listEl.firstElementChild as HTMLElement;
    this.itemElements = this.createItemElements();
    this.hintEl = this.createHintElement();

    // Insert hidden live zone
    this.liveZone = AutocompleteInput.createLiveResultElement();
    this.element.prepend(this.liveZone);

    EventHandler.on(element, 'keydown', this.onKeydown.bind(this));
    EventHandler.on(element, 'focusout', this.onFocusOut.bind(this));

    EventHandler.on(this.fieldEl, 'click', () => {
      this.isFiltered = false;
      this.isOpen = !this.isOpen;
    });

    EventHandler.on(this.fieldEl, 'input', () => {
      this.updateList();
    });

    Data.setData(element, AutocompleteInput.DATA_KEY, this);
  }

  private get isOpen(): boolean {
    return !this.listEl.hasAttribute('hidden');
  }

  private set isOpen(value: boolean) {
    if (value) {
      this.element.classList.add(AutocompleteInput.CLASS_NAME.isOpen);
      this.listEl.removeAttribute('hidden');
      this.fieldEl.setAttribute('aria-expanded', 'true');
      this.updateList();
      this.setActiveOption();
      EventHandler.trigger(this.element, AutocompleteInput.EVENT.open);
    } else {
      this.element.classList.remove(AutocompleteInput.CLASS_NAME.isOpen);
      this.listEl.setAttribute('hidden', 'hidden');
      this.fieldEl.setAttribute('aria-expanded', 'false');
      this.fieldEl.removeAttribute('aria-activedescendant');
      this.unselectOption();
      EventHandler.trigger(this.element, AutocompleteInput.EVENT.close);
    }
  }

  /** Reset the "selected" option to either the current field value or the first option. */
  private selectMatchingOption() {
    const selectedId = this.matchingId ?? this.currentItemElements[0]?.id;
    this.currentItemElements.forEach((el) => {
      el.setAttribute('aria-selected', el.id === selectedId ? 'true' : 'false');
      if (el.id === selectedId) {
        this.fieldEl.setAttribute('aria-activedescendant', el.id);
      }
    });
  }

  /** Returns only the suggestion elements matching the current input value. */
  private getFilteredItemElements() {
    return this.itemElements
      .filter((el) => !this.isFiltered || AutocompleteInput.compareText(el.dataset.name, this.fieldEl.value))
      .slice(0, this.options.limit);
  }

  /** Update the suggestion list based on the current input value. */
  private updateList() {
    this.currentItemElements = this.getFilteredItemElements();
    if (this.currentItemElements.length) {
      this.currentItemElements.forEach((el) => {
        el.innerHTML = AutocompleteInput.getElementInnerHtml(
          el.dataset.name,
          this.isFiltered ? this.fieldEl.value : ''
        );
      });
      if (this.options.showResultNumber && this.isFiltered) {
        this.hintEl.innerHTML = this.createResultsMessageContent();
        this.listEl.replaceChildren(this.hintEl, ...this.currentItemElements);
      } else {
        this.listEl.replaceChildren(...this.currentItemElements);
      }
      this.liveZone.innerHTML = this.createResultsMessageContent(true);
    } else {
      this.hintEl.innerHTML = this.createResultsMessageContent();
      this.listEl.replaceChildren(this.hintEl);
      this.liveZone.innerHTML = this.createResultsMessageContent(true);
    }
  }

  /**
   * Process the next suggestion index in the list. If the current selection is the
   * last suggestion, the first suggestion in the list is selected.
   */
  private getNextOptionIndex(currentIndex: number, optionListSize: number): number {
    return currentIndex >= optionListSize - 1 ? 0 : currentIndex + 1;
  }

  /**
   * Process the previous suggestion index in the list. If the current selection is the
   * first suggestion, the last suggestion in the list is selected.
   */
  private getPreviousOptionIndex(currentIndex: number, optionListSize: number): number {
    return currentIndex <= 0 ? optionListSize - 1 : currentIndex - 1;
  }

  /**
   * Select the suggestion at given index in the list.
   */
  private selectOption(indexCallback: (currentIndex: number, optionListSize: number) => number) {
    const elements = this.currentItemElements;
    if (elements.length) {
      const activeElementId = this.matchingId;
      let activeIndex = -1;
      const currentIndex = elements.findIndex((el, index) => {
        // Get the active element in this findIndex for performance reason.
        // Instead of making another findIndex if currentIndex = -1
        if (el.id === activeElementId) {
          activeIndex = index;
        }
        return el.getAttribute('aria-selected') === 'true';
      });
      const index = indexCallback(currentIndex >= 0 ? currentIndex : activeIndex, elements.length);

      elements.forEach((el, i) => {
        el.setAttribute('aria-selected', i === index ? 'true' : 'false');
        if (i === index) {
          el.scrollIntoView({ block: 'nearest' });
          this.fieldEl.setAttribute('aria-activedescendant', el.id);
        }
      });
    }
  }

  /**
   * Get the id of the option element matching the input field value
   */
  private get matchingId(): string | null {
    return this.itemElements.find((item) => item.dataset.name === this.fieldEl.value)?.id ?? null;
  }

  private setActiveOption() {
    this.itemElements.forEach((item) => {
      if (this.matchingId === item.id) {
        item.classList.add(AutocompleteInput.CLASS_NAME.active);
        item.scrollIntoView({ block: 'nearest' });
      } else {
        item.classList.remove(AutocompleteInput.CLASS_NAME.active);
      }
    });
  }

  private unselectOption() {
    this.currentItemElements.forEach((el) => el.setAttribute('aria-selected', 'false'));
  }

  private onKeydown(e: KeyboardEvent) {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!this.isOpen) {
          this.isFiltered = false;
          this.updateList();
          this.isOpen = true;
          this.selectMatchingOption();
        } else {
          this.selectOption(this.getNextOptionIndex);
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (this.isOpen) {
          this.selectOption(this.getPreviousOptionIndex);
        }
        break;
      case 'Escape':
        e.preventDefault();
        if (this.isOpen) {
          this.isOpen = false;
          this.fieldEl.focus();
        }
        break;
      case 'Enter':
        this.selectCurrentSuggestion();
        this.isOpen = false;
        break;
      default:
        // Ignore non-character keys and shortcut combinations
        const keyIsPrintable = (e.key === 'Backspace' || e.key.length === 1) && !e.metaKey && !e.altKey && !e.ctrlKey;

        if (keyIsPrintable) {
          this.isFiltered = true;

          this.unselectOption();

          // Wait for input event to be handled
          setTimeout(() => {
            this.setActiveOption();
          });

          if (!this.isOpen) {
            this.isOpen = true;
          }
        }
    }
  }

  private selectCurrentSuggestion() {
    const selectedId = this.fieldEl.getAttribute('aria-activedescendant');
    if (!selectedId) {
      return;
    }
    const selectedListElement = this.currentItemElements.find((el) => el.id === selectedId);
    const name = selectedListElement.dataset.name;
    const value = selectedListElement.dataset.value;
    this.fieldEl.value = name;

    // Emit a change event with the selected value
    EventHandler.trigger(this.element, AutocompleteInput.EVENT.onchange, { name, value });
  }

  /** Closes the suggestion list if the focus is moved outside of the autocomplete. */
  private onFocusOut(e: FocusEvent) {
    if (!this.element.contains(e.relatedTarget as Node)) {
      this.isOpen = false;
    }
  }

  /** Create a "live zone" to announce stuff with assistive technologies. */
  private static createLiveResultElement(): HTMLElement {
    const el = document.createElement('div');
    el.setAttribute('aria-live', 'polite');
    el.setAttribute('aria-atomic', 'true');
    el.classList.add('nj-sr-only');
    return el;
  }

  /** Create suggestion items elements based on the template element. */
  private createItemElements(): HTMLElement[] {
    return this.dataList.map((item, i) => {
      const el = this.itemTemplateEl.cloneNode() as HTMLElement;
      el.innerHTML = item.name;

      el.dataset.name = item.name;
      el.dataset.value = item.value;
      el.setAttribute('id', `${this.fieldEl.getAttribute('id')}-option-${i}`);

      EventHandler.on(el, 'click', () => {
        this.isOpen = false;
        this.fieldEl.value = item.name;

        // Emit a change event with the selected value
        EventHandler.trigger(this.element, AutocompleteInput.EVENT.onchange, {
          name: item.name,
          value: item.value
        });
      });

      return el;
    });
  }

  private createHintElement() {
    const el = this.itemTemplateEl.cloneNode() as HTMLElement;
    el.className = AutocompleteInput.CLASS_NAME.hint;
    el.innerHTML = this.options.noResultMessage;
    el.setAttribute('aria-hidden', 'true');
    return el;
  }

  /** Content of hint item and hidden. */
  private createResultsMessageContent(isParagraph = false) {
    const elements = this.currentItemElements;
    let result: string;

    if (elements.length === 0) {
      result = this.options.noResultMessage;
    } else {
      result = `${
        elements.length === 1
          ? this.options.singularResultsMessage.replace('{x}', elements.length.toString())
          : this.options.pluralResultsMessage.replace('{x}', elements.length.toString())
      }`;
    }

    return isParagraph ? `<p>${result}</p>` : result;
  }

  static init(options = {}): AutocompleteInput[] {
    return super.init(this, options, AutocompleteInput.SELECTOR.default) as AutocompleteInput[];
  }

  public dispose(): void {
    Data.removeData(this.element, AutocompleteInput.DATA_KEY);
  }

  /**
   * Returns a string with matching occurences wrapped with an highlight element.
   */
  private static getElementInnerHtml(text: string, search: string): string {
    const regexFlags = 'gi';
    if (typeof search === 'undefined' || search?.trim() === '') {
      return text;
    }
    const regExp = new RegExp(AutocompleteInput.normalizeString(search), regexFlags);
    const matches = AutocompleteInput.normalizeString(text).matchAll(regExp);
    let finalText = text;
    let buffer = 0;
    if (matches) {
      for (const match of matches) {
        const updatedIndex = buffer + match.index;
        const textBeforeOccurrence = finalText.slice(0, updatedIndex);
        const occurrence = finalText.slice(updatedIndex, updatedIndex + search.length);
        const textAfterOccurrence = finalText.slice(updatedIndex + search.length, finalText.length);
        finalText = `${textBeforeOccurrence}${this.HIGHLIGHT_OPENING_TAG}${occurrence}${this.HIGHLIGHT_CLOSING_TAG}${textAfterOccurrence}`;
        buffer =
          buffer + AutocompleteInput.HIGHLIGHT_OPENING_TAG.length + AutocompleteInput.HIGHLIGHT_CLOSING_TAG.length;
      }
    }
    return `<span>${finalText}</span>`;
  }

  /** Check if a suggestion text matches the search value. */
  private static compareText(text: string, search: string): boolean {
    text = AutocompleteInput.normalizeString(text);
    search = AutocompleteInput.normalizeString(search);
    search = search.replace(/\(|\)|\\/gi, '');
    const reg = new RegExp(search, 'gi');
    return text.search(reg) !== -1;
  }

  private static normalizeString(text: string) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  static getInstance(element: HTMLElement): AutocompleteInput {
    return Data.getData(element, AutocompleteInput.DATA_KEY) as AutocompleteInput;
  }

  setOptions(options: Array<{ name: string; value: string }>) {
    this.dataList = options;
    this.itemElements = this.createItemElements();
    this.updateList();
    this.selectMatchingOption();
  }
}
