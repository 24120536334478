export interface PaginationType {
  currentPage: number;
  totalPages: number;
  totalResults: number;
}

export enum SortTypes {
  DATE = 'date',
  FILLING = 'filling',
  BOOKMARK = 'bookmark',
}

export interface PaginatedResponse {
  data: any[];
  pagination: PaginationType;
}

export const emptyPaginateResponse = {
  data: [],
  pagination: { totalResults: 0, totalPages: 1, currentPage: 1 },
};
