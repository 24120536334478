import { ContentTypes } from '../types/inspirations';

export const initialState = {
  projectInspirationRefreshCounter: 0,
  initiativeInspirationRefreshCounter: 0,
  postInspirationRefreshCounter: 0,
};
const refreshCounter = {
  name: 'inspirations',
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        case 'INCREMENT_PROJECT_REFRESH_COUNTER':
          return {
            ...state,
            projectInspirationRefreshCounter: state.projectInspirationRefreshCounter + 1,
          };
        case 'INCREMENT_INITIATIVE_REFRESH_COUNTER':
          return {
            ...state,
            initiativeInspirationRefreshCounter:
              state.initiativeInspirationRefreshCounter + 1,
          };
        case 'INCREMENT_POST_REFRESH_COUNTER':
          return {
            ...state,
            postInspirationRefreshCounter: state.postInspirationRefreshCounter + 1,
          };
        default:
          return state;
      }
    };
  },
  doUpdateRefreshCounter:
    ({ type }) =>
    ({ dispatch }) => {
      switch (type) {
        case ContentTypes.POST:
          dispatch({
            type: 'INCREMENT_POST_REFRESH_COUNTER',
          });
          break;
        case ContentTypes.PROJECT:
          dispatch({
            type: 'INCREMENT_PROJECT_REFRESH_COUNTER',
          });
          break;
        case ContentTypes.INITIATIVE:
          dispatch({
            type: 'INCREMENT_INITIATIVE_REFRESH_COUNTER',
          });
          break;
      }
    },
  selectProjectRefreshCounter: (state) =>
    state.inspirations.projectInspirationRefreshCounter,
  selectInitiativeRefreshCounter: (state) =>
    state.inspirations.initiativeInspirationRefreshCounter,
  selectPostRefreshCounter: (state) => state.inspirations.postInspirationRefreshCounter,
};
export default refreshCounter;
