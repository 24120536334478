import { NotificationsConfigType } from '../../types/notifications';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';

export const getNotificationsConfig = (): NotificationsConfigType[] => [
  {
    label: i18n._(t`Digest sign up`),
    name: 'newUC',
  },
  {
    label: i18n._(t`Use case comment`),
    name: 'newCommentOnUC',
  },
  {
    label: i18n._(t`Comment on bookmarked use case`),
    name: 'newCommentOnFollowedUC',
  },
];
