import React, { useEffect, useState } from 'react';

function useWindowLocation(
  defaultState: string,
): [string, React.Dispatch<React.SetStateAction<string>>] {
  const [windowLocation, setWindowLocation] = useState(defaultState);

  useEffect(() => {
    const path = window.location.pathname;

    setWindowLocation(path);
  }, [window.location.pathname]);

  return [windowLocation, setWindowLocation];
}

export default useWindowLocation;
