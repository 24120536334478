import { Err, Ok, boolean, object, string } from 'idonttrustlikethat';

const url = string.and((str) => {
  try {
    new URL(str);

    return Ok(str);
  } catch (err) {
    return Err(`${str} is not a URL`);
  }
});

const baseValidator = object({
  isProd: boolean.optional().default(false),
  env: string, // environment name (e.g. TST, STG,, PRE-PRD, PRD)
  backendUrl: url,
  frontendUrl: string,
  oktaClientId: string,
  oktaIssuerUrl: string,
  oktaRedirectUri: string,
  //token: string
});

export const envValidator = baseValidator;

export type AppEnv = typeof envValidator.T;
