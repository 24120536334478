import { TagType } from '../../types/tag';
import { SelectedTag } from '../../components/TagsSearch/SelectedTag/SelectedTag';
import { TagsSearch } from '../../components/TagsSearch/TagsSearch';
import { Fragment, FunctionComponent } from 'react';

interface TagsFormPropTypes {
  selectedTags: TagType[];
  tags: TagType[];
  setSelectedTags: React.Dispatch<React.SetStateAction<TagType[]>>;
  placeholder: any;
  label: any;
  inputId?: string;
  onlyOne?: boolean;
}

export const ProfileTagsForm: FunctionComponent<TagsFormPropTypes> = ({
  selectedTags,
  setSelectedTags,
  tags,
  placeholder,
  label,
  inputId,
  onlyOne,
}) => {
  function onTagSelect(selectedTag: TagType) {
    if (onlyOne && selectedTags.length > 0) {
      return;
    }
    setSelectedTags([...selectedTags, selectedTag]);
  }

  function removeSelectedTag(selectedTag: TagType) {
    const newTeamList = selectedTags.filter(
      (selectedTags) => selectedTags.id !== selectedTag.id,
    );
    setSelectedTags(newTeamList);
  }

  return (
    <>
      {label && <p className="default-input">{label}</p>}
      <div className="mt-2 flex flex-wrap">
        {selectedTags.map((tag) => (
          <div key={tag.id} className="mr-2 mb-2">
            <SelectedTag tag={tag} onTagClick={removeSelectedTag} />
          </div>
        ))}
      </div>
      <TagsSearch
        tags={tags}
        onTagClick={onTagSelect}
        placeholder={placeholder}
        inputId={inputId}
      />
    </>
  );
};
