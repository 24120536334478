import './yammer-widget.scss';
import { FunctionComponent } from 'react';

interface YammerWidgetProps {
  yammer_widget_src: string;
  yammer_title: string;
  style_extension?: any;
}

const YammerWidget: FunctionComponent<YammerWidgetProps> = ({
  yammer_widget_src,
  yammer_title,
  style_extension,
}) => {
  if (
    yammer_widget_src &&
    (yammer_widget_src.includes('https://engage.cloud.microsoft/embed') ||
    yammer_widget_src.includes('https://web.yammer.com/embed'))
  ) {
    return (
      <>
        <h1 className="ago-principal-title-bold ago-yammer-title">{yammer_title}</h1>
        <iframe
          name="embed-feed"
          title="Viva Engage"
          src={yammer_widget_src}
          className="ago-yammer-widget"
          style={style_extension}
        />
      </>
    );
  }
  return <></>;
};

export default YammerWidget;
