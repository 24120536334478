/**
 * --------------------------------------------------------------------------
 * NJ: accordion.ts
 * --------------------------------------------------------------------------
 */
import AbstractComponent from '../../globals/ts/abstract-component';
import { Core } from '../../globals/ts/enum';
import Data from '../../globals/ts/data';
import EventHandler from '../../globals/ts/event-handler';

export default class Accordion extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-accordion`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.accordion`;
  protected static readonly EVENT_KEY = `.${Accordion.DATA_KEY}`;
  protected static readonly ACTIONS = {
    expand: `accordion-expand`,
    collapse: `accordion-collapse`
  };
  protected static readonly SELECTOR = {
    default: `.${Accordion.NAME}`,
    details: `details.${Accordion.NAME}-item`,
    expandAllBtn: `.${Accordion.NAME}__action[data-${Accordion.ACTIONS.expand}]`,
    collapseAllBtn: `.${Accordion.NAME}__action[data-${Accordion.ACTIONS.collapse}]`
  };

  private readonly root: HTMLElement;
  private readonly details: NodeListOf<HTMLDetailsElement>;
  private readonly expandAllBtn: HTMLButtonElement;
  private readonly collapseAllBtn: HTMLButtonElement;

  constructor(element: HTMLElement) {
    super(Accordion, element);
    Data.setData(element, Accordion.DATA_KEY, this);

    this.root = element;
    this.details = this.root.querySelectorAll(Accordion.SELECTOR.details);
    this.expandAllBtn = this.root.querySelector(Accordion.SELECTOR.expandAllBtn);
    this.collapseAllBtn = this.root.querySelector(Accordion.SELECTOR.collapseAllBtn);
    this.setListeners();
  }

  static init(options = {}): Accordion[] {
    return super.init(this, options, Accordion.SELECTOR.default) as Accordion[];
  }

  dispose(): void {
    Data.removeData(this.element, Accordion.DATA_KEY);

    for (let i = 0, len = this.details.length; i < len; i++) {
      EventHandler.off(this.details[i], 'toggle');
    }
    EventHandler.off(this.expandAllBtn, 'click');
    EventHandler.off(this.expandAllBtn, 'click');
    this.element = null;
  }

  static getInstance(element: HTMLElement): Accordion {
    return Data.getData(element, Accordion.DATA_KEY) as Accordion;
  }

  private setListeners(): void {
    for (let i = 0, len = this.details.length; i < len; i++) {
      EventHandler.on(this.details[i], 'toggle', this.toggleExclusiveAccordion(this.details[i]));
    }

    EventHandler.on(this.expandAllBtn, 'click', this.clickOnAction(Accordion.ACTIONS.expand));
    EventHandler.on(this.collapseAllBtn, 'click', this.clickOnAction(Accordion.ACTIONS.collapse));
  }

  private toggleExclusiveAccordion(item: HTMLDetailsElement) {
    return (e) => {
      const name = item.getAttribute('name');

      if (e.newState === 'open') {
        this.root.querySelectorAll(`${Accordion.SELECTOR.details}[name=${name}][open]`).forEach((details) => {
          if (!(details === item)) {
            details.removeAttribute('open');
          }
        });
      }
    };
  }

  private clickOnAction(action: string) {
    return () => {
      this.details.forEach((details) => {
        if (action === Accordion.ACTIONS.expand) {
          details.setAttribute('open', '');
        } else {
          details.removeAttribute('open');
        }
      });
    };
  }
}
