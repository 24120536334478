import { array, number, object, intersection } from 'idonttrustlikethat';
import { strapiCommonDates } from '../common/common-types';
import { applicationNews } from '../component/home/application_news';
import { homeBlocks } from '../component/blocks/home-block';

const agoraHome = object({
  data: object({
    id: number,
    attributes: intersection(
      strapiCommonDates,
      object({
        information_banner: applicationNews,
        content: array(homeBlocks),
      }),
    ),
  }),
});

type AgoraHome = typeof agoraHome.T;

export { agoraHome };
export type { AgoraHome };
