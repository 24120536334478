import { useMemo } from 'react';
import { truncateText, toTitleCase } from '../../../../../../utils/text';

const useUseCaseCard = (useCaseDetails: any) => {
  const shortDescription = useMemo(
    () =>
      truncateText(
        useCaseDetails.shortDescription ?? 'no short description available',
        200,
      ),
    [useCaseDetails.shortDescription],
  );

  const firstName = useMemo(
    () => toTitleCase(useCaseDetails.user.firstName ?? 'no short first name available'),
    [useCaseDetails.user.firstName],
  );

  const lastName = useMemo(
    () => toTitleCase(useCaseDetails.user.lastName ?? 'no short last name available'),
    [useCaseDetails.user.lastName],
  );

  return {
    shortDescription,
    firstName,
    lastName,
  };
};

export { useUseCaseCard };
