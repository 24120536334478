export const initialState = {};
const metrics = {
  name: 'metrics',
  getReducer: () => {
    return (state = initialState, { type, payload = {} }) => {
      switch (type) {
        default:
          return state;
      }
    };
  },
  doFetchMetrics:
    () =>
    ({ apiFetch }) => {
      return apiFetch('kpi/general');
    },
};
export default metrics;
