import { FunctionComponent, Fragment } from 'react';
import { Trans } from '@lingui/react';

interface PropTypes {
  maxLength?: number;
  value?: string;
}

export const CharacterController: FunctionComponent<PropTypes> = ({
  maxLength,
  value,
}) => {
  if (!maxLength || !value) return null;
  const charactersRemaining = maxLength - value.length;

  return (
    <span className="mb-2 text-warning-6 font-normal">
      &nbsp;(
      {charactersRemaining > 0 ? (
        <Fragment>
          {charactersRemaining}&nbsp;
          <Trans id="character(s) remaining" />
        </Fragment>
      ) : (
        <Trans id="Maximum characters reached" />
      )}
      )
    </span>
  );
};
