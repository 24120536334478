import { literal, number, object, string } from 'idonttrustlikethat';
import { videoLinks } from '../videos/video';

const communityComponent = literal('blocks.video');
const communityBlock = object({
  id: number,
  __component: communityComponent,
  title: string,
  yammer_title: string,
  yammer_link: string,
  video_links: videoLinks,
});

type CommunityBlock = typeof communityBlock.T;
type CommunityComponent = typeof communityComponent.T;

export { communityBlock, communityComponent };
export type { CommunityBlock, CommunityComponent };
