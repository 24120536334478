import './information-banner.scss';
import { FC, useEffect } from 'react';
import { Banner } from '../../atoms/banner/banner';
import { ApplicationNews } from '../../../../types/strapi/component/home/application_news';

interface InformationBannerProps {
  informationBanner: ApplicationNews;
}

const InformationBanner: FC<InformationBannerProps> = ({ informationBanner }) => {
  const oneOrMoreIsActive = informationBanner.find((info) => info.is_active === true);

  useEffect(() => {
    const scrollers = document.querySelectorAll<HTMLElement>('.scroller');

    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute('data-animated', 'true');

        const scrollerInner = scroller.querySelector<HTMLElement>('.scroller__inner');
        if (!scrollerInner) return;
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true) as HTMLElement;
          duplicatedItem.setAttribute('aria-hidden', 'true');
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return oneOrMoreIsActive ? (
    <div
      style={{
        backgroundColor:
          informationBanner[0].information_banner.data.attributes.background_color,
      }}
      className="scroller"
      data-direction="left"
      data-speed="fast"
    >
      <ul className="scroller__inner">
        {informationBanner.map((information) => {
          return information.is_active ? (
            <Banner
              key={information.id}
              infoBanner={information.information_banner.data.attributes}
            ></Banner>
          ) : (
            <></>
          );
        })}
      </ul>
    </div>
  ) : (
    <></>
  );
};

export { InformationBanner };
