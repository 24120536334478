import { httpGet } from '../http/http';

const apiFetcher = async <T>(
  route: string,
  setState: Function | null = null,
): Promise<T> => {
  const url = `${process.env.REACT_APP_API_URL}/${route}`;
  return httpGet(url, setState);
};

export { apiFetcher };
