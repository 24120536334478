import React, { useEffect, useState } from 'react';

function useShowBurgerMenuContent(
  defaultState: boolean,
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [showBurgerMenuContent, setShowBurgerMenuContent] = useState(defaultState);

  const windowResizeHandler = () => {
    const windowWidth = window.innerWidth;

    if (showBurgerMenuContent && windowWidth >= 1200) {
      setShowBurgerMenuContent(false);
    }
  };

  useEffect(() => {
    const root = document.getElementById('root');

    if (root && showBurgerMenuContent) {
      root.style.overflow = 'hidden';
      document.body.style.overscrollBehavior = 'none';
      window.addEventListener('resize', windowResizeHandler);
    } else if (root) {
      root.style.overflow = 'visible';
      document.body.style.overscrollBehavior = 'auto';
    }

    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
  }, [showBurgerMenuContent]);

  return [showBurgerMenuContent, setShowBurgerMenuContent];
}

export default useShowBurgerMenuContent;
