import { useState, useRef, useEffect } from 'react';
import debounce from 'lodash/debounce';

export function useDebounceValue(value: string, delay: number = 500) {
  const [debouncedValue, setDebouncedValue] = useState<string>(value);
  const debounceRef = useRef(debounce(setDebouncedValue, delay));
  useEffect(() => {
    debounceRef.current(value);
    return debounceRef.current.cancel;
  }, [value]);

  return debouncedValue;
}
