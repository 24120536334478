import { literal, number, object } from 'idonttrustlikethat';

const indicatorsAndTagsComponent = literal('blocks.indicators-and-tags');
const indicatorsAndTagsBlock = object({
  id: number,
  __component: indicatorsAndTagsComponent,
});

type IndicatorsAndTagsBlock = typeof indicatorsAndTagsBlock.T;
type IndicatorsAndTagsComponent = typeof indicatorsAndTagsComponent.T;

export { indicatorsAndTagsBlock, indicatorsAndTagsComponent };
export type { IndicatorsAndTagsBlock, IndicatorsAndTagsComponent };
