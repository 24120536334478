import './banner.scss';
import { FC } from 'react';
import { InformationBannerAttributes } from '../../../../types/strapi/information-banner/information-banner';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

interface PropsBanner {
  infoBanner: InformationBannerAttributes;
}

const Banner: FC<PropsBanner> = ({ infoBanner }) => {
  const { background_color, content, text_color } = infoBanner;
  return (
    <li
      className="ago-banner-content ago-subtitle-regular"
      style={{
        color: text_color,
        backgroundColor: background_color,
      }}
    >
      <ReactMarkdown children={content} rehypePlugins={[rehypeRaw]}></ReactMarkdown>
    </li>
  );
};

export { Banner };
