import { FC } from 'react';
import './home-tags-summary.scss';
import { HomeTagData } from '../../../../../types/strapi/component/blocks/api-indicator-data/indicators-and-tiles-data';
import { TagLink } from '../../../../../pages/Project/components/TagLink';

interface HomeTagsSummaryProps {
  title: string;
  tags: HomeTagData[];
}

const HomeTagsSummary: FC<HomeTagsSummaryProps> = ({ title, tags }) => {
  const getTagInfo = (tag: HomeTagData): string => {
    return tag.tag.label + ' (' + tag.total + ')';
  };
  return (
    <div className="hts-layout">
      <h3 className="hts-title ago-principal-title-bold">{title}</h3>
      <div>
        {tags.slice(0, 10).map((tag) => (
          <TagLink
            key={tag.tag.id + 'tagSummary'}
            id={tag.tag.id}
            category={tag.tag.category}
            className="hts-tag"
            closeAriaLabel={tag.tag.id + 'tagSummary'}
            text={getTagInfo(tag)}
            size="sm"
            variant="green"
          />
        ))}
      </div>
    </div>
  );
};

export { HomeTagsSummary };
