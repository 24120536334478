import { FunctionComponent, Fragment, ChangeEvent } from 'react';
import { CharacterController } from '../CharacterController/CharacterController';

import Disclaimer from '../Disclaimer/Disclaimer';

interface PropTypes {
  placeholder: string;
  onChange?: (ev: ChangeEvent<any>) => void;
  value: string;
  label?: string;
  required?: boolean;
  maxLength?: number;
  id: string;
  name?: string;
  showDisclaimer?: boolean;
}

export const TextArea: FunctionComponent<PropTypes> = ({
  placeholder,
  onChange,
  value,
  label,
  required,
  maxLength,
  id,
  name,
  showDisclaimer,
}) => (
  <Fragment>
    {label && (
      <label htmlFor={id} className="default-input">
        {label}
        {showDisclaimer && <Disclaimer />}
        <CharacterController maxLength={maxLength} value={value} />
      </label>
    )}

    <textarea
      className="shadow appearance-none border border-neutral-3 rounded w-full py-2 px-3 text-neutral-5 leading-tight focus:outline-none focus:shadow-outline resize-none h-32"
      id={id}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      required={required}
      maxLength={maxLength}
    />
  </Fragment>
);

export default TextArea;
