import { literal, number, object, string } from 'idonttrustlikethat';
import { eventLinks } from '../events/event_link';
import { tilesResponse } from '../../tile/tile';

const eventAndTilesComponent = literal('blocks.event-and-tiles');
const eventAndTilesBlock = object({
  id: number,
  __component: eventAndTilesComponent,
  event: eventLinks,
  event_title: string,
  tiles: tilesResponse,
});

type EventAndTilesBlock = typeof eventAndTilesBlock.T;
type EventAndTilesComponent = typeof eventAndTilesComponent.T;

export { eventAndTilesBlock, eventAndTilesComponent };
export type { EventAndTilesBlock, EventAndTilesComponent };
