/**
 * --------------------------------------------------------------------------
 * NJ: dropdown.ts
 * --------------------------------------------------------------------------
 */
import AbstractComponent from '../../globals/ts/abstract-component';
import Data from '../../globals/ts/data';
import { Core, EventName } from '../../globals/ts/enum';
import EventHandler from '../../globals/ts/event-handler';

export default class SelectInput extends AbstractComponent {
  static readonly NAME = `${Core.KEY_PREFIX}-form-item--custom-list`;
  protected static readonly DATA_KEY = `${Core.KEY_PREFIX}.custom-list`;
  protected static readonly EVENT_KEY = `.${SelectInput.DATA_KEY}`;

  private static readonly ESCAPE_KEYCODE = 27;
  private static readonly ENTER_KEYCODE = 13;
  private static readonly UP_KEYCODE = 38;
  private static readonly DOWN_KEYCODE = 40;

  private static readonly CLASS_NAME = {
    isOpen: `${Core.KEY_PREFIX}-form-item--open`
  };

  private static readonly ATTRIBUTE = {
    value: 'data-value'
  };

  protected static readonly SELECTOR = {
    default: `.${SelectInput.NAME}`,
    label: `.${Core.KEY_PREFIX}-form-item__label`,
    button: `.${Core.KEY_PREFIX}-form-item__custom-list-button`,
    input: `.${Core.KEY_PREFIX}-form-item__field`,
    options: `.${Core.KEY_PREFIX}-form-item__list`,
    option: `.${Core.KEY_PREFIX}-list-deprecated__item`
  };

  private static readonly EVENT = {
    onchange: `${EventName.onchange}${SelectInput.EVENT_KEY}`,
    open: `${EventName.show}${SelectInput.EVENT_KEY}`,
    close: `${EventName.hide}${SelectInput.EVENT_KEY}`
  };

  static getInstance(element: HTMLElement): SelectInput {
    return Data.getData(element, SelectInput.DATA_KEY) as SelectInput;
  }

  static init(options = {}): SelectInput[] {
    return super.init(this, options, SelectInput.SELECTOR.default) as SelectInput[];
  }

  // elements
  private buttonEl: HTMLButtonElement;
  private labelEl: HTMLLabelElement;
  private listEl: HTMLUListElement;
  private inputEl: HTMLInputElement;
  private optionElements: HTMLLIElement[];

  constructor(element: HTMLElement) {
    super(SelectInput, element);

    Data.setData(element, SelectInput.DATA_KEY, this);

    this.buttonEl = this.element.querySelector(SelectInput.SELECTOR.button);
    this.labelEl = this.element.querySelector(SelectInput.SELECTOR.label);
    this.listEl = this.element.querySelector(SelectInput.SELECTOR.options);
    this.inputEl = this.element.querySelector(SelectInput.SELECTOR.input);
    this.optionElements = Array.from(this.element.querySelectorAll(SelectInput.SELECTOR.option));

    this.addToggleEvent();
    this.addEscapeEvent();
    this.addBlurEvent();
    this.addUpDownEvent();
    this.addClickEvent();
    this.addShortcutEvents();
  }

  /**
   * Update optionElements list and its listeners
   */
  updateOptionsAndListeners() {
    this.optionElements = Array.from(this.element.querySelectorAll(SelectInput.SELECTOR.option));
    this.addClickEvent();
  }

  get isOpen(): boolean {
    return !this.listEl.hasAttribute('hidden');
  }

  set isOpen(value: boolean) {
    if (value) {
      // Open the modal
      this.listEl.removeAttribute('hidden');
      this.buttonEl.setAttribute('aria-expanded', 'true');
      this.buttonEl.setAttribute('tabindex', '-1');
      this.element.classList.add(SelectInput.CLASS_NAME.isOpen);
      this.listEl.focus();
      this.focusedIndex = this.selectedIndex;
      if (this.focusedIndex === -1) {
        this.listEl.scrollTo({ top: 0 });
      }

      this.optionElements.forEach((el, i) => {
        const activeClassName = 'nj-list-deprecated__item--active';
        if (i === this.focusedIndex) {
          el.classList.add(activeClassName);
        } else {
          el.classList.remove(activeClassName);
        }
      });

      EventHandler.trigger(this.element, SelectInput.EVENT.open);
    } else {
      // Close the modal
      this.listEl.setAttribute('hidden', 'hidden');
      this.buttonEl.setAttribute('aria-expanded', 'false');
      this.buttonEl.removeAttribute('tabindex');
      this.element.classList.remove(SelectInput.CLASS_NAME.isOpen);
      this.focusedIndex = -1;
      EventHandler.trigger(this.element, SelectInput.EVENT.close);
    }
  }

  /**
   * Index of the option element corresponding to the current field value.
   */
  private get selectedIndex(): number {
    return this.optionElements.findIndex((el) => el.getAttribute('aria-selected') === 'true');
  }

  private set selectedIndex(value: number) {
    this.optionElements.forEach((el, i) => {
      el.setAttribute('aria-selected', String(i === value));
    });
    this.inputEl.value = this.selectedOptionLabel;
    this.buttonEl.setAttribute('aria-label', `${this.labelEl.textContent} - ${this.selectedOptionLabel}`);
    EventHandler.trigger(this.element, SelectInput.EVENT.onchange, {
      value: this.selectedOptionLabel,
      option: this.optionElements[value]
    });
  }

  /**
   * Index of the currently focused option.
   */
  private get focusedIndex(): number {
    return this.optionElements.findIndex((el) => document.activeElement === el);
  }

  private set focusedIndex(value: number) {
    if (value !== -1) {
      this.optionElements[value].focus();
    }
  }

  /** Current label of the field */
  private get selectedOptionLabel(): string {
    if (this.selectedIndex !== -1) {
      const el = this.optionElements[this.selectedIndex];
      return el.textContent;
    }
    return '';
  }

  /** Current value of the field */
  private get selectedOptionValue(): string {
    if (this.selectedIndex !== -1) {
      const el = this.optionElements[this.selectedIndex];
      return el.getAttribute(SelectInput.ATTRIBUTE.value) ?? el.textContent;
    }
    return '';
  }

  dispose(): void {
    Data.removeData(this.element, SelectInput.DATA_KEY);
    this.element = null;
  }

  private addToggleEvent() {
    EventHandler.on(this.buttonEl, EventName.click, () => {
      this.isOpen = !this.isOpen;
    });
  }

  private addEscapeEvent() {
    EventHandler.on(this.listEl, EventName.keydown, (e: KeyboardEvent) => {
      if (e.keyCode === SelectInput.ESCAPE_KEYCODE) {
        this.isOpen = false;
        this.buttonEl.focus();
      }
    });
  }

  private addBlurEvent() {
    EventHandler.on(this.element, EventName.focusout, (e) => {
      if (!this.element.contains(e.relatedTarget as Node)) {
        this.isOpen = false;
      }
    });
  }

  /**
   * Navigate between options and set `focusedIndex`
   */
  private addUpDownEvent() {
    EventHandler.on(this.listEl, EventName.keydown, (e: KeyboardEvent) => {
      if (e.keyCode === SelectInput.UP_KEYCODE) {
        e.preventDefault();
        this.focusedIndex = Math.max(0, this.focusedIndex - 1);
      }

      if (e.keyCode === SelectInput.DOWN_KEYCODE) {
        e.preventDefault();
        this.focusedIndex = Math.min(this.optionElements.length - 1, this.focusedIndex + 1);
      }
    });
  }

  /**
   * Select option on Click or Enter keydown and close menu
   */
  private addClickEvent() {
    this.optionElements.forEach((opt, i) => {
      EventHandler.on(opt, EventName.click, () => {
        this.selectedIndex = i;
        this.isOpen = false;
        this.buttonEl.focus();
      });

      EventHandler.on(opt, EventName.keydown, (e: KeyboardEvent) => {
        if (e.keyCode === SelectInput.ENTER_KEYCODE) {
          e.preventDefault();
          this.selectedIndex = this.focusedIndex;
          this.isOpen = false;
          this.buttonEl.focus();
        }
      });
    });
  }

  /**
   * Select first option whose first letter is
   * matching the alphanumeric value of keycode
   */
  private addShortcutEvents() {
    /*
      Regex matching every alpha-numeric characters.

      \d : every digits
      \p{Letter} : every letters in the latin alphabet including letters with diacritics

      The "u" flag enables unicode mode required to use `\p{Letter}`.

      See :
      - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions/Unicode_Property_Escapes#general_categories
      - https://unicode.org/reports/tr18/#General_Category_Property
    */
    const alphaNumericRegex = /^[\d\p{Letter}]$/u;

    EventHandler.on(this.listEl, EventName.keydown, (e: KeyboardEvent) => {
      const isAlphanumeric = alphaNumericRegex.test(e.key);
      if (isAlphanumeric) {
        const goToIndex = this.optionElements.findIndex(
          (el) => el.textContent[0].toLowerCase() === e.key.toLowerCase()
        );
        if (goToIndex !== -1) {
          this.focusedIndex = goToIndex;
        }
      }
    });
  }
}
