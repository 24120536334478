import { array, number, object, string } from 'idonttrustlikethat';
import { strapiFileResponse } from '../file/strapi-file';

const tileAttributes = object({
  image: strapiFileResponse,
  title: string,
  url: string,
  description: string.nullable(),
});

const tile = object({
  id: number,
  attributes: tileAttributes,
});

const tiles = array(tile);
const tileResponse = object({
  data: tile,
  meta: object({}).optional(),
});

const tilesResponse = object({
  data: tiles,
  meta: object({}).default({}),
});

type Tile = typeof tile.T;
type Tiles = typeof tiles.T;
type TileResponse = typeof tileResponse.T;
type TilesResponse = typeof tilesResponse.T;

export { tile, tiles, tileResponse, tilesResponse };

export type { Tile, Tiles, TileResponse, TilesResponse };
