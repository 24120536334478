import { FC } from 'react';
import './home-events-block.scss';
import { HomeEventCard } from './home-event-card';
import { NJButton } from '@engie-group/fluid-design-system-react';
import { EventType } from '../../../../../../types/events';
import { Loader } from '../../../../../Loader/Loader';

interface HomeEventsBlockProps {
  eventList: EventType[];
  isEventsLoading: boolean;
  title: string;
}

const HomeEventsBlock: FC<HomeEventsBlockProps> = ({
  eventList,
  isEventsLoading,
  title,
}) => {
  return (
    <div className="he-block">
      <h2 className="het-event-title">{title}</h2>
      <div className="he-list">
        {isEventsLoading ? (
          <Loader />
        ) : (
          eventList.map((eventLink) => (
            <HomeEventCard key={eventLink.title} event={eventLink}></HomeEventCard>
          ))
        )}
      </div>
      <div className="het-button-div">
        <NJButton href={'/events/list'} variant="primary" label="All events"></NJButton>
      </div>
    </div>
  );
};

export { HomeEventsBlock };
